import React, { useState, useEffect } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Tabs, Input, Menu, Popover, Button, Checkbox, Card, Popconfirm, Upload, Progress, Avatar, Drawer, Space, Select, message, Spin, Modal } from 'antd';
import Icon, { FileImageOutlined, FileWordOutlined, FilePptOutlined, FileTextOutlined } from '@ant-design/icons';
import { UserOutlined, AntDesignOutlined } from '@ant-design/icons';
import "../../../logics/prototypes";
import { websocketService, socket } from "../../../logics/services/websocket.service";
import store from '../../../logics/store/case.store';
import { encrypt } from '../../../logics/helpers';
import { APIURL } from '../../../constants';
import PDFView from "./PdfView";
import EditSvg from '../../../assets/svg-icons/edit.svg';
import DeleteSvg from '../../../assets/svg-icons/delete.svg';
import ArbitrationSvg from  '../../../assets/svg-icons/arbitration.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';
import StarFillSvg from  '../../../assets/svg-icons/star_fill.svg';
import FileUploadSvg from '../../../assets/svg-icons/file_upload.svg';
import ActivitySvg from '../../../assets/svg-icons/activity.svg';
import CommentSvg from '../../../assets/svg-icons/comment.svg';
import ResponsesBySvg from '../../../assets/svg-icons/responses_by.svg';
import InformationSvg from '../../../assets/svg-icons/information.svg';
import MoreHorizSvg from '../../../assets/svg-icons/more_horiz.svg';
import PdfSvg from '../../../assets/svg-icons/pdf.svg';
import DownloadSvg from '../../../assets/svg-icons/download.svg';
import WhiteDownloadSvg from '../../../assets/svg-icons/white_download.svg';
import EyeSvg from '../../../assets/svg-icons/eye.svg';
import close from "../../../assets/svg-icons/close.svg";
import WaitingSvg from "../../../assets/svg-icons/waiting.svg";
import SendMessageSvg from  '../../../assets/svg-icons/send-message.svg';
import { DownOutlined } from '@ant-design/icons';
import ArrowDownSvg from '../../../assets/svg-icons/arrow_drop_down.svg';
import LeftNavSvg from '../../../assets/svg-icons/left_navigation.svg';
import RightNavSvg from '../../../assets/svg-icons/right_navigation.svg';

const ArbitrationIcon = props => <Icon component={ArbitrationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarFillIcon = props => <Icon component={StarFillSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const EditIcon = props => <Icon component={EditSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const CommentEditIcon = props => <Icon component={EditSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const DeleteIcon = props => <Icon component={DeleteSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: 8}} {...props} />;
const FileUploadIcon = props => <Icon component={FileUploadSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const ActivityIcon = props => <Icon component={ActivitySvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const CommentIcon = props => <Icon component={CommentSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const ResponsesByIcon = props => <Icon component={ResponsesBySvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const InformationIcon = props => <Icon component={InformationSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const MoreHorizIcon = props => <Icon component={MoreHorizSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const PdfIcon = props => <Icon component={PdfSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: '8px'}} {...props} />;
const DownloadIcon = props => <Icon component={DownloadSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const WhiteDownloadIcon = props => <Icon component={WhiteDownloadSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const EyeIcon = props => <Icon component={EyeSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: '16px'}} {...props} />;
const CloseIcon = props => <Icon component={close} style={{ fontSize: 24, verticalAlign: "middle", float: 'right' }} {...props} />;
const CommentCloseIcon = props => <Icon component={close} style={{ fontSize: 24, verticalAlign: "middle", marginLeft: 'auto' }} {...props} />;
const WaitingIcon = props => <Icon component={WaitingSvg} style={{ fontSize: 236, verticalAlign: "middle" }} {...props} />;
const SendMessageIcon = props => <Icon component={SendMessageSvg} style={{ fontSize: 20, verticalAlign: 'middle'}} {...props} />;
const ArrowDownIcon = props => <Icon component={ArrowDownSvg} style={{ fontSize: 24, verticalAlign: 'middle', float: 'right' }} {...props} />;
const LeftNavIcon = props => <Icon component={LeftNavSvg} style={{ fontSize: 18, verticalAlign: 'middle'}} {...props} />;
const RightNavIcon = props => <Icon component={RightNavSvg} style={{ fontSize: 18, verticalAlign: 'middle'}} {...props} />;

const { TabPane } = Tabs;
const { TextArea } = Input;

const menu = (
    <Menu>
        <Menu.Item key="0">
            <span>Please provide a reason why you would like to reject this proposal?</span>
        </Menu.Item>
    </Menu>
);

const loaderList = [1,2];
const { Option } = Select;
const patterns = {
    isImageFilePattern: /\.(jpe?g|gif|bmp|png|svg)$/i,
    isImageNSFilePattern: /\.(tiff)$/i,
    isVideoFilePattern: /\.(mp4|webm|3gp|ogg)$/i,
    isVideoNSFilePattern: /\.(flv|wmv|avi)$/i,
    isAudioFilePattern: /\.(mp3)$/i,
    isPdfFilePattern: /\.(pdf)$/i,
    isDocFilePattern: /\.(doc|docx)$/i,
    isExcelFilePattern: /\.(xls|xlsx|csv)$/i,
    isTxtFilePattern: /\.(txt)$/i,
    isPptFilePattern: /\.(ppt|pptx)$/i,
    isExtractableFilePattern: /\.(gz|tar|rar|g?zip)$/i,
    isViewable: /\.(jpe?g|gif|bmp|png|svg|mp3|mp4|webm|3gp|ogg|pdf|txt)$/i,
    isUploadable: /\.(jpe?g|gif|bmp|png|svg|tiff?|mp3|mp4|3gp|flv|wmv|mkv|avi|webm|ogg|pdf|doc|docx|csv|xls|xlsx|ppt|pptx|txt)$/i
};

class DecisionView extends React.Component {
    
    constructor(props) {
        super(props);
        this.siteSetting=store.getState().settings;
        this.caseId=this.props.caseId;
        this.visible=this.props.visible;
        this.user=this.props.user;
        this.rolePermissions = this.user.permissions;
        this.proposalId=this.props.proposalId;
        this.editList = [];
        this.state = {
            caseInfo: [],
            loading: false,
            outcomeViewVisible: this.visible,
            confirm: false,
            proposalId: this.proposalId,
            previewFileName: '',
            previewFileId: 0,
            previewFileDrawer: false,
            previewPdfFile: false,
            previewImgFile: false,
            noPreviewFile: false,
            previewFileLength: 0,
            openFileNo: 0,
            uploadFileData: '',
            previewIcon: '',
            acceptModel: false,
            rejectModel: false,
            signedPassword: '',
            agreementSign: false,
            outcomeComment: '',
            outcomeCommentLoading: false,
            outcomeActivityLoading: false,
            selectedRejReason: 'Please select',
            rejectReason: '',
            rejectDescription: '',
            acceptSign: false,
            appvRejModal: false,
            selectedProposal: [],
            proposalStatus: [],
            proposActivites: [],
            proposCommentList: [],
            reasonList: [],
            rejectReasonList: [],
            caseParties: [],
            noAccessParties: [],
            isUserView: false,
            userViewParam: [],
            userViewType: '',
            editComment: '',
            editCommentId: '',
            prevFileId: undefined,
            nxtFileId: undefined
        };
    }
    
    componentDidMount = async () => {
        this.user = userService.getUser();
        this.getCaseInfo();
        this.viewProposal();
		this.getRejectReasons();
    };

    getCaseInfo = () => {
        this.setState({caseInfo: store.getState().case});
        // apiService.getSingleCase({
        //     case_id: this.caseId,
        // }).then((res) => {
        //     if (res.status === "success") {
        //         this.setState({caseInfo: res.data});
        //     }
        // });
    }

    onClose = () => {
        this.setState({outcomeViewVisible: false});
        this.props.closeDecisionViewVisible(false);
    };

    viewProposal() {
        this.setState({loading: true});
		apiService.viewProposal({
            cs_id: this.state.proposalId,
            case_id: this.caseId
        }).then(res => {
            if(res.status == 'success') {
                this.setState({loading: false, selectedProposal: res.data.proposed_info, proposalStatus: res.data.proposed_info.party_status, proposActivites: res.data.proposal_activity, proposCommentList: res.data.proposal_comments});
                this.getParties(res.data.proposed_info);
            }
        })
	}

    getParties(selectedDeProposal) {
        apiService.getCaseDecisionParties({
            case_id: this.caseId,
            cs_id: this.state.proposalId,
        }).then(res => {
            if (res.status === 'success') { 
                this.setState({caseParties: res.data});
				let allParties = selectedDeProposal.parties ? selectedDeProposal.parties.split(',').map(Number) : [];
				res.data.forEach(e => {
					if(allParties.findIndex(a => a == e.u_id) < 0)
                        this.setState({noAccessParties: this.state.noAccessParties.concat(e.u_id)});
				});
			}
		});
	}

    getRejectReasons() {
		apiService.getRejectReasons({module_name : 'Decision'})
        .then(res => {
            if(res.status == 'success') {
                this.setState({rejectReasonList: res.data});
            }
        });
	}

    onProposalTabChange = key => {
        if(key == "comment") {
            this.setState({outcomeCommentLoading: true});
            this.getComment();
        } else if(key == "activity") {
            this.setState({outcomeActivityLoading: true});
            this.proposalActivity();
        }
    };

    viewFile = (fileId) => {
        this.setState({loading: true, previewPdfFile: false, previewImgFile: false, noPreviewFile: false});
        apiService.getFileData({
            f_id: fileId
        }).then((res) => {
            if (res.status == 'success') {
                this.selectedFile = res.data;	
                this.isImage = patterns.isImageFilePattern.test(this.selectedFile.file.file_name);
                this.isPdf = patterns.isPdfFilePattern.test(this.selectedFile.file.file_name);
                this.isDoc = patterns.isDocFilePattern.test(this.selectedFile.file.file_name);
                this.isTxt = patterns.isTxtFilePattern.test(this.selectedFile.file.file_name);
                this.isExcel = patterns.isExcelFilePattern.test(this.selectedFile.file.file_name);
                this.isPpt = patterns.isPptFilePattern.test(this.selectedFile.file.file_name);
                this.isNSImage = patterns.isImageNSFilePattern.test(this.selectedFile.file.file_name);
                this.isViewable = patterns.isViewable.test(this.selectedFile.file.file_name);
                let isOpenFile = false;
                let prevFileId;
                let nxtFileId;
                this.state.selectedProposal.filesInfo.forEach((e, k) => {
                    if(isOpenFile && nxtFileId == undefined) {
                        nxtFileId = e.f_id;
                    }
                    if(e.f_id == this.selectedFile.f_id) {
                        this.setState({openFileNo: k + 1});
                        isOpenFile = true;
                    }
                    if(!isOpenFile) {
                        prevFileId = e.f_id;
                    }
                });
                this.setState({previewFileId: fileId, previewFileDrawer: true, previewFileName: this.selectedFile.file.name, uploadFileData: res.data.url, loading: false, previewFileLength: this.state.selectedProposal.filesInfo.length, prevFileId: prevFileId, nxtFileId: nxtFileId, previewIcon: this.selectedFile.file.ext});
                if(this.isPdf) {
                    this.setState({previewPdfFile: true});
                } else if (this.isImage) {
                    this.setState({previewImgFile: true});
                } else {
                    this.setState({noPreviewFile: true});
                }
            }
        });
    }

    onShowPreviewFileDrawer() {
        this.setState({previewFileId: 0, previewFileDrawer: false, previewPdfFile: false, previewImgFile: false, noPreviewFile: false, previewFileName: '', uploadFileData: '', previewFileLength: 0, prevFileId: undefined, nxtFileId: undefined});
    }

    signPasswordChange = (e) =>  {
        this.setState({signedPassword: e.target.value});
    }

    handleAgreementSign = (agreementSign) =>  {
        this.setState({agreementSign: !agreementSign});
    }

    outcomeCommentChange = (e) =>  {
        this.setState({outcomeComment: e.target.value});
    }

    outcomeAddComment() {
        apiService.addProposalComment({
            csId: this.state.selectedProposal.cs_id,
            comment: this.state.outcomeComment,
            parent_id: 0
        }).then((res) => {
            if(res.status == 'success') {
                message.success(res.message);
                this.getComment();
                this.proposalActivity();
            } else {
                message.error(res.message);
            }
        });
        this.setState({outcomeComment: ''});
	}

    getComment() {
        apiService.getProposalComment({
            csId: this.state.selectedProposal.cs_id,
            caseId: this.caseId
        }).then(res => {
            if (res.status == 'success') {
                this.setState({proposCommentList: res.data, outcomeCommentLoading: false});
            }
        }, () => {
        });
    }

    proposalActivity() {
		apiService.outcomesActivity({
            csId: this.state.proposalId,
            type : 'decision'
        }).then(res => {
            if(res.status == 'success') {
                this.setState({proposActivites: res.data});
            }
        });

	}

    closeproposal() {
        this.setState({acceptModel: false, rejectModel: false, acceptSign: false, agreementSign: false, signedPassword: '', rejectReason: '', rejectDescription: ''});
        this.onClose();	
	}

    handleChangeRejectReason(reason) {
        this.setState({selectedRejReason: reason, rejectReason: reason});
	}

    handleRejectDescription = (e) =>  {
        this.setState({rejectDescription: e.target.value});
    }

    rejprevious() {
        this.setState({appvRejModal: false, selectedRejReason: 'Please select', rejectReason: '', rejectDescription: '', signedPassword: ''});
	}

    accept() {
        this.setState({rejectModel: false, acceptModel: true, acceptSign: true});
    }

    rejSign() {
        this.setState({acceptModel: false, acceptSign: false, agreementSign: false, signedPassword: ''});
	}

    rejectProposal() {
		this.setState({loading: true});
		apiService.rejectProposal({
            cs_id: this.state.proposalId,
            case_id: this.caseId,
            rejectedReason: this.state.rejectReason,
            rejectDescription: this.state.rejectDescription
        }).then(res => {
            if(res.status == 'success') {
                this.setState({loading: false, proposalStatus: res.data.proposalStatus, rejectModel: false, selectedRejReason: 'Please select', rejectReason: '', rejectDescription: ''});
                message.success(res.message);
                if(res.data.proposalInfo.status == 4) {
                    this.getCaseInfo();
                }			
                this.closeproposal();
                websocketService.send({
                    method: 'proposal',
                    isDisplay: true,
                    data: {
                        users: this.state.selectedProposal.parties.split(",").map(Number),
                        content: res.instant_msg,
                        type: 'p_reject', // Party Reject
                        time_display: false
                    }
                });
            } else if(res.status == 'error') {
                this.setState({loading: false});
                message.error(res.message);
            }
        });
	}

    acceptProposal() {
		this.setState({loading: true});
		apiService.acceptProposal({
            cs_id: this.state.proposalId,
            case_id: this.caseId,
            password: encrypt(this.state.signedPassword)
        }).then(res => {
            this.setState({loading: false});
            if(res.status == 'success') {   
                this.viewProposal();             					
                message.success(res.message);	
                let parties = res.data.proposalInfo.parties;
                this.setState({proposalStatus: res.data.proposalStatus});		
                if(res.data.proposalInfo.status == 3) {
                    this.getCaseInfo();
                }							
                websocketService.send({
                    method: 'proposal',
                    isDisplay: true,
                    data: {
                        users: parties.split(",").map(Number),
                        content: res.instant_msg,
                        type: 'p_accept', // Party Accept
                        time_display: false,
                        status: res.data.proposalInfo.status
                    }
                });
                this.setState({agreementSign: false, signedPassword: ''});
                this.rejSign();
            } else if(res.status == 'error') {
                message.error(res.message);
            }				
        });
	}

    apprRej() {
        this.setState({appvRejModal: true});
        setTimeout(() => {
            var container = document.getElementById('outcome-reject');
            if(container) container.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 500);
	}

    rejectDecision() {
        this.setState({loading: true});
		apiService.rejectDecision({
			cs_id: this.state.proposalId,
			case_id: this.caseId,
			rejectedReason: this.state.rejectReason,
			rejectDescription: this.state.rejectDescription,
			password: this.state.signedPassword
		}).then(res => {
            this.setState({loading: false});
			if(res.status == 'success') {
                this.onClose();
				message.success(res.message);
                this.setState({proposalStatus: res.data.proposalStatus, loading: false, appvRejModal: false, selectedRejReason: 'Please select', rejectReason: '', rejectDescription: '', signedPassword: ''});
                if(res.data.proposalInfo.status == 4) {
                    this.getCaseInfo();
                }
                websocketService.send({
                    method: 'decision',
                    isDisplay: true,
                    data: {
                        users: this.state.selectedProposal.parties ? this.state.selectedProposal.parties.split(',').map(Number):[],
                        content: res.instant_msg,
                        type: 'reject',
                        time_display: false
                    }
                });
			} else if(res.status == 'error') {
				message.error(res.message);
			} 
		});
	}

    approveDecision() {
        this.setState({loading: true});
		apiService.approveDecision({
			cs_id: this.state.proposalId,
			case_id: this.caseId
		}).then(res => {
            this.setState({loading: false});
			if(res.status == 'success') {
				this.onClose();
				message.success(res.message);
                this.setState({proposalStatus: res.data.proposalStatus});
                if(res.data.proposalInfo.status == 3) {
                    this.getCaseInfo();
                }
				websocketService.send({
					method: 'decision',
					isDisplay: true,
					data: {
						users: this.state.noAccessParties ? this.state.noAccessParties : [],
						content: res.instant_msg,
						type: 'approve', 
						time_display: false,
						status: res.data.proposalInfo.status
					}
				});

                websocketService.send({
                    method: 'decision',
                    isDisplay: true,
                    data: {
                        users: this.state.selectedProposal.parties ? this.state.selectedProposal.parties.split(',').map(Number):[],
                        content: res.instant_msg,
                        type: 'approve',
                        time_display: false,
                        status: res.data.proposalInfo.status
                    }
                });
			} else if(res.status == 'error') {
				message.error(res.message);
			}	
		});
	}

    editProposal() {
        this.props.viewEditProposal(false, this.state.proposalId);
		this.closeproposal();
	}

    withdrawProposal() {
        this.setState({loading: true});
		apiService.withdrawProposal({
			csId: this.state.selectedProposal.cs_id,
			case_id: this.caseId
		}).then(res => {
            this.setState({loading: false});
			if(res.status == 'success') {
				this.viewProposal();
				message.success(res.message);
				websocketService.send({
					method: 'proposal',
					isDisplay: true,
					data: {
						users: this.state.selectedProposal.parties.split(",").map(Number),
						content: res.instant_msg,
						type: 'withdraw', 
						time_display: false
					}
				});
			} else if(res.status == 'error') {
				message.error(res.message);
			}	
		});
	}

    proposPreview() {
        this.setState({loading: true});
		apiService.previewProposal({
            csId: this.state.selectedProposal.cs_id
        }).then(res => {
            if(res.status == 'success') {
                this.setState({previewFileId: 0, previewFileDrawer: true, previewPdfFile: true, previewFileName: '', uploadFileData: res.data, loading: false});
            }
        });
    }

    showUserView = (data, type) => {
        this.setState({isUserView: true, userViewParam: data, userViewType: type});
    };

    closeUserView = () => {
        this.setState({isUserView: false});
    };

    showEditComment(comment) {
        this.setState({editComment: comment.comment, editCommentId: comment.cmt_id});
		let r = !this.editList[comment.cmt_id];
		this.editList.forEach((e, k) => {
			this.editList[k] = false;
		});
		this.editList[comment.cmt_id] = r;		
	}

    closeEditComment(comment) {
        this.setState({editComment: '', editCommentId: ''});
		let r = !this.editList[comment.cmt_id];
        this.editList[comment.cmt_id] = r;
	}
    
    outcomeEditCommentChange = (e) =>  {
        this.setState({editComment: e.target.value});
    }

    outcomeUpdateComment(id) {
        this.setState({loading: true});
        apiService.editProposalComment({
            cs_cmt_id: this.state.editCommentId,
			comment: this.state.editComment
        }).then((res) => {
            if(res.status == 'success') {
                message.success(res.message);
                this.getComment();
                this.proposalActivity();
            } else {
                message.error(res.message);
            }
        });
        this.setState({editComment: '', editCommentId: '', loading: false});
		this.editList[this.state.editCommentId] = false;
	}

    getFileIcon(type) {
        let svgIcon = '';
        if(type == 'application/pdf' || type == 'pdf') {
            svgIcon = <PdfIcon />;
        } else if(type == 'image/jpeg' || type == 'image/png' || type == 'jpg' || type == 'png') {
            svgIcon = <FileImageOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        } else if(type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type == 'application/msword' || type == 'doc' || type == 'docx') {
            svgIcon = <FileWordOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        } else if(type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || type == 'application/vnd.ms-powerpoint' || type == 'ppt' || type == 'pptx') {
            svgIcon = <FilePptOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        } else if(type == 'text/plain' || type == 'txt') {
            svgIcon = <FileTextOutlined style={{fontSize: '22px', verticalAlign: 'middle'}} />;
        }

        return svgIcon;
    }

    render() {
        const { outcomeViewVisible, loading, caseInfo, selectedProposal, proposalStatus, previewFileName, previewFileDrawer, previewFileId, previewPdfFile, previewImgFile, noPreviewFile, previewFileLength, openFileNo, prevFileId, nxtFileId, uploadFileData, previewIcon, acceptModel, rejectModel, signedPassword, agreementSign, outcomeComment, outcomeCommentLoading, proposCommentList, outcomeActivityLoading, proposActivites, selectedRejReason, rejectReason, rejectDescription, acceptSign, appvRejModal, rejectReasonList, isUserView, userViewParam, userViewType, editComment } = this.state;

        let cs_id = selectedProposal ? selectedProposal.cs_id: 0;
        return (
            <div>
                <Drawer
                    title="Case Decision"
                    className="custom-drawer-popup outcome-view-drawer"
                    placement="top"
                    height={"100%"}
                    onClose={this.onClose}
                    visible={outcomeViewVisible}
                    extra={
                        <Space>
                            {   (selectedProposal && selectedProposal.p_status == 3 || selectedProposal.p_status == 6) &&
                                (
                                    <>
                                        {(selectedProposal && selectedProposal.p_status == 3) && (<EyeIcon onClick={() => this.proposPreview()} />)}
                                        {/* {(selectedProposal && selectedProposal.p_status == 3 && this.rolePermissions['Download Decision'] == 1) && (<a href={ APIURL + "proposal/downloadProposal?case_id=" + this.caseId + "&csId=" + cs_id + "&token=" + this.user.token} download><DownloadIcon /></a>)} */}
                                    </>
                                )
                            }
                            {/* {   ((selectedProposal && (selectedProposal?.p_status == 0  || selectedProposal?.p_status == 1 || selectedProposal?.p_status == 2) && selectedProposal.proposed_by.u_id == user.id) && (this.rolePermissions['Edit Case Decision'] || selectedProposal?.p_status == 0 )) &&
                                (
                                    <>
                                        {(this.rolePermissions['Edit Case Decision'] && selectedProposal && (selectedProposal.p_status == 0  || selectedProposal.p_status == 1 || selectedProposal.p_status == 2) && (selectedProposal.proposed_by.u_id == user.id)) && (<>{(selectedProposal && (selectedProposal.signed_count != 0 || selectedProposal.p_status == 4)) ? <EditIcon className="disabled-opacity" />: <EditIcon onClick={() => this.editProposal()} />}</>)}
                                        {(selectedProposal && (selectedProposal.p_status == 0)) && (<>{(selectedProposal && (selectedProposal.signed_count != 0 || selectedProposal.p_status == 4)) ? <DeleteIcon className="disabled-opacity" />: 
                                            <Popconfirm
                                                title={<><p>You can't revert this action.</p><span>Are you sure want to withdraw this proposal?</span></>}
                                                onConfirm={() => this.withdrawProposal()}
                                            >
                                                <DeleteIcon />
                                            </Popconfirm>
                                        }</>)}
                                    </>
                                )
                            } */}
                        </Space>
                      }
                    >
                        <Row className="sub-header case-name-header">
                            <Col span={20} className="case-name-bar">
                                <ArbitrationIcon className="fill-svg-w" />
                                <span className="pl16 case-name"><b>{caseInfo ? caseInfo.case_name:''}</b></span>
                                <span className="pl8">
                                    { !caseInfo?.isFav ? <StarIcon />: <StarFillIcon />}
                                </span>
                            </Col>
                        </Row>

                        <Drawer
                            title={!previewFileName?<span>&nbsp;</span>:previewFileName}
                            className="task-file-view-drawer"
                            placement="top"
                            height={"100%"}
                            onClose={() => this.onShowPreviewFileDrawer(previewFileDrawer)}
                            visible={previewFileDrawer}
                            extra={previewFileId != 0 && <a href={ APIURL + "file/downloadFile?f_id=" + previewFileId + "&token=" + this.user.token} download><DownloadIcon /></a>}
                        >
                            {previewPdfFile && (<PDFView pdfData={uploadFileData} isUrl={false} />)}
                            {previewImgFile && (<div><img src={uploadFileData} alt="img" width="100%"/></div>)}
                            {noPreviewFile && (<div className="no-preview-container"><Card><span>No Preview Available</span><p>You can Download copy of the file</p></Card></div>)}
                        </Drawer>
                        
                        <Spin spinning={loading} delay={500}>
                            {   selectedProposal && selectedProposal.cs_id &&
                                (
                                    <>
                                        { !previewFileDrawer &&
                                            (
                                                <>
                                                    <Row className="task-details p16">
                                                        <p className="task-title mb16">Case Decision</p>
                                                        <p className="task-description" dangerouslySetInnerHTML={{ __html: selectedProposal.description }}></p>
                                                        {   (selectedProposal.proposal_figure && selectedProposal.proposal_figure != 'null') &&
                                                            (
                                                                <Col span={24} className="proposed-figure">
                                                                    <span className="proposed-value">Decision Figure:</span>
                                                                    {   selectedProposal.proposal_figure
                                                                        ?
                                                                            <span className="proposed-usd">{ selectedProposal.proposal_figure } { this.siteSetting && this.siteSetting.crid ? this.siteSetting.crid : ''  }</span>
                                                                        :
                                                                            <span className="proposed-none">None</span>
                                                                    }
                                                                </Col>
                                                            )
                                                        }

                                                        {   selectedProposal.signature_by &&
                                                            (
                                                                <Col span={24} className="proposed-figure">
                                                                    <span className="proposed-value">Arbitrator Signature:</span><br/>
                                                                    <span>Digitally Signed by</span> <span dangerouslySetInnerHTML={{ __html: selectedProposal.signature_by }}></span><br/>
                                                                    <span dangerouslySetInnerHTML={{ __html: selectedProposal.signature_at }}></span>
                                                                </Col>
                                                            )
                                                        }
                                                        

                                                        
                                                    </Row>

                                                    <Row className="tabs-container">
                                                        <Col span={24}>
                                                            <Tabs defaultActiveKey={(selectedProposal && selectedProposal.filesInfo.length != 0) ? 1: 2} onChange={this.onProposalTabChange}>
                                                                {   (selectedProposal && selectedProposal.filesInfo.length != 0) &&
                                                                    (
                                                                        <TabPane 
                                                                        tab={
                                                                            <span>
                                                                                <FileUploadIcon />
                                                                            </span>
                                                                        } key="1">
                                                                            <Col span={24} className="task-view-tab-heading">
                                                                                <p className="task-view-tab-title">Attachment(s)</p>
                                                                            </Col>
                                                                            <div className="attachments-container">
                                                                                {/* <p className="attachments">Attachment(s)</p> */}
                                                                                <div className="uploaded-files">
                                                                                    <ul className="uploaded-file-list">
                                                                                        {
                                                                                            selectedProposal.filesInfo.map((file, index) => (
                                                                                                <li className="uploaded-file" key={index}>
                                                                                                    {/* <span className="file-icon">
                                                                                                        <PdfIcon />
                                                                                                    </span>                                                */}
                                                                                                    <span className="file-name" onClick={() => this.viewFile(file.f_id)}>
                                                                                                        { file.orginal_name }
                                                                                                    </span>
                                                                                                    <span className="file-delete" >
                                                                                                        <a href={ APIURL + "file/downloadFile?f_id=" + file.f_id + "&token=" + this.user.token} download><DownloadIcon /></a>
                                                                                                    </span>
                                                                                                </li>
                                                                                            ))
                                                                                        }
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </TabPane>
                                                                    )
                                                                }
                                                                <TabPane  
                                                                tab={
                                                                    <span>
                                                                        <ResponsesByIcon />
                                                                    </span>
                                                                } key="2">
                                                                    <div>
                                                                        <Row>
                                                                            <Col span={24} className="task-view-tab-heading">
                                                                                <p className="task-view-tab-title">Case Decision by</p>
                                                                            </Col>
                                                                            {   
                                                                                selectedProposal.proposal_status.map((proposer, index) => (
                                                                                    <div key={index}>
                                                                                    {   (proposer.permissions == "Decision & Sign") &&
                                                                                        (
                                                                                            <div className="user-details">
                                                                                                <Avatar className="mr16" src={proposer.user_profile.user_image}>{proposer.user_profile.p_name}</Avatar>
                                                                                                <span>
                                                                                                    <p className="name">{proposer.user_profile.name}</p>
                                                                                                    <p className="role">{proposer.role_name}</p>

                                                                                                    {(proposer.status == 2) && (<p className="status">Completed on: {proposer.updated_at.utcLocal().dateTimeFormat()}</p>)}
                                                                                                </span>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    </div>
                                                                                ))
                                                                            }

                                                                            {   selectedProposal.p_status != 5 &&
                                                                                (
                                                                                    <Col span={24} className="task-view-tab-heading">
                                                                                        {(selectedProposal.p_status == 0 || selectedProposal.p_status == 1 || selectedProposal.p_status == 2 || selectedProposal.p_status == 3) && (<p className="task-view-tab-title">Approved By</p>)}
                                                                                        {(selectedProposal.p_status == 4 || selectedProposal.p_status == 7) && (<p className="task-view-tab-title">Rejected By</p>)}
                                                                                    </Col>
                                                                                )
                                                                            }

                                                                            {   (selectedProposal.pending && selectedProposal.p_status != 5) &&
                                                                                (
                                                                                    <div className="user-details">
                                                                                        <Avatar className="mr16" src={selectedProposal.pending.user_profile.user_image}>{selectedProposal.pending.user_profile.p_name}</Avatar>
                                                                                        <span>
                                                                                            <p className="name">{selectedProposal.pending.user_profile.name}</p>
                                                                                            <p className="role">{selectedProposal.pending.role_name}</p>
                                                                                            <p className="status">Pending</p>
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {   (selectedProposal.approved && selectedProposal.p_status != 5) &&
                                                                                (
                                                                                    <div className="user-details">
                                                                                        <Avatar className="mr16" src={selectedProposal.approved.user_profile.user_image}>{selectedProposal.approved.user_profile.p_name}</Avatar>
                                                                                        <span>
                                                                                            <p className="name">{selectedProposal.approved.user_profile.name}</p>
                                                                                            <p className="role">{selectedProposal.approved.role_name}</p>

                                                                                            {(selectedProposal.approved) && (<p className="status">Approved on: {selectedProposal.approved.updated_at.utcLocal().dateTimeFormat()}</p>)}
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {   (selectedProposal.rejected && selectedProposal.p_status != 5) &&
                                                                                (
                                                                                    <div className="user-details">
                                                                                        <Avatar className="mr16" src={selectedProposal.rejected.user_profile.user_image}>{selectedProposal.rejected.user_profile.p_name}</Avatar>
                                                                                        <span>
                                                                                            <p className="name">{selectedProposal.rejected.user_profile.name}</p>
                                                                                            <p className="role">{selectedProposal.rejected.role_name}</p>

                                                                                            {(selectedProposal.rejected) && (<p className="status-reject">Rejected on:  {selectedProposal.rejected.rejected_at.utcLocal().dateTimeFormat()}</p>)}

                                                                                            {(selectedProposal.rejected.status == 4) && 
                                                                                                (
                                                                                                    <p className="status-view-reason">
                                                                                                        <Popover 
                                                                                                            content={<><span className="popover-reason-title">Reason</span><p>{selectedProposal.rejected.rejected_reason}</p><span className="popover-comments-title">Comments</span><p>{selectedProposal.rejected.description}</p></>} 
                                                                                                            title="Rejection Reason" trigger="click">
                                                                                                            View Reason
                                                                                                        </Popover>
                                                                                                    </p>
                                                                                                )
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {/* <div className="response-download">
                                                                                <p className="view-res">View Response</p>
                                                                                <p>Download</p>
                                                                            </div> */}
                                                                        </Row>
                                                                    </div>
                                                                </TabPane>
                                                                <TabPane  
                                                                tab={
                                                                    <span>
                                                                        <InformationIcon />
                                                                    </span>
                                                                } key="3">
                                                                    <div>
                                                                        <Row>
                                                                            <Col span={24} className="task-view-tab-heading">
                                                                                <p className="task-view-tab-title">Information</p>
                                                                            </Col>
                                                                            <Row className="can-view">
                                                                                <Col span={10}>
                                                                                    <p>Can View</p>
                                                                                </Col>
                                                                                <Col span={14}>
                                                                                    {   (selectedProposal.canView && selectedProposal.canView.length != 0) &&
                                                                                        (
                                                                                            <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                                                {
                                                                                                    (selectedProposal.canView).slice(0,3).map((view_party, index) => (
                                                                                                        <Avatar onClick={() => this.showUserView(selectedProposal.canView, 'can_view')} src={view_party.user_image} key={index}>{view_party.p_name}</Avatar>
                                                                                                    ))
                                                                                                }
                                                                                            </Avatar.Group>
                                                                                        )
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="can-view">
                                                                                <Col span={10}>
                                                                                    <p className="mt0">Must Approve</p>
                                                                                </Col>
                                                                                <Col span={14}>
                                                                                    {   (selectedProposal.assignTo && selectedProposal.assignTo.length != 0) &&
                                                                                        (
                                                                                            <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                                                {
                                                                                                    (selectedProposal.assignTo).slice(0,3).map((view_party, index) => (
                                                                                                        <Avatar onClick={() => this.showUserView(selectedProposal.assignTo, 'assigned_to')} src={view_party.user_image} key={index}>{view_party.p_name}</Avatar>
                                                                                                    ))
                                                                                                }
                                                                                            </Avatar.Group>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (!selectedProposal.assignTo || (selectedProposal.assignTo.length == 0)) && (<div className="mt5">None</div>)
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                            <div className="sign-status">
                                                                                <p><span>Type:</span> { selectedProposal.type }</p>
                                                                                <p><span>Overall Status:</span> { selectedProposal.status }</p>
                                                                                {/* <p><span>Created on:</span> 15 Mar 2021 12:28PM</p> */}
                                                                                <p><span>Created on:</span> {selectedProposal.created_at.utcLocal().dateTimeFormat()}</p>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                </TabPane>
                                                                {   (selectedProposal.p_status != 0 && (this.rolePermissions['Add comments in Case Decision'] == 1 || this.rolePermissions['Edit comments in Case Decision'] == 1)) &&
                                                                    (
                                                                        <TabPane  
                                                                        tab={
                                                                            <span>
                                                                                <CommentIcon />
                                                                            </span>
                                                                        } key="4">
                                                                            
                                                                            {   (this.rolePermissions['Add comments in Case Decision'] == 1) &&
                                                                                (
                                                                                    <>
                                                                                        <Col span={24} className="task-view-tab-heading">
                                                                                            <p className="task-view-tab-title">Comments</p>
                                                                                        </Col>
                                                                                        <div className="comment-details">
                                                                                            <Row>
                                                                                                <Col span={21}>
                                                                                                    <TextArea name="outcome-comment" id="outcome-comment" rows={4} autoSize={{ maxRows: 3 }} placeholder="Add your comments" onChange={this.outcomeCommentChange} maxLength={1000} value={outcomeComment} />
                                                                                                </Col>
                                                                                                <Col span={3} className="task-add-comment">
                                                                                                    <SendMessageIcon onClick={()=> this.outcomeAddComment() } />
                                                                                                </Col>
                                                                                                <Col>
                                                                                                    <p className="info-txt">Comments you post are visible to everyone with access to this Decision</p>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            
                                                                            {   outcomeCommentLoading
                                                                                ?
                                                                                    loaderList.map((item, index) => (
                                                                                        <Card key={index}>
                                                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                                                        </Card>
                                                                                    ))
                                                                                :
                                                                                    <>
                                                                                        {   proposCommentList && proposCommentList.length > 0
                                                                                            ? proposCommentList.map((comment, index) => (
                                                                                                <div className="comment-container" key={index}>
                                                                                                    <div className="user-comment-details">
                                                                                                        <Avatar className="mr16" src={comment.user_image}>{comment.p_name}</Avatar>
                                                                                                        <span>
                                                                                                            <p className="name">{comment.name}</p>
                                                                                                            <p className="role">{comment.user_type}</p>
                                                                                                        </span>
                                                                                                        {(!this.editList[comment.cmt_id] && comment.cmt_by == this.user.id) && <CommentEditIcon onClick={() => this.showEditComment(comment)} />}
                                                                                                        {(this.editList[comment.cmt_id] && comment.cmt_by == this.user.id) && <CommentCloseIcon onClick={() => this.closeEditComment(comment)} />}
                                                                                                    </div>
                                                                                                    {(!this.editList[comment.cmt_id]) && <p className="comment">{comment.comment}</p>}
                                                                                                    {   (this.editList[comment.cmt_id]) &&
                                                                                                        (
                                                                                                            <Row className="mb4">
                                                                                                                <Col span={21}>
                                                                                                                    <TextArea name="outcome-edit-comment" id="outcome-edit-comment" rows={3} onChange={this.outcomeEditCommentChange} maxLength={1000} value={editComment} />
                                                                                                                </Col>
                                                                                                                <Col span={3} className="task-edit-comment">
                                                                                                                    <SendMessageIcon className={editComment.length == 0 && "disabled-opacity"} onClick={()=> this.outcomeUpdateComment(comment.f_id) } />
                                                                                                                </Col>
                                                                                                            </Row>
                                                                                                        )
                                                                                                    }
                                                                                                    <p className="comment-date-time">{comment.created_at.utcLocal().dateTimeFormat()}</p>
                                                                                                </div>
                                                                                            ))
                                                                                            :
                                                                                                <></>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </TabPane>
                                                                    )
                                                                }
                                                                {   this.rolePermissions['View Activity in Decision'] &&
                                                                    (
                                                                        <TabPane  
                                                                        tab={
                                                                            <span>
                                                                                <ActivityIcon />
                                                                            </span>
                                                                        } key="5">
                                                                            <Col span={24} className="task-view-tab-heading">
                                                                                <p className="task-view-tab-title">Activity</p>
                                                                            </Col>
                                                                            { outcomeActivityLoading
                                                                                ?
                                                                                    loaderList.map((item, index) => (
                                                                                        <Card key={index}>
                                                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                                                        </Card>
                                                                                    ))
                                                                                :
                                                                                    <>
                                                                                        {   proposActivites && proposActivites.length > 0
                                                                                            ? proposActivites.map((activity, index) => (
                                                                                                <div className="activity-details" key={index}>
                                                                                                    <Avatar size={24} className="mr16" src={activity.creator_info.user_image}>{activity.creator_info.p_name}</Avatar>
                                                                                                    <span style={{ width: '100%' }}>
                                                                                                        <p className="content">{ activity.creator_info.name +' ('+ activity.user_role +') ' } { activity.content }</p>
                                                                                                        <p className="content">{activity.created_at.utcLocal().dateTimeFormat()}</p>
                                                                                                    </span>
                                                                                                </div>
                                                                                            ))
                                                                                            :
                                                                                                <></>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </TabPane>
                                                                    )
                                                                }
                                                            </Tabs>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }

                                        {   previewFileDrawer &&
                                            (
                                                <div className="view-file-drawer">
                                                    <Row className="preview-header">
                                                        <Col className="header">
                                                            Preview
                                                        </Col>
                                                        <Col className="close">
                                                            <CloseIcon onClick={() => this.onShowPreviewFileDrawer(previewFileDrawer)} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="attach-count">
                                                        <Col className="attach">
                                                            Attachments
                                                        </Col>
                                                        <Col className="count">
                                                            {openFileNo} / {previewFileLength} Documents
                                                        </Col>
                                                    </Row>
                                                    <Row className="preview-detail">
                                                        <Col span={24}>
                                                            <Row className="preview-name-download">
                                                                <Col className="icon-name">
                                                                    {this.getFileIcon(previewIcon)} <span className="name">{previewFileName}</span>
                                                                </Col>
                                                                <Col className="download">
                                                                    <a href={ APIURL + "file/downloadFile?f_id=" + previewFileId + "&token=" + this.user.token} download><WhiteDownloadIcon /></a>
                                                                </Col>
                                                            </Row>
                                                            <Row className="view-file-drawer">
                                                                <Col span={24}>
                                                                    {previewPdfFile && (<PDFView pdfData={uploadFileData} isUrl={false} />)}
                                                                    {previewImgFile && (<div><img src={uploadFileData} alt="img" width="100%"/></div>)}
                                                                    {noPreviewFile && (<div className="no-preview-container"><Card><span>No Preview Available</span><p>You can Download copy of the file</p></Card></div>)}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="nav-icon">
                                                        <Col className="left-nav">
                                                            <LeftNavIcon className={prevFileId == undefined ? "disabled": ""} onClick={() => this.viewFile(prevFileId)}/>
                                                        </Col>
                                                        <Col className="right-nav">
                                                            <RightNavIcon className={nxtFileId == undefined ? "disabled": ""} onClick={() => this.viewFile(nxtFileId)}/>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        }

                                        {   appvRejModal &&
                                            (
                                                <div className="document-sign outcome-reject" id="outcome-reject">
                                                    <p className="reject-proposal">Why are you rejecting the Case Decision?</p>
                                                    <Select defaultValue={selectedRejReason} className="reject-reason-select" onChange={(e) => this.handleChangeRejectReason(e)} suffixIcon={<ArrowDownIcon />} getPopupContainer={trigger => trigger.parentNode}>
                                                        { rejectReasonList && rejectReasonList.length > 0 
                                                            ? rejectReasonList.map((reject, index) => (
                                                                <React.Fragment key={[index]}>
                                                                    <Option value={reject.reason}>{reject.reason}</Option>
                                                                </React.Fragment>
                                                            ))
                                                            :<></>
                                                        }
                                                    </Select>
                                                    <TextArea className="reject-reason mb8" rows={5} placeholder="Please provide a reason why you would like to reject this proposal?" onChange={this.handleRejectDescription} maxLength={500}/>
                                                    <p className="reject-proposal">Please enter your account password.</p>
                                                    <Input.Password id="view-outcome-sign-password" placeholder="Enter your password" value={signedPassword} onChange={(e) => this.signPasswordChange(e)} maxLength={32} />
                                                </div>
                                            )
                                        }

                                        {   (selectedProposal && selectedProposal.p_status == 1 && proposalStatus && proposalStatus.permissions == 'Must Approve' && !appvRejModal) &&
                                            (
                                                <Row className="all-view-footer">
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-reject-approve" className="cancel" disabled={caseInfo && caseInfo.case_status != 3} onClick={() => this.apprRej()}>Reject</Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Popconfirm
                                                            title={<><span>You can’t revert this action.</span><p>Are you sure you want to Approve this Case Decision?</p></>}
                                                            onConfirm={() => this.approveDecision()}
                                                            okText="Confirm"
                                                        >
                                                            <Button type="primary" id="outcome-approve-proposal" className="complete" >Approve</Button>
                                                        </Popconfirm>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        {   (selectedProposal && selectedProposal.p_status == 1 && proposalStatus && proposalStatus.permissions == 'Must Approve' && appvRejModal) &&
                                            (
                                                <Row className="all-view-footer">
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-reject-previous" className="cancel" onClick={() => this.rejprevious()}>Cancel</Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button type="primary" id="outcome-approve-reject" className="complete" disabled={!rejectReason || !rejectDescription || !signedPassword} onClick={() => this.rejectDecision()}>Reject</Button>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Spin>
                </Drawer>

                <Modal 
                    title={userViewType == 'assigned_to' ? <span>Assigned To</span>: (userViewType == 'can_view' ? <span>Can View</span> : <></>)}
                    visible={isUserView}  
                    onCancel={this.closeUserView}
                    className="custom-modal-popup user-view-details"
                    footer={null}>
                        {   userViewParam && userViewParam.length > 0
                                ? userViewParam.map((view_party, index) => (
                                <div className="popover-member-detail" key={index}>
                                    <Avatar src={view_party.user_image}>{view_party.p_name}</Avatar>
                                    <div className="name-role">
                                        <p className="name">{view_party.name}</p>
                                        <p className="role">{view_party.role_name}</p>
                                    </div>
                                </div>
                            ))
                            :
                                <></>
                        }
                    
                </Modal>
            </div>
        );
    }
}
export default DecisionView;