import React, { useState } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Input, Button, Checkbox , Drawer, Select, message, Spin } from 'antd';
import Icon from '@ant-design/icons';
import { websocketService, socket } from "../../../logics/services/websocket.service";
import store from '../../../logics/store/case.store';

import ArbitrationSvg from  '../../../assets/svg-icons/arbitration.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';
import { StarFillIcon } from "../../../logics/images";
import CaseStatusIcon from "./CaseStatusIcon";
import { connect } from 'react-redux';

const ArbitrationIcon = props => <Icon component={ArbitrationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;

const { Option } = Select;
const { TextArea } = Input;

class Withdraw extends React.Component {
    constructor(props) {
        super(props);
        this.caseInfo=store.getState().case
        this.caseId=this.props.caseId;
        this.visible=this.props.visible;
        //this.caseName=this.props.caseName;
        this.state = {
            caseInfo: this.caseInfo,
            loading: false,
            withdrawVisible: this.visible,
            reason: '',
            comment: '',
            confirm: false,
            buttonDisabled: true,
            rejectWithdrawList: []
        };
        this.submitWithdraw = this.submitWithdraw.bind(this);
    }

    componentDidMount = async () => {
        let response;
        const caseId = this.caseId;
        this.user = userService.getUser();
        apiService.getWithdrawReasons({
            module_name: "Withdraw Case"
        }).then((res) => {
            if (res.status === 'success') {
                this.setState({rejectWithdrawList: res.data});
            }
        });
        // apiService.getCaseInfo({
        //     case_id: caseId,
        // }).then((res) => {
        //     if (res.status === "success") {
        //         this.setState({caseInfo: res.data});
        //     }
        // });
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'none';
    };

    onClose = () => {
        this.setState({withdrawVisible: false});
        this.props.closeWithdrawVisible(false);
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'flex';
    };

    handleReasonChange = (e) =>  {
        this.setState({reason: e});
        (e != '' && this.state.confirm) ? this.setState({buttonDisabled: false}) : this.setState({buttonDisabled: true});
    }

    handleCommentChange = (e) =>  {
        this.setState({comment: e.target.value});
    }

    handleConfirmChange = (e) =>  {
        this.setState({confirm: e.target.checked});
        (e.target.checked && this.state.reason != '') ? this.setState({buttonDisabled: false}) : this.setState({buttonDisabled: true});
    }

    submitWithdraw() {
        this.setState({loading: true});
        apiService.withdrawCase({
                case_id: this.caseId,
                reason: this.state.reason,
                comment: this.state.comment
            }).then(res => {   
                this.setState({loading: false});             
                if (res.status == 'success') {
                    this.onClose();
                    message.success(res.message);
                    apiService.getSingleCase({
                        case_id: this.caseId
                    }).then((res) => {
                        if (res.status == 'success') {
                            // dispatch({ type: 'case', value: res.data});
                            this.props.dispatchCase(res.data);
                        }
                    });
                    websocketService.send({
                        method:'case',
                        isDisplay: true,
                        data: {
                            users: res.data?res.data.split(',').map(Number):[],
                            content: res.instant_msg,
                            type: 'withdraw'
                        }
                    }); 
                } else if (res.status == 'error') {
                    message.error(res.message);
                    this.onClose();
                }
            });
    }

    render() {
        const { withdrawVisible, rejectWithdrawList, buttonDisabled, loading, caseInfo } = this.state;
        return (
            <div>
                
                <Drawer
                    title="Withdraw from the case"
                    className="withdraw-drawer"
                    placement="top"
                    height={"100%"}
                    onClose={this.onClose}
                    visible={withdrawVisible}
                    >
                        <Spin spinning={loading} delay={500}>
                            <Row className="sub-header mb8 case-name-header">
                                <Col span={24} className="case-name-bar">
                                    <CaseStatusIcon caseId={this.caseId} case_status={caseInfo?.case_status} case_type={caseInfo?.case_type} size={24}/>
                                    <span className="pl16 case-name">{caseInfo ? caseInfo.case_name:''}</span>
                                    <span className="pl8">
                                        { !caseInfo?.isFav ? <StarIcon />: <StarFillIcon />}
                                    </span>
                                </Col>
                            </Row>

                            <Row className="withdraw-content p15">

                                <Row className="mb16">
                                    <Col span={24}>
                                        <p className="mb8">Why do you want to withdraw from the case?</p>
                                    </Col>
                                    <Col span={24}>
                                        <Select name="withdraw-reason" id="withdraw-reason" style={{ width: "100%" }} placeholder="Select a reason" onChange={this.handleReasonChange}>
                                            { rejectWithdrawList && rejectWithdrawList.length > 0 
                                                ? rejectWithdrawList.map((item, index) => (
                                                    <React.Fragment key={[index]}>
                                                        <Option value={item.reason}>{item.reason}</Option>
                                                    </React.Fragment>
                                                ))
                                                :
                                                    <></>
                                            }
                                        </Select>
                                    </Col>
                                </Row>

                                <Row className="mb16">
                                    <Col span={24}>
                                        <p className="mb8">Comment (optional)</p>
                                    </Col>
                                    <Col span={24}>
                                        <TextArea name="withdraw-comment" id="withdraw-comment" rows={6} maxLength={300} onChange={this.handleCommentChange} />
                                    </Col>
                                </Row>

                                <Row className="mb16">
                                    <Col span={24}>
                                        <p className="mb8">What happens if I Withdraw?</p>
                                    </Col>
                                    <Col span={24}>
                                        <span>Please note that once you withdraw from the case you will no longer be able to take part in the case, have access to the case, view case information or view any case documents. The matter may proceed in your absence or if you have initiated this case, the matter will be closed.</span>
                                    </Col>
                                </Row>

                                <Row className="mb16">
                                    <Col span={24}>
                                        <Checkbox onChange={this.handleConfirmChange}>I confirm, I want to withdraw from the case.</Checkbox>
                                    </Col>
                                </Row>

                            </Row>

                            <Row className="withdraw-footer">
                                <Col span={12}>
                                    <Button type="primary" id="withdraw-cancel" className="withdraw-cancel" onClick={this.onClose}>Cancel</Button>
                                </Col>
                                <Col span={12}>
                                    <Button type="primary" id="withdraw-submit" className="withdraw-submit" disabled={buttonDisabled} onClick={this.submitWithdraw}>Withdraw</Button>
                                </Col>
                            </Row>
                        </Spin>
                </Drawer>
            </div>            
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        dispatchCase: (data) => { dispatch({ type: 'case', value: data})}
    }
};
export default connect(null, mapDispatchToProps)(Withdraw);