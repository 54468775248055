import React, { useState, useEffect } from "react";
import { Row, Col, Card, Avatar, Tag, Collapse, Skeleton } from "antd";
import apiService from "../../../logics/services/api.service";
import "../../../logics/prototypes";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Switch, Link, useParams } from "react-router-dom";

function Details() {
  const { Panel } = Collapse;

  function callback(key) {
    //console.log(key);
  }
  // const dispatch = useDispatch();
  const [caseDetails, setCaseDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [TagDetails, setTagDetails] = useState([]);
  const [escalation, setEsacalation] = useState([]);

  // const caseInfo = useSelector(state => state.data);
  // let { path } = useRouteMatch();
  let { caseId } = useParams();

  useEffect(() => {
    apiService
      .getCaseInfo({
        case_id: caseId,
      })
      .then((res) => {
        if (res.status === "success") {
          setCaseDetails(res.data);
          setEsacalation(res.data.escalation);
          setIsLoading(true);
        }
      });
  }, [caseId]);

  useEffect(() => {
    apiService
      .getTagList({
        case_id: caseId,
      })
      .then((res) => {
        if (res.status === "success") {
          setTagDetails(res.data);
        }
      });
  }, [caseId]);

  return (
    <div className="casedetails_collapse">
      <Row className="page-header">
        <Col span={24}>Details</Col>
      </Row>
      {!isLoading ? (
        <Skeleton active loading={!isLoading}></Skeleton>
      ) : (
        <div>
            <Collapse
                className="details"
                defaultActiveKey={["1"]}
                onChange={callback}
                expandIconPosition={"right"}
            >
                <Panel header="Case Information" key="1">
                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Case Name
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.case_name}
                        </Row>
                    </Col>
                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Case Reference Number (Source)
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.case_ref_no}
                        </Row>
                    </Col>

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Category
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.casecategory?.c_name}
                        </Row>
                    </Col>

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Sub Category
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.casesubcategory && caseDetails.casesubcategory.length > 0 ? caseDetails.casesubcategory: "Not applicable"}
                        </Row>
                    </Col>

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Date(s) Complained
                        </Row>
                        <Row span={12} className="rowcontent">
                            No data provided
                        </Row>
                    </Col>

                    {/* <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Date Problem Occured
                        </Row>
                        <Row span={12} className="rowcontent">
                            No data provided
                        </Row>
                    </Col> */}

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Claim Amount
                        </Row>
                        <Row span={12} className="rowcontent">
                            { caseDetails.claim_currency && caseDetails.claim_value?caseDetails.claim_currency:'' }{ caseDetails.claim_value?caseDetails.claim_value:'No data provided' }
                        </Row>
                    </Col>

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Salesperson
                        </Row>
                        <Row span={12} className="rowcontent">
                            No data provided
                        </Row>
                    </Col>

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Purchase Date
                        </Row>
                        <Row span={12} className="rowcontent">
                            No data provided
                        </Row>
                    </Col>
                </Panel>
            </Collapse>

            <Collapse
                className="details"
                onChange={callback}
                expandIconPosition={"right"}
            >
                <Panel header={caseDetails.mainClaimant.role+" Information"} key="2">
                    <Col className="mb8">
                        <Row span={12} className="rowheading">
                            Name
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.mainClaimant.name}
                        </Row>
                    </Col>
                    { caseDetails.mainClaimant?.org_name && (
                        <Col className="mb8">
                            <Row span={12} className="rowheading">
                                Organization Name
                            </Row>
                            <Row span={12} className="rowcontent">
                                { caseDetails.mainClaimant?caseDetails.mainClaimant.org_name:'No data provided' }
                            </Row>
                        </Col>
                    )}
                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Phone
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.mainClaimant?.phone && caseDetails.mainClaimant.phone != 'null'?caseDetails.mainClaimant.phone:'No data provided'}
                        </Row>
                    </Col>

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Email
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.mainClaimant.email}
                        </Row>
                    </Col>

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Address
                        </Row>
                        <Row span={12} className="rowcontent">
                            { caseDetails.mainClaimant && caseDetails.mainClaimant.address?caseDetails.mainClaimant.address:'No data provided' }
                        </Row>
                    </Col>
                </Panel>
            </Collapse>

            <Collapse
                className="details"
                onChange={callback}
                expandIconPosition={"right"}
            >
                <Panel header={(caseDetails.mainRespondent?.role)+" Information"} key="3">
                    <Col className="mb8">
                        <Row span={12} className="rowheading">
                            Name
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.mainRespondent.name}
                        </Row>
                    </Col>
                    { caseDetails.mainRespondent?.org_name && (
                        <Col className="mb8">
                        <Row span={12} className="rowheading">
                            Organization Name
                        </Row>
                        <Row span={12} className="rowcontent">
                            { caseDetails.mainRespondent?caseDetails.mainRespondent.org_name:'No data provided' }
                        </Row>
                    </Col>
                    )}
                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Phone
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.mainRespondent?.phone && caseDetails.mainRespondent.phone != 'null'?caseDetails.mainRespondent.phone:'No data provided'}
                        </Row>
                    </Col>

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Email
                        </Row>
                        <Row span={12} className="rowcontent">
                            {caseDetails.mainRespondent.email}
                        </Row>
                    </Col>

                    <Col className="mb16">
                        <Row span={12} className="rowheading">
                            Address
                        </Row>
                        <Row span={12} className="rowcontent">
                            { caseDetails.mainRespondent && caseDetails.mainRespondent.address?caseDetails.mainRespondent.address:'No data provided' }
                        </Row>
                    </Col>
                </Panel>
            </Collapse>

            <Collapse
                className="details"
                onChange={callback}
                expandIconPosition={"right"}
            >
                <Panel header="Desired Resolution Description" key="4">
                    <Col className="mb16">
                        <Row span={12} className="rowcontent word-break" dangerouslySetInnerHTML={{ __html: caseDetails.resolution?.rc_description }}>
                        </Row>
                    </Col>
                </Panel>
            </Collapse>

            <Collapse
                className="details"
                onChange={callback}
                expandIconPosition={"right"}
            >
                <Panel header="Case Description" key="5">
                    <Col className="mb16">
                        <Row span={12} className="rowcontent word-break">
                            {caseDetails.case_description}
                        </Row>
                        { !caseDetails.case_description && (<Row span={12} className="rowcontent">No data provided</Row>)}
                    </Col>
                </Panel>
            </Collapse>

            {caseDetails.feedbacks!=null?
            <Collapse
                className="details"
                onChange={callback}
                expandIconPosition={"right"}
            >
                <Panel header="Your Feedback" key="5">
                    <Row className="feedback-head">
                        <Col className="left-list">{}</Col>
                            <b>On : {(caseDetails.feedbacks.current_feedback.created_at.utcLocal().dateTimeFormat())}</b>
                        <Col span={12} className="textRight">
                            <b>At stage:</b> {caseDetails.feedbacks.current_feedback.resolution_type}
                        </Col>
                    </Row>                            
                    {caseDetails.feedbacks.current_feedback.fb_questions.map((item, index) => (
                        <Col>
                            <Row className="rowheading p8">{index+1}{" "}{item.question}</Row>
                            <Row className="rowcontent pl16 pt4">{" "}{item.value}</Row>
                        </Col>
                    ))}
                </Panel>
            </Collapse>:<></>
            }
          
            { caseDetails.caseHistories.length > 0 ?
                <Collapse
                    className="details"
                    onChange={callback}
                    expandIconPosition={"right"}
                >
                    <Panel header="Case History" key="5">
                        {caseDetails.caseHistories.map((item, index) => (                
                        <Col className="EscalBack m8" key={index}>
                            <Row className="rowheading p8">
                                {item.title}
                            </Row>
                            <Row>
                                <Col className="rowheading p8">
                                    <Avatar
                                    src={item.user_image}
                                    >
                                    {item.p_name}
                                    </Avatar>
                                </Col>
                                <Col>
                                    <Col className="rowheading">
                                    {item.name}
                                    </Col>
                                    <Col className="rowcontent">
                                    {item.role_name}
                                    </Col>
                                </Col>
                            </Row>
                            <Col className="middle-list quest card-heading p8">
                                <Col className="rowheading">On</Col>
                                <Col className="rowcontent">
                                    {item.history_date.utcLocal().dateTimeFormat()}
                                </Col>
                            </Col>
                            <Col className="mb8 p8">
                                <Row className="rowheading">Reason</Row>
                                <Row className="rowcontent word-break">
                                    {item.reason}
                                </Row>
                            </Col>
                            <Col className="mb8 p8">
                                <Row className="rowheading">Comments</Row>
                                <Row className="rowcontent word-break">
                                    {item.comment}
                                </Row>
                            </Col>
                        </Col>))}
                    </Panel>
                </Collapse>:<></>
            }

            <Collapse
                className="details"
                onChange={callback}
                expandIconPosition={"right"}
            >
                <Panel header="Tags" key="6">
                {TagDetails.length != 0 ? (
                    <Col className="mb16">
                    {TagDetails.map((item, index) => (
                        <Tag className="rowcontent" color="default" key={index}>{item.qf_value}</Tag>
                    ))}
                    </Col>
                ) : (
                    <Row>No tag assigned yet!</Row>
                )}
                </Panel>
            </Collapse>

            { escalation.length > 0
                ?
                <Collapse
                    className="details"
                    onChange={callback}
                    expandIconPosition={"right"}
                >
                    <Panel header="Escalation" key="7">
                        <Col className="mb24">
                        {escalation.map((item, index) => (
                            <Col className="EscalBackground" key={index}>
                            <Col className="mb8 p8">
                                <Row className="rowheading">Escalated On</Row>
                                <Row className="rowcontent">{item.escalate_on.utcLocal().dateTimeFormat()}</Row>
                            </Col>
                            <Col className="mb8 p8">
                                <Row className="rowheading">Escalated From</Row>
                                <Row className="rowcontent">{item.prevCaseType == 1?caseDetails.allRes[0]?.rc_name:caseDetails.allRes[1]?.rc_name} to {item.case_type_name}</Row>
                            </Col>

                            <Col className="mb8 p8">
                                <Row className="rowheading">Escalated by</Row>
                                <Row className="rowcontent">
                                {item.requested_by.name} ({item.requested_by.role_name})
                                </Row>
                            </Col>

                            <Col className="mb8 p8">
                                <Row className="rowheading">Status</Row>
                                {item.requested_by.role_label != 'Case Officer' && (<Row className="rowcontent">{item.status == 0 ?'Pending':(item.status == 1?'Accepted':'Declined')}</Row>)}
                                {item.requested_by.role_label == 'Case Officer' && (<Row className="rowcontent">Accepted</Row>)}
                            </Col>

                            <Col className="mb8 p8">
                                <Row className="rowheading">Reason</Row>
                                <Row className="rowcontent word-break">
                                {item.escalate_reason}
                                </Row>
                            </Col>

                            <Col className="mb8 p8">
                                <Row className="rowheading">Comments</Row>
                                <Row className="rowcontent word-break">
                                {item.escalate_comment}
                                </Row>
                            </Col>
                            { item.acceptedList.length > 0 
                                ? 
                                    <Col>
                                    {item.acceptedList.map(
                                        (accepted, index) => (
                                        <Col className="EscalBack m8" key={index}>
                                            <Row className="rowheading p8">
                                                Escalation request accepted by
                                            </Row>
                                            <Row>
                                                <Col className="rowheading p8">
                                                    <Avatar
                                                    src={accepted.image}
                                                    >
                                                    {accepted.p_name}
                                                    </Avatar>
                                                </Col>
                                                <Col>
                                                    <Col className="rowheading">
                                                    {accepted.user}
                                                    </Col>
                                                    <Col className="rowcontent">
                                                    {accepted.role}
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Col className="middle-list quest card-heading p8">
                                                <Col className="rowheading">Accepted On</Col>
                                                <Col className="rowcontent">
                                                    {accepted.accepted_on.utcLocal().dateTimeFormat()}
                                                </Col>
                                            </Col>
                                        </Col>
                                        )
                                    )}
                                    </Col>
                                : 
                                    <></>
                            }
                            { item.rejectedList.length > 0 
                                ? 
                                    <Col>
                                    {item.rejectedList.map(
                                        (rejected, index) => (
                                        <Col className="EscalBack m8" key={index}>
                                            <Row className="rowheading p8">
                                            Escalation request declined by
                                            </Row>
                                            <Row>
                                                <Col className="rowheading p8">
                                                    <Avatar
                                                    src={rejected.image}
                                                    >
                                                    {rejected.p_name}
                                                    </Avatar>
                                                </Col>
                                                <Col>
                                                    <Col className="rowheading">
                                                    {rejected.user}
                                                    </Col>
                                                    <Col className="rowcontent">
                                                    {rejected.role}
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Col className="middle-list quest card-heading p8">
                                                <Col className="rowheading">Declined On</Col>
                                                <Col className="rowcontent">
                                                    {rejected.declined_on.utcLocal().dateTimeFormat()}
                                                </Col>
                                            </Col>
                                            <Col className="mb8 p8">
                                                <Row className="rowheading">Reason</Row>
                                                <Row className="rowcontent word-break">
                                                {rejected.reason}
                                                </Row>
                                            </Col>

                                            <Col className="mb8 p8">
                                                <Row className="rowheading">Comments</Row>
                                                <Row className="rowcontent word-break">
                                                {rejected.comment}
                                                </Row>
                                            </Col>
                                        </Col>
                                        )
                                    )}
                                    </Col>
                                : 
                                    <></>
                            }
                            </Col>
                        ))}
                        </Col>
                    </Panel>
                </Collapse>
                :
                <></>
            }
        </div>
      )}
    </div>
  );
}
export default Details;
