import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Menu,
    Avatar,
    Collapse,
    Button,
    Timeline,
    Drawer,
    Skeleton,
    BackTop,
    Divider
} from "antd";
import Icon from "@ant-design/icons";
import MyCaseSvg from "../../assets/svg-icons/my_cases.svg";
import MyCaseActiveSvg from "../../assets/svg-icons/my_cases_active.svg";
import ActivitySvg from "../../assets/svg-icons/activity.svg";
import filter from "../../assets/svg-icons/filter.svg";
import scrolltotop from "../../assets/svg-icons/scrolltotop.svg";
import close from "../../assets/svg-icons/close.svg";
import ActivityActiveSvg from "../../assets/svg-icons/activity_active.svg";
import { Link, useParams, useHistory } from "react-router-dom";
import apiService from "../../logics/services/api.service";
import userService from "../../logics/services/user.service";

const MyCaseIcon = (props) => (
    <Icon
        component={MyCaseSvg}
        style={{ fontSize: 24, verticalAlign: "middle" }}
        {...props}
    />
);
const MyCaseActiveIcon = (props) => (
    <Icon
        component={MyCaseActiveSvg}
        style={{ fontSize: 24, verticalAlign: "middle" }}
        {...props}
    />
);
const MyCaseIconlist = (props) => (
    <Icon
        component={MyCaseSvg}
        style={{ fontSize: 16, verticalAlign: "middle",marginRight:5 }}
        {...props}
    />
);

const ActivityIcon = (props) => (
    <Icon
        component={ActivitySvg}
        style={{ fontSize: 24, verticalAlign: "middle" }}
        {...props}
    />
);
const ActivityActiveIcon = (props) => (
    <Icon
        component={ActivityActiveSvg}
        style={{ fontSize: 24, verticalAlign: "middle" }}
        {...props}
    />
);

const Filtericon = (props) => (
    <Icon
        component={filter}
        style={{ fontSize: 24, verticalAlign: "middle" }}
        {...props}
    />
);
const ScrolltoTop = (props) => (
    <Icon
        component={scrolltotop}
        style={{ fontSize: 80, verticalAlign: "middle", width: 40, height: 40 }}
        {...props}
    />
);

const Closeicon = (props) => (
    <Icon
        component={close}
        style={{ fontSize: 24, verticalAlign: "middle" }}
        {...props}
    />
);

function AllActivity() {
    const history = useHistory();
    const [current, setState] = useState("allactivity");
    let { caseId } = useParams();
    const [loading, setLoading] = useState(false);
    const [activityDetails, setActivityDetails] = useState([]);
    const [activityCount, setActivityCount] = useState([]);
    const [activityDetailsGroup, setActivityDetailsGroup] = useState([]);
    const [offset, setOffset] = useState(0);
    const [visible, setVisible] = useState(false);
    const [filtervalue, setFilterValue] = useState("");
    const user = userService.getUser();
    let type = 'mycase';
    let rolePermissions = user.permissions;
    // if (rolePermissions['All Cases Tab']) {
    //     type = 'all';
    // } else {
    //     type = 'mycase';
    // }
    const count = 20;
    let pagesize = 0;

    const { Panel } = Collapse;
    let caseTags = [];

    caseTags["case"] = "Case Updates";
    caseTags["task"] = "Tasks";
    caseTags["calendar"] = "Meetings";
    caseTags["keydate"] = "Key Dates";
    caseTags["meeting"] = "Meetings";
    caseTags["file"] = "Files & Folders";
    caseTags["settlement"] = "Case Outcomes";
    caseTags["questionnaire"] = "Questionnaires";

    const groupBy = (collection, keyGetter) => {
        if (!collection) {
            return null;
        }
        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current[keyGetter]]) {
                previous[current[keyGetter]] = [current];
            } else {
                previous[current[keyGetter]].push(current);
            }
            return previous;
        }, {});
        // this will return an array of objects, each object containing a group of objects
        return Object.keys(groupedCollection).map((key) => ({
            key,
            value: groupedCollection[key],
        }));
    };

    const fetchMoreData = (offset, filteredvalue) => {
        setLoading(true);
        apiService
            .getAllCaseActivity({
                case_id: 0,
                type: filteredvalue,
                search: "",
                all: true,
                offset: 0,
                limit: 20,
                param_type: type,
                activity_start_date: "",
                activity_end_date: "",
                participant_id: 0,
                case_stage: "",
                case_status: "",
                search_case: "",
            })
            .then((res) => {
                setLoading(false);
                if (res.status === "success") {
                    setOffset(offset + 20);
                    setActivityCount(res.activityCount);
                    let nextOffset = (pagesize + 1) * count;
                    if (res.activityCount <= nextOffset) {
                        //this.exceedNotifyRecord = true;
                    } else {
                        //this.exceedNotifyRecord = false;
                    }
                    //let data = activityDetails.concat(res.data);
                    setActivityDetails(res.data);
                    const datass = groupBy(res.data, "createdAt");
                    setActivityDetailsGroup(datass);
                }
            });
    };

    const loadMoreData = (offset) => {
        setLoading(true);
        apiService
            .getAllCaseActivity({
                case_id: 0,
                type: filtervalue,
                search: "",
                all: true,
                offset: offset,
                limit: 20,
                param_type: type,
                activity_start_date: "",
                activity_end_date: "",
                participant_id: 0,
                case_stage: "",
                case_status: "",
                search_case: "",
            })
            .then((res) => {
                setLoading(false);
                if (res.status === "success") {
                    setOffset(offset + 20);
                    let d = activityDetails.concat(res.data);
                    setActivityDetails(d);
                    const dd = groupBy(d, "createdAt");
                    setActivityDetailsGroup(dd);
                }
            });
    };

    const fetchFilterData = (filt_val) => {
        apiService
            .getAllCaseActivity({
                case_id: caseId,
                all: true,
                offset: 0,
                limit: 20,
                param_type: type,
                type: filt_val,
                search: "",
            })
            .then((res) => {
                if (res.status === "success") {
                    setOffset(offset + 10);
                    setActivityCount(res.activityCount);
                    setActivityDetails(res.data);
                    const data = groupBy(res.data, "createdAt");
                    setActivityDetailsGroup(data);
                }
            });
    };

    useEffect(() => {
        fetchMoreData(0, filtervalue);
    }, []);

    function activityfilter(filt_val) {
        setFilterValue(filt_val);
        onClose();
        fetchFilterData(filt_val);
    }

    function closeFunc(filt_val) {
        onClose();
        setFilterValue("");
        setActivityDetails([]);
        setActivityDetailsGroup([]);
        fetchMoreData(0, "");
    }

    const showDrawer = () => {
        setVisible(!visible);
    };

    const onClose = () => {
        setVisible(!visible);
    };

    const handleClick = (e) => {
        setState(e.key);
        if (e.key === "mycases") {
            history.push("/app/allcases");
        }
    };

    function navigateToPage(data) {
        let link;
        switch (data.type) {
            case "chat":
                link = {
                    link_id: data.link_id,
                };
                history.push(
                    "/app/case/" +
                    data.case_case_id +
                    "/message/" +
                    btoa(JSON.stringify(link))
                );
                break;
            case "invite":
                history.push("/app/case/" + data.case_case_id + "/overview");
                break;
            case "task":
                link = {
                    link_id: data.link_id,
                };
                history.push(
                    "/app/case/" +
                    data.case_case_id +
                    "/task/" +
                    btoa(JSON.stringify(link))
                );
                break;
            case "calendar":
                link = {
                    link_id: data.link_id,
                };
                history.push(
                    "/app/case/" +
                    data.case_case_id +
                    "/events/" +
                    btoa(JSON.stringify(link))
                );
                break;
            case "keydate":
                link = {
                    link_id: data.link_id,
                };
                history.push(
                    "/app/case/" +
                    data.case_case_id +
                    "/events/" +
                    btoa(JSON.stringify(link))
                );
                break;
            case "settlement":
                link = {
                    link_id: data.link_id,
                };
                history.push(
                    "/app/case/" +
                    data.case_case_id +
                    "/outcomes/" +
                    btoa(JSON.stringify(link))
                );
                break;
            case "decision":
                link = {
                    link_id: data.link_id,
                };
                history.push(
                    "/app/case/" +
                    data.case_case_id +
                    "/outcomes/" +
                    btoa(JSON.stringify(link))
                );
                break;
            case "escalation":
                link = {
                    link_id: data.link_id,
                };
                history.push(
                    "/app/case/" +
                    data.case_case_id +
                    "/overview/" +
                    btoa(JSON.stringify(link))
                );
                break;
            case "reminder":
                link = {
                    link_id: data.link_id,
                };
                if (data.description.includes("Task")) {
                    history.push(
                        "/app/case/" +
                        data.case_case_id +
                        "/tasks/" +
                        btoa(JSON.stringify(link))
                    );
                } else {
                    history.push(
                        "/app/case/" +
                        data.case_case_id +
                        "/events/" +
                        btoa(JSON.stringify(link))
                    );
                }
                break;
            default:
                // history.push("/app/case/" + data.case_case_id + "/overview");
                break;
        }
    }

    return (
        <div>
            <Row className="home-header" justify="space-between">
                <Col span={12}>
                    <Link to={`/app/allcases`}>Home</Link>
                </Col>
                <Col onClick={showDrawer}>
                    <Filtericon />
                </Col>
            </Row>
            <Row className="mt8">
                <Col span={24}>
                    <Menu
                        className="top-menu"
                        onClick={handleClick}
                        selectedKeys={[current]}
                        mode="horizontal"
                    >
                        <Menu.Item
                            key="mycases"
                            style={{ width: "49.5%" }}
                            icon={
                                current === "mycases" ? <MyCaseActiveIcon /> : <MyCaseIcon />
                            }
                        >
                            My Cases
                        </Menu.Item>
                        <Menu.Item
                            key="allactivity"
                            style={{ width: "49.5%" }}
                            icon={
                                current === "allactivity" ? (
                                    <ActivityActiveIcon />
                                ) : (
                                        <ActivityIcon />
                                    )
                            }
                        >
                            All Activity
                        </Menu.Item>
                    </Menu>
                </Col>
            </Row>
            <Row>
                {/* 👇️ scroll to top on button click */}
                <BackTop style={{ right: '40px' }}>
                    <ScrolltoTop />
                </BackTop>
                <Collapse
                    className="activity_section"
                    defaultActiveKey={["sec_0"]}
                    bordered={false}
                    expandIconPosition={"right"}
                >
                    {activityDetailsGroup.map((actGroup, index) => (
                        <Panel header={  <Divider orientation="left" dashed='true' orientationMargin={0} style={{margin: 0}}>{actGroup.key}</Divider>} className="activity_panel" key={'sec_'+ index}>
                            <Timeline className="pl8 pt16">
                                {actGroup.value.map((item, index) => (
                                    <Timeline.Item
                                        dot={<Avatar src={item.creator.user_image == null ? item.creator.p_name : item.creator.user_image}>{item.creator.p_name}</Avatar>}
                                        color="red"
                                        key={index}
                                        onClick={() => navigateToPage(item)}
                                    >
                                        <Col>
                                            <Row>
                                                <Col className="casename_border ml16">
                                                    <MyCaseIconlist/>&nbsp;{item.case_name}
                                                </Col>
                                            </Row>
                                            <Row className="activity_timelineheader">
                                                {item.creator.name}&nbsp;({item.creator.role_name})&nbsp;{item.description}
                                            </Row>
                                            <Row justify="space-between" align="bottom">
                                                <Col span={12} className="activitydate">{String(item.created_at).utcLocal().dateTimeFormat()}</Col>
                                                <Col span={10} className="activity_casefilter_text">
                                                    {caseTags[item.type]}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Timeline.Item>
                                ))}
                            </Timeline>
                        </Panel>
                    ))}
                </Collapse>
                {activityCount == 0 && !loading ? (
                    <Col className="activity-endlist-alignText">
                        <p>You’ve reached the end of the list! 🎉</p>
                    </Col>
                ) : (loading ? (
                    <Row className="w100" align="middle" justify="space-around">
                        <Col span={24}>
                            {/* <Spin spinning></Spin> */}
                            <Skeleton active title={true} avatar={true} paragraph={{ rows: 1 }} className="p8" />
                            <Skeleton active title={true} avatar={true} paragraph={{ rows: 1 }} className="p8" />
                            <Skeleton active title={true} avatar={true} paragraph={{ rows: 1 }} className="p8" />
                        </Col>
                    </Row>
                ) : (
                        <Row className="mb24 mt10 w100" align="middle" justify="space-around">
                            <Col>
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => loadMoreData(offset)}
                                >
                                    Load More
                                </Button>
                            </Col>
                        </Row>
                    ))}
            </Row>

            <Drawer
                placement="right"
                closable={false}
                mask={false}
                className="activityfilter-menu"
                contentWrapperStyle={{ bottom: 0}}
                onClose={onClose}
                visible={visible}
                zIndex={1200}
            >
                <div>
                    <Row className="filter-header">
                        <Col>Filter</Col>
                        <Col>
                            <Closeicon onClick={onClose} />
                        </Col>
                    </Row>
                    <Col
                        className={ filtervalue == 'case'?"filter_top active":"filter_top"}
                        onClick={() => activityfilter("case")}
                    >
                        Case Updates
                    </Col>
                    <Col
                        className={ filtervalue == 'task'?"filter_top active":"filter_top"}
                        onClick={() => activityfilter("task")}
                    >
                        Tasks
                    </Col>
                    <Col
                        className={ filtervalue == 'calendar'?"filter_top active":"filter_top"}
                        onClick={() => activityfilter("calendar")}
                    >
                        Meetings
                    </Col>
                    <Col
                        className={ filtervalue == 'keydate'?"filter_top active":"filter_top"}
                        onClick={() => activityfilter("keydate")}
                    >
                        Key Date
                    </Col>
                    <Col
                        className={ filtervalue == 'file'?"filter_top active":"filter_top"}
                        onClick={() => activityfilter("file")}
                    >
                        Files & Folders
                    </Col>
                    <Col
                        className={ filtervalue == 'questionnaire'?"filter_top active":"filter_top"}
                        onClick={() => activityfilter("questionnaire")}
                    >
                        Questionnaires
                    </Col>
                    <Col
                        className={ filtervalue == 'settlement'?"filter_top active":"filter_top"}
                        onClick={() => activityfilter("settlement")}
                    >
                        Case outcomes
                    </Col>
                    <Row style={{ padding: '16px' }}>
                        <Col className="clear-filter" onClick={() => closeFunc()}>
                            Clear Filter
                        </Col>
                        {/* <Button type="primary" size="small" className="textright">
                        Apply Filter
                        </Button> */}
                    </Row>
                </div>
            </Drawer>
        </div>
    );
}
export default AllActivity;