import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Avatar,
  Tag,
  Collapse,
  Skeleton,
  Form,
  Input,
  Button,
  Select,
  Menu,
  Dropdown,
  Space,
  message,
  Modal,
  Spin
} from "antd";
import Icon from "@ant-design/icons";
// import moment from "moment";
import apiService from "../../../logics/services/api.service";
import { useSelector, useDispatch } from "react-redux";
import {
  useRouteMatch,
  Switch,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import userService from "../../../logics/services/user.service";
import closeSVG from "../../../assets/svg-icons/close.svg";
import "./styles.less";
import * as moment from 'moment';
import { decodeToken } from "react-jwt";
import store from "../../../logics/store/case.store";

const CloseIcon = (props) => (
  <Icon
    component={closeSVG}
    style={{ fontSize: 24, verticalAlign: "middle" }}
    {...props}
  />
);

const { Option } = Select;

function Calender() {
    const history = useHistory();
    const user = userService.getUser();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [TimeZoneDetails, setTimeZoneDetails] = useState([]);
    const [weekStart, setWeekStart] = useState([]);
    const [calendarId, setCalendarId] = useState([]);
    const [agendaMail, setAgendaMail] = useState([]);
    const [workDays, setWorkDays] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [form] = Form.useForm();
    const [placement, ] = useState("topLeft");

    const checkOptionsOne = [
        { label: "Sun", value: "0", checked: false },
        { label: "Mon", value: "1", checked: false },
        { label: "Tue", value: "2", checked: false },
        { label: "Wed", value: "3", checked: false },
        { label: "Thu", value: "4", checked: false },
        { label: "Fri", value: "5", checked: false },
        { label: "Sat", value: "6", checked: false },
    ];
    const weekStarts = [
        { label: "Sunday", value: "0" },
        { label: "Monday", value: "1" },
        { label: "Tuesday", value: "2" },
        { label: "Wednesday", value: "3" },
        { label: "Thursday", value: "4" },
        { label: "Friday", value: "5" },
        { label: "Saturday", value: "6" },
    ];
    const dateFormats = [
        { format: moment().format("DD/MM/YYYY"), value: "dd/MM/YYYY" },
        { format: moment().format("MM/DD/YYYY"), value: "MM/dd/YYYY" },
        { format: moment().format("MMM DD, YYYY"), value: "MMM dd, YYYY" },
        { format: moment().format("DD MMM YYYY"), value: "dd MMM YYYY" },
        { format: moment().format("ddd, DD/MM/YYYY"), value: "EEE, dd/MM/YYYY" },
        { format: moment().format("ddd, MM/DD/YYYY"), value: "EEE, MM/dd/YYYY" },
        {format: moment().format("ddd, MMM DD, YYYY"), value: "EEE, MMM dd, YYYY"},
        { format: moment().format("ddd, DD MMM YYYY"), value: "EEE, dd MMM YYYY" },
    ];
    // dateLongFormats = [
    // 	{format: moment().format('D MMM YYYY'),value: 'd MMM yyyy'},
    // 	{format: moment().format('D MMMM YYYY'),value: 'd MMMM yyyy'},
    // 	{format: moment().format('dddd, D MMMM YYYY'),value: 'EEEE, d MMMM yyyy'},
    // 	{format: moment().format('ddd, MMM D YYYY'),value: 'EEE, MMM d yyyy'}
    // ];
    const timeFormat = [
        { label: "12 hour format", value: "hh:mm a" },
        { label: "24 hour format", value: "HH:mm" },
    ];

    useEffect(() => {
        setIsDataLoading(true);
        if (user) {
            form.setFieldsValue({
                Timezone: user?.timezone,
                DateShortFormat: user?.date_short,
                TimeFormat: user?.time
            });
        }
        apiService.getTimeZone()
            .then((res) => {
                setIsDataLoading(false);
                if (res.status === "success") {
                    setTimeZoneDetails(res.data);
                }
            });

        apiService.getCalenderSetting()
            .then((res) => {
                if (res.status === "success") {
                    if (res.data) {
                        setCalendarId(res.data.u_cs_id);
                        setAgendaMail(res.data.agenda_mail);
                        setWeekStart(res.data.week_starts?.toString());
                        checkOptionsOne.forEach((e) => {
                            if (res.data.work_days.indexOf(e.value) >= 0) {
                                setWorkDays(workDays.concat(e.values));
                                e.checked = true;
                            } else {
                                e.checked = false;
                            }
                        });
                    }
                }
            });
    }, []);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        const userdata = userService.getUser();
        form.setFieldsValue({
            Timezone: userdata?.timezone,
            DateShortFormat: userdata?.date_short,
            TimeFormat: userdata?.time
        })
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const Submit = () => {
        let workDays = [];
        checkOptionsOne.forEach((e) => {
            if (e.checked == true) {
                workDays.push(e.value);
            }
        });
        setIsLoading(true);
        apiService.updateCalender({
            u_cs_id: calendarId,
            agenda_mail: agendaMail,
            week_starts: weekStart,
            time_format: form.getFieldValue('TimeFormat'),
            time_zone: form.getFieldValue('Timezone'),
            date_format_short: form.getFieldValue('DateShortFormat'),
            date_format_long: form.getFieldValue('DateShortFormat'),
            work_days: workDays?.join(",")
        }).then((res) => {
            setIsLoading(false);
            if (res.status === "success") {
                userService.setUserToken(res.token);
                dispatch({type: 'user', value: decodeToken(res.token)});
                message.success(res.message);
            } else {
                message.error(res.message);
            }
        });
    };

    const onClose = () => {
        let prev = localStorage.getItem("previousUrl")
        ? localStorage.getItem("previousUrl")
        : "app/allcases";
        history.push(prev);
    };

    const handleClick = (e) => {
        if (e.key === "myprofile") {
            history.push("/app/account/profile");
        } else if (e.key === "payment") {
            history.push("/app/account/payment");
        }
    };

    return (
        <div className="profile-section">
            <Row className="header">
                <Col className="textCenter">
                    <CloseIcon onClick={onClose} />
                </Col>
                <Col span={22} className="textCenter accountheading">
                    My Account
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Menu
                        mode="horizontal"
                        onClick={handleClick}
                        className="p-menu"
                        defaultSelectedKeys={["calendar"]}
                    >
                        <Menu.Item
                            key="myprofile"
                            style={{ width: "33%", textAlign: "center" }}
                            >
                            My Profile
                        </Menu.Item>
                        <Menu.Item
                            key="calendar"
                            style={{ width: "33%", textAlign: "center" }}
                            >
                            Calendar
                        </Menu.Item>
                        <Menu.Item
                            key="payment"
                            style={{ width: "33%", textAlign: "center" }}
                            >
                            Payment
                        </Menu.Item>
                    </Menu>
                </Col>
            </Row>
            <div className="profile-inner-section p16">
                { isDataLoading?(<div style={{ width: '100%'}}>
                    <Skeleton active title={true} paragraph={{rows:1, width: '100%'}} />
                    <Skeleton active title={true} paragraph={{rows:1, width: '100%'}} />
                    <Skeleton active title={true} paragraph={{rows:1, width: '100%'}} />
                </div>):
                (<Form form={form} layout="vertical">
                    <Form.Item label="Time Zone" name="Timezone" className="w100 mb24">
                        <Select
                            defaultValue={user?.timezone}
                            dropdownMatchSelectWidth={false}
                            placement={placement}
                            optionLabelProp="label" 
                            showSearch
                        >
                            {TimeZoneDetails.map((item, index) => (
                                <Option label={"(UTC " + item.offset + ") " + item.zone_name} value={item.zone_name} key={'opt_timezone_'+index}>
                                    {"(UTC " + item.offset + ") " + item.zone_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Date short format"
                        name="DateShortFormat"
                        className="w100 mb24"
                        >
                        <Select
                            defaultValue={user?.date_short}
                            dropdownMatchSelectWidth={false}
                            placement={placement}
                            optionLabelProp="label"
                        >
                            {dateFormats.map((item, index) => (
                                <Option value={item.value} label={item.format + ' (' + item.value +')'} key={'opt_date_'+ index}>
                                    { item.format } ({ item.value })
                                </Option>
                            ))}
                        </Select>
                        {/* <Col>For example 12/08/2021 will be displayed as above</Col> */}
                    </Form.Item>
                    <Form.Item
                        label="Time format"
                        name="TimeFormat"
                        className="w100 mb24"
                        >
                        <Select
                            defaultValue={user?.time}
                            dropdownMatchSelectWidth={false}
                            placement={placement}
                        >
                            {timeFormat.map((item, index) => (
                                <Option value={item.value} label={item.label} key={'opt_time_'+ index}>{item.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* <Form.Item>
                            <Form.Item
                                label="Week starts on"
                                name="email"
                                className="w100 mb0"
                            >
                                <Select
                                defaultValue="Sunday"
                                style={{}}
                                dropdownMatchSelectWidth={false}
                                placement={placement}
                                >
                                <Option value="Sunday">Sunday</Option>
                                <Option value="Monday">Monday</Option>
                                <Option value="Tuesday">Tuesday</Option>
                                <Option value="Wednesday">Wednesday</Option>
                                <Option value="Thursday">Thursday</Option>
                                <Option value="Friday">Friday</Option>
                                <Option value="Saturday">Saturday</Option>

                                </Select>
                            </Form.Item>
                            </Form.Item> */}
                </Form>)}
            </div>
            <div className="p-footer">
                <Row gutter={32} style={{ margin: 0 }} justify="space-between">
                    <Col span={12}>
                        <Button type="default" size="large" className="w100" disabled={isLoading} onClick={showModal}>
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                        type="primary"
                        size="large"
                        className="w100"
                        loading={isLoading}
                        disabled={isLoading || isDataLoading}
                        onClick={() => Submit()}
                        >
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </div>

            <Modal
                title="Cancel Changes"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={
                    <div className="p-footer" style={{ margin: 0}}>
                
                    <Row
                        gutter={32}
                        style={{ margin: 0 }}
                        justify="space-between"
                        >
                        <Col span={12}>
                            <Button
                                type="primary"
                                size="large"
                                className="w100"
                                onClick={() => handleOk()}
                                >
                                Yes
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                size="large"
                                className="cancelbutton"
                                type="default"
                                onClick={()=> handleCancel()}
                                >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </div>
                }
            >
                <Col style={{justify:'center'}}>Changes will be lost. Are you sure?</Col>
            </Modal>
        </div>
    );
}
export default Calender;
