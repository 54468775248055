import { Button, Card, Col, Drawer, Form, Input, Row, Select, Upload, Steps, Checkbox, Progress, Collapse, Avatar, Radio, Divider, Switch } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon, CloseIcon, DeleteIcon, MenuIcon, SortDownIcon } from "../../logics/images";
import apiService from "../../logics/services/api.service";
import Icon from '@ant-design/icons';
import PhoneInput from "react-phone-input-2";
import startsWith from 'lodash.startswith';

import MobImg from '../../assets/images/mobile.png';
import GalleryImg from '../../assets/images/Shape.png';
import CaseDiscussionSvg from  '../../assets/svg-icons/case_discussion.svg';
import CaseMediationSvg from  '../../assets/svg-icons/case_mediation.svg';
import CaseDecisionSvg from  '../../assets/svg-icons/case_dc.svg';
import InfoSvg from  '../../assets/svg-icons/info.svg';
import PdfSvg from '../../assets/svg-icons/document_pdf.svg';
import EditSvg from '../../assets/svg-icons/edit.svg';
import IndividualSvg from '../../assets/svg-icons/individual.svg';
import OrganizationSvg from '../../assets/svg-icons/organization.svg';
import ArrowDownSvg from  '../../assets/svg-icons/arrow_down.svg';
import GallerySvg from  '../../assets/svg-icons/gallery.svg';

const { Step } = Steps;
const { TextArea } = Input;
const { Panel } = Collapse;

const CaseDiscussionIcon = prop => <Icon component={CaseDiscussionSvg} fill="none" style={{ fontSize: 32, verticalAlign: 'middle'}} {...prop} />;
const CaseMediationIcon = prop => <Icon component={CaseMediationSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...prop} />;
const CaseDecisionIcon = prop => <Icon component={CaseDecisionSvg} style={{ fontSize: 32, verticalAlign: 'middle'}} {...prop} />;
const InfoIcon = prop => <Icon component={InfoSvg} style={{ fontSize: 16, marginRight: '6px'}} {...prop} />;
const PdfIcon = props => <Icon component={PdfSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: '8px'}} {...props} />;
const EditIcon = props => <Icon component={EditSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const IndividualIcon = props => <Icon component={IndividualSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const OrganizationIcon = props => <Icon component={OrganizationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ArrowTopIcon = props => <Icon component={ArrowDownSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const ArrowDownIcon = props => <Icon component={ArrowDownSvg} className="arrow-down-nav-icon" style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const GalleryIcon = props => <Icon component={GallerySvg} style={{ fontSize: 18 }} {...props} />;

function CreateCaseView() {
    const settings = useSelector(state => state.settings);
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [resolutionViewMore, setResolutionViewMore] = useState(false);
    const [participantVisible, setParticipantVisible] = useState(false);
    const [rankMediatorVisible, setRankMediatorVisible] = useState(false);
    const [paymentVisible, setPaymentVisible] = useState(false);
    const [paymentStatusVisible, setPaymentStatusVisible] = useState(false);
    const [current, setCurrent] = useState(0);
    const [impactList, setImpactList] = useState([]);
    const [seekingList, setSeekingList] = useState([]);
    const history = useHistory();

    const showDrawer = () => {
        setVisible(true);
    }

    const onClose = () => {
        setVisible(false);
    }

    const showResolutionViewMore = () => {
        setResolutionViewMore(true);
    }

    const onCloseResolutionViewMore = () => {
        setResolutionViewMore(false);
    }

    const showParticipantDrawer = () => {
        setParticipantVisible(true);
    }

    const onCloseParticipant = () => {
        setParticipantVisible(false);
    }

    const showRankMediatorDrawer = () => {
        setRankMediatorVisible(true);
    }

    const onCloseRankMediator = () => {
        setRankMediatorVisible(false);
    }

    const onClosePayment = () => {
        setPaymentVisible(false);
    }

    const showPaymentStatusDrawer = () => {
        setPaymentStatusVisible(true);
    }

    const onClosePaymentStatus = () => {
        setPaymentStatusVisible(false);
    }

    const onViewClose = () => {
        history.push('/app/create-case');
    }

    const addSeekingList = () => {
        setSeekingList([ ...seekingList, { value: ''}]);
    }

    const addImpactedList = () => {
        setImpactList([ ...impactList, { value: ''}]);
    }

    const deleteSeeking = (index) => {
        let list = seekingList.filter((e, i) => { return i !== index;});
        setSeekingList(list);
    }

    const deleteImpacts = (index) => {
        let list = impactList.filter((e,i) => { return i !== index;});
        setImpactList(list);
    }

    const next = () => {
        if(current != 4) {
            setCurrent(current + 1);
        } else {
            setPaymentVisible(true);
        }
    }

    const selectBefore = (
        <Select defaultValue="USD">
          <Option value="USD">USD</Option>
          <Option value="INR">INR</Option>
        </Select>
    );
      
    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const callback = (key) => {
        //console.log(key);
    }

    const onPaymentChange = (e) => {
        console.log('radio checked', e.target.value);
      };

    useEffect(() => {
    }, []);

    return (<div className="create-case-container">
            <Row className="header">
                <Col className="textCenter">
                    <CloseIcon onClick={onViewClose} />
                </Col>
                <Col span={22} className="textCenter heading">
                    Case Registration
                </Col>
            </Row>
            <div className="create-inner-section">
                { current == 0 && (
                    <>
                        <Row className="create-case-header" justify="space-between">
                            <Col className="left">
                                <span className="icon">1</span> Case Information
                            </Col>
                            <Col className="right">
                                <div>
                                    Step 1 of 5 <span className="ml8" onClick={ showDrawer }><MenuIcon /></span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="p16 step1">
                            <Col span={24}>
                                <Form
                                    layout={"vertical"}
                                    form={form}
                                    >
                                        <Form.Item label="Case Category:">
                                            <Select placeholder="Select">
                                                <Select.Option value="Family">Family</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Sub-category:">
                                            <Select placeholder="Select">
                                                <Select.Option value="Health Coverage">Health Coverage</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item className="head-label" label="Please give a summary of your dispute.">
                                            <p className="info-txt mb8">Note this will be visible to the other Party.</p>
                                            <span className="char-count">0/255 characters left</span>
                                            <TextArea placeholder="Please provide the details of your case" />
                                        </Form.Item>
                                        <Form.Item className="head-label" label="How you been impacted by the dispute? (optional)">
                                            <p className="info-txt mb8">Please add each 'Impact' in order of their importance.</p>
                                            { impactList.map((item, key) => (
                                                <Form.Item>
                                                    <div className="text-area-header">
                                                        <i className="index">{ key + 1 }</i>
                                                        <DeleteIcon onClick={ () => deleteImpacts(key) } />
                                                        <span className="char-count">{ item.value.length }/255 characters left</span>
                                                    </div>
                                                    <TextArea placeholder="Eg. Lorem ipsum dolor sit amet,  adipiscing elit."></TextArea>
                                                </Form.Item>
                                            ))}
                                            { impactList.length < 5 && (<Button className="w100" type="default" onClick={ addImpactedList }>Add</Button>)}
                                        </Form.Item>
                                        <Form.Item className="head-label" label="What outcome(s) are you seeking? (optional)">
                                            <p className="info-txt mb8">Please add each outcome in order of their importance.</p>
                                            { seekingList.map((item, key) => (
                                                <Form.Item>
                                                    <div className="text-area-header">
                                                        <i className="index">{ key + 1 }</i>
                                                        <DeleteIcon onClick={ () => deleteSeeking(key) } />
                                                        <span className="char-count">{ item.value.length }/255 characters left</span>
                                                    </div>
                                                    <TextArea placeholder="Eg. Lorem ipsum dolor sit amet,  adipiscing elit."></TextArea>
                                                </Form.Item>
                                            ))}
                                            { seekingList.length < 5 && (<Button className="w100" type="default" onClick={ addSeekingList }>Add</Button>)}
                                        </Form.Item>
                                        <Form.Item className="head-label" label="What is the amount you are claiming? (optional)">
                                            <p className="info-txt mb8">If applicable, what is the financial value of your case?</p>
                                            <Input addonBefore={selectBefore} placeholder={"Enter amount here"} maxLength="100"/>
                                        </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </>
                )}
                { current == 1 && (
                    <>
                        <Row className="create-case-header" justify="space-between">
                            <Col className="left">
                                {/* <Badge count={2} style={{ background: '#136796' }} /> Resolution Choice */}
                                <span className="icon">2</span> Resolution Choice
                            </Col>
                            <Col className="right">
                                <div>
                                    Step 2 of 5 <span className="ml8" onClick={ showDrawer }><MenuIcon /></span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="p16 step2">
                            <Col span={24}>
                                <span className="select-mode">Select the mode of Resolution:</span>
                                <Card title={<><CaseDiscussionIcon/> <span className="resolution-mode">Direct Dialogue</span></>} extra={<Checkbox id="direct_dialogue" onChange={onChange}></Checkbox>} for="direct_dialogue">
                                    <p className="content">Negotiate directly with the other party without a mediator. <a onClick={showResolutionViewMore}>more</a></p>
                                    <div className="know-more-contain"><InfoIcon/> <span className="know-more">Know more</span></div>
                                </Card>
                                <Card title={<><CaseMediationIcon/> <span className="resolution-mode">Mediation</span></>} extra={<Checkbox onChange={onChange}></Checkbox>}>
                                    <p className="content">Mediate a case with the help of an independent. <a onClick={showResolutionViewMore}>more</a></p>
                                    <div className="know-more-contain"><InfoIcon/> <span className="know-more">Know more</span></div>
                                </Card>
                                <Card title={<><CaseDecisionIcon/> <span className="resolution-mode">Arbitration</span></>} extra={<Checkbox onChange={onChange}></Checkbox>}>
                                    <p className="content">Get a binding decision with the help of an independent expert. <a onClick={showResolutionViewMore}>more</a></p>
                                    <div className="know-more-contain"><InfoIcon/> <span className="know-more">Know more</span></div>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )}
                { current == 2 && (
                    <>
                        <Row className="create-case-header" justify="space-between">
                            <Col className="left">
                                {/* <Badge count={2} style={{ background: '#136796' }} /> Resolution Choice */}
                                <span className="icon">3</span> Evidence
                            </Col>
                            <Col className="right">
                                <div>
                                    Step 3 of 5 <span className="ml8" onClick={ showDrawer }><MenuIcon /></span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="p16 step3">
                            <Col span={24}>
                                <span className="file-label">Do you want to add files to support your case? <p className="file-label-optional">(optional)</p></span>
                                <p className="file-text">Note that any files you upload will be visible to all Participants in the case.</p>
                                <Card className="upload-details">
                                    <Upload showUploadList={false} multiple={true}>
                                        <p className="browse-file">Browse file</p>
                                    </Upload>
                                    {/* <p className="browse-file">Browse file</p> */}
                                    <p className="file-size">Maximum upload file size : 300 MB</p>
                                    <p className="file-extension">(JPEG, GIF, BMP, PNG, SVG, TIFF, MP3, MP4, 3GP, FLV, WMV, MKV, AVI, WEBM, OGG, PDF, DOC, DOCX, CSV, XLS, XSLX, PPT, PPTX, TXT)</p>
                                </Card>
                                <div className="file-list-details">
                                    <div className="file-list-container">
                                        <div className="file-list">
                                            <PdfIcon style={{fontSize: 16, marginRight: "8px"}} />
                                            <span>Phone screen On Day 1.jpg</span>
                                            {/* <span className="percentage">{file.file.percent}%</span> */}
                                        </div>
                                        <Progress showInfo={true} percent={50} />
                                    </div>
                                    <div className="delete-icon-contain">
                                        <DeleteIcon className="delete-icon"/>
                                    </div>
                                </div>
                                <div className="uploded-files-details">
                                    <span className="uploded-files">Uploaded Files</span>
                                    <p className="description">We suggest you add a file description so other participants know what the file is for.</p>
                                    <Card className="preview-file-details">
                                        <div className="preview-file-icon">
                                            <DeleteIcon className="delete-icon"/>
                                        </div>
                                        <div className="preview-file">
                                            <img src={MobImg} alt="img" width="100%"/>
                                        </div>
                                        <div className="file-name">
                                            <GalleryIcon /> <p>Phone screen On Day 1.jpg</p>
                                            {/* <img src={GalleryIcon} alt="img" width="100%"/> <p>Phone screen On Day 1.jpg</p> */}
                                        </div>
                                        <div className="file-description">
                                            <TextArea name="file-description" id="file-description" placeholder="Add your file description" autoSize={{ maxRows: 5 }} maxLength={1000} />
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
                { current == 3 && (
                    <>
                        <Row className="create-case-header" justify="space-between">
                            <Col className="left">
                                {/* <Badge count={2} style={{ background: '#136796' }} /> Resolution Choice */}
                                <span className="icon">4</span> Invite Parties
                            </Col>
                            <Col className="right">
                                <div>
                                    Step 4 of 5 <span className="ml8" onClick={ showDrawer }><MenuIcon /></span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="p16 step4">
                            <Col span={24}>
                                <div className="invite-head">
                                    <Row>
                                        <Col span={14}>
                                            <span className="head-text">Invite Parties & Users</span>
                                        </Col>
                                        <Col span={10}>
                                            <Button type="primary" size="large" className="w100" onClick={ showParticipantDrawer }> Add Participant </Button>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <p className="invite-info-text">You will be able to add multiple Participants who should be part of this case. Once you create the case, the invited Participants will receive an email to join the case.</p>
                                </Row>
                                <Row>
                                    <Collapse
                                        className="parties-details mb16"
                                        defaultActiveKey={["1"]}
                                        onChange={callback}
                                        expandIconPosition={"right"}
                                    >
                                        <Panel header="Claimant(s)" key="1">
                                            <Col>
                                                <Card className="parties-card mt8">
                                                    <Row>
                                                        <Col span={12} className="label">
                                                            Name
                                                        </Col>
                                                        <Col span={12} className="role label right">
                                                            Role
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12">
                                                        <Col span={12}>
                                                            <Avatar>SJ</Avatar>
                                                            <span className="name-text">Sam Jones</span>
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            Claimant
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={12} className="label">
                                                            Email
                                                        </Col>
                                                        <Col span={12} className="label right">
                                                            Party
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12">
                                                        <Col span={12} className="text">
                                                            jonathan.clarke@gmail.com
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            Claimant
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={12}>
                                                            <Row className="mobile label">Mobile</Row>
                                                            <Row className="mobile-number text">...</Row>
                                                        </Col>
                                                        <Col span={12} className="icon">
                                                            <EditIcon className="edit-icon" />
                                                            <DeleteIcon className="delete-icon"/>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Panel>
                                    </Collapse>

                                    <Collapse
                                        className="parties-details mb16"
                                        onChange={callback}
                                        expandIconPosition={"right"}
                                    >
                                        <Panel header="Respondent(s)" key="2">
                                            <Col>
                                                <Card className="parties-card mt8">
                                                    <Row>
                                                        <Col span={12} className="label">
                                                            Name
                                                        </Col>
                                                        <Col span={12} className="role label right">
                                                            Role
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12">
                                                        <Col span={12}>
                                                            <Avatar>JC</Avatar>
                                                            <span className="name-text">John Cotton</span>
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            Respondent
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={12} className="label">
                                                            Email
                                                        </Col>
                                                        <Col span={12} className="label right">
                                                            Party
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb12">
                                                        <Col span={12} className="text">
                                                            jonathan.clarke@gmail.com
                                                        </Col>
                                                        <Col span={12} className="text right">
                                                            Respondent
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={12}>
                                                            <Row className="mobile label">Mobile</Row>
                                                            <Row className="mobile-number text">...</Row>
                                                        </Col>
                                                        <Col span={12} className="icon">
                                                            <EditIcon className="edit-icon" />
                                                            <DeleteIcon className="delete-icon"/>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Panel>
                                    </Collapse>
                                </Row>
                                <Row>
                                    <p className="end-text">To create a case, you must have at least one Respondent. To add a Respondent, click "Add party."</p>
                                </Row>
                                <Row>
                                    <Collapse
                                        className="mediator-details mb16"
                                        defaultActiveKey={3}
                                        onChange={callback}
                                        expandIconPosition={"right"}
                                    >
                                        <Panel header="Mediator" key="3">
                                            <Col>
                                                <Card className="mediator-card mt8">
                                                    <Row>
                                                        <Col span={24}>
                                                            <Row className="mediator-check-container">
                                                                <Col span={19}>
                                                                    <p>I want the Platform to allocate the most suitable mediator for my case.</p>
                                                                </Col>
                                                                <Col span={3}>
                                                                    <Switch id="mediator-check" checked={true}/>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mediator-choose-container">
                                                                <Col span={17}>
                                                                    <p>I want to choose my own mediator.</p>
                                                                    <span>Once you and the other party make your selection, we will try and provide the closest match.</span>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Button id="mediator-choose" type="primary" onClick={ showRankMediatorDrawer }>Change</Button>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Panel>
                                    </Collapse>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Card className="rank-preference mb8 ">
                                            <Row className="rank-preference-detail">
                                                <Col span={2} className="sno">
                                                    {1}
                                                </Col>
                                                <Col span={22} className="icon-container">
                                                    <Row className="icon">
                                                        <Col span={16}></Col>
                                                        <Col span={4}><ArrowTopIcon /></Col>
                                                        <Col span={4}><ArrowDownIcon /></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="rank-created-detail">
                                                <Col span={24} className="created-details">
                                                    <Row>
                                                        <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                                        <Col span={16}>
                                                            <Row>
                                                                <Col className="name">Jane Raymond</Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="role">Mediator, Lawyer</Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="pt10">
                                                        <Col>
                                                            <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card className="rank-preference mb8 ">
                                            <Row className="rank-preference-detail">
                                                <Col span={2} className="sno">
                                                    {2}
                                                </Col>
                                                <Col span={22} className="icon-container">
                                                    <Row className="icon">
                                                        <Col span={16}></Col>
                                                        <Col span={4}><ArrowTopIcon /></Col>
                                                        <Col span={4}><ArrowDownIcon /></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="rank-created-detail">
                                                <Col span={24} className="created-details">
                                                    <Row>
                                                        <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                                        <Col span={16}>
                                                            <Row>
                                                                <Col className="name">Jane Raymond</Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="role">Mediator, Lawyer</Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="pt10">
                                                        <Col>
                                                            <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card className="rank-preference mb8 ">
                                            <Row className="rank-preference-detail">
                                                <Col span={2} className="sno">
                                                    {3}
                                                </Col>
                                                <Col span={22} className="icon-container">
                                                    <Row className="icon">
                                                        <Col span={16}></Col>
                                                        <Col span={4}><ArrowTopIcon /></Col>
                                                        <Col span={4}><ArrowDownIcon /></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="rank-created-detail">
                                                <Col span={24} className="created-details">
                                                    <Row>
                                                        <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                                        <Col span={16}>
                                                            <Row>
                                                                <Col className="name">Jane Raymond</Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="role">Mediator, Lawyer</Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="pt10">
                                                        <Col>
                                                            <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
                { current == 4 && (
                    <>
                        <Row className="create-case-header" justify="space-between">
                            <Col className="left">
                                {/* <Badge count={2} style={{ background: '#136796' }} /> Resolution Choice */}
                                <span className="icon">5</span> Review & Pay
                            </Col>
                            <Col className="right">
                                <div>
                                    Step 5 of 5 <span className="ml8" onClick={ showDrawer }><MenuIcon /></span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="step5">
                            <Col span={24}>

                                <Collapse
                                    className="view-case-collapse mb16"
                                    defaultActiveKey={["1"]}
                                    onChange={callback}
                                    expandIconPosition={"right"}
                                >
                                    <Panel header={<><span className="collapse-sno">1</span> Case Information <EditIcon className="edit-icon" /></>} key="1">
                                        <Row className="mb16">
                                            <Col span={24} className="mb16">
                                                <label>Select the category and sub-category relevant to your case.</label>
                                            </Col>
                                            <Col span={24}>
                                                <Row className="mb4">
                                                    <span className="sub-label">Case Category:</span>
                                                </Row>
                                                <Row>
                                                    <Select placeholder="Select case category"
                                                        options={[
                                                            { value: 'Consumer', label: 'Consumer' },
                                                            { value: 'Business', label: 'Business' },
                                                            { value: 'Representative', label: 'Representative' },
                                                            { value: 'Legal Representative', label: 'Legal Representative' },
                                                            { value: 'Witness', label: 'Witness' }
                                                        ]}
                                                    />
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mb16">
                                            <Col span={24}>
                                                <Row className="mb4">
                                                    <span className="sub-label">Sub-category:</span>
                                                </Row>
                                                <Row>
                                                    <Select placeholder="Select sub-category"
                                                        options={[
                                                            { value: 'Consumer', label: 'Consumer' },
                                                            { value: 'Business', label: 'Business' },
                                                            { value: 'Representative', label: 'Representative' },
                                                            { value: 'Legal Representative', label: 'Legal Representative' },
                                                            { value: 'Witness', label: 'Witness' }
                                                        ]}
                                                    />
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mb16">
                                            <Col span={24} className="mb4">
                                                <label>Case Summary</label>
                                            </Col>
                                            <Col span={24}>
                                                <TextArea />
                                            </Col>
                                        </Row>
                                        <Row className="mb16">
                                            <Col span={24} className="mb4">
                                                <label>How you been impacted by the dispute? </label> <span className="optional-text">(optional)</span>
                                                <p className="info-text">Please add each 'Impact' in order of their importance.</p>
                                            </Col>
                                            <Col span={24}>
                                                <TextArea />
                                            </Col>
                                        </Row>
                                        <Row className="mb16">
                                            <Col span={24} className="mb4">
                                                <label>What outcome(s) are you seeking? </label> <span className="optional-text">(optional)</span>
                                                <p className="info-text">Please add each outcome in order of their importance.</p>
                                            </Col>
                                            <Col span={24}>
                                                <span className="not-answered">Not answered.</span>
                                            </Col>
                                        </Row>
                                        <Row className="mb16">
                                            <Col span={24} className="mb4">
                                                <label>What is the amount you are claiming? </label> <span className="optional-text">(optional)</span>
                                            </Col>
                                            <Col span={24}>
                                                <Input addonBefore={"USD"} />
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>

                                <Collapse
                                    className="view-case-collapse mb16"
                                    onChange={callback}
                                    expandIconPosition={"right"}
                                >
                                    <Panel header={<><span className="collapse-sno">2</span> Resolution Choice <EditIcon className="edit-icon" /></>} key="2">
                                        <Card title={<><CaseMediationIcon/> <span className="resolution-mode">Mediation</span></>}>
                                            <p className="content">Mediate a case with the help of an independent.</p>
                                        </Card>
                                    </Panel>
                                </Collapse>

                                <Collapse
                                    className="view-case-collapse mb16"
                                    onChange={callback}
                                    expandIconPosition={"right"}
                                >
                                    <Panel header={<><span className="collapse-sno">3</span> Evidence <EditIcon className="edit-icon" /></>} key="2">
                                        <Card className="evidence-card" title={<><GalleryIcon/> <span className="img-name">Phone screen On Day 1.jpg</span></>}>
                                            <p className="img-description">This is a photo of the phone's display on the first day I purchased it, and it worked perfectly. However, after a few days, it began to flicker and random black spots appeared on the screen.</p>
                                        </Card>
                                    </Panel>
                                </Collapse>

                                <Collapse
                                    className="view-case-collapse mb16"
                                    onChange={callback}
                                    expandIconPosition={"right"}
                                >
                                    <Panel header={<><span className="collapse-sno">4</span> Invite Parties <EditIcon className="edit-icon" /></>} key="2">
                                        <Card className="parties-card">
                                            <Row>
                                                <Col span={12} className="label">
                                                    Name
                                                </Col>
                                                <Col span={12} className="role label right">
                                                    Role
                                                </Col>
                                            </Row>
                                            <Row className="mb12">
                                                <Col span={12}>
                                                    <Avatar>SJ</Avatar>
                                                    <span className="name-text">Sam Jones</span>
                                                </Col>
                                                <Col span={12} className="text right">
                                                    Claimant
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card className="parties-card mt8">
                                            <Row>
                                                <Col span={12} className="label">
                                                    Name
                                                </Col>
                                                <Col span={12} className="role label right">
                                                    Role
                                                </Col>
                                            </Row>
                                            <Row className="mb12">
                                                <Col span={12}>
                                                    <Avatar>SJ</Avatar>
                                                    <span className="name-text">John Cotton</span>
                                                </Col>
                                                <Col span={12} className="text right">
                                                    Respondent
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card className="parties-card mt8">
                                            <Row>
                                                <Col span={12} className="label">
                                                    Name
                                                </Col>
                                                <Col span={12} className="role label right">
                                                    Role
                                                </Col>
                                            </Row>
                                            <Row className="mb12">
                                                <Col span={12}>
                                                    <Avatar>SJ</Avatar>
                                                    <span className="name-text">William Blake</span>
                                                </Col>
                                                <Col span={12} className="text right">
                                                    Legal Representative
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Panel>
                                </Collapse>

                            </Col>
                        </Row>
                    </>
                )}
            </div>
            <div className="c-footer">
                <Row gutter={32} style={{ margin: 0 }} justify="space-between">
                    <Col span={12}>
                        <Button
                            type="default"
                            size="large"
                            className="w100"
                            onClick={ onViewClose }
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            size="large"
                            className="w100"
                            onClick={ next }
                        >
                            Next
                        </Button>
                    </Col>
                </Row>
            </div>

            <Drawer
                placement="bottom"
                title={<><CaseMediationIcon/> <span className="resolution-mode">Mediation</span></>}
                closable={false}
                className="resolution-view-drawer"
                contentWrapperStyle={{ bottom: 0}}
                extra={<CloseIcon onClick={onCloseResolutionViewMore}/>}
                visible={resolutionViewMore}
            >
                <Row className="resolution-view-detail">
                    <Row>
                        <Col span={24}>
                            <p className="resolution-description">Lorem ipsum dolor sit amet consectetur. Urna lobortis netus sed tortor. Tellus varius venenatis erat sed tellus vitae mattis mus risus. Quis ut platea id pellentesque placerat lorem velit consequat. Mi commodo nulla ultricies volutpat arcu faucibus vitae augue. Viverra morbi augue ut eget tortor sagittis. Sed adipiscing venenatis sollicitudin venenatis odio mauris nec. Quis in amet leo cras mattis sed dictum et sodales. Sit.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="know-more-contain"><InfoIcon/> <span className="know-more">Know more</span></div>
                        </Col>
                    </Row>
                </Row>
            </Drawer>

            <Drawer
                placement="bottom"
                closable={true}
                mask={true} 
                title={'Case Registration'}
                className="activityfilter-menu"
                contentWrapperStyle={{ bottom: 0}}
                onClose={onClose}
                visible={visible}
                zIndex={1200}>
                <Steps current={1} direction="vertical">
                    <Step icon={<span>1</span>} title="Case Information" />
                    <Step icon={<span>2</span>} title="Resolution Choice" />
                    <Step icon={<span>3</span>} title="Evidence" />
                    <Step icon={<span>4</span>} title="Invite Parties" />
                    <Step icon={<span>5</span>} title="Review & Pay" />
                </Steps>
            </Drawer>

            <Drawer
                //placement="bottom"
                closable={false}
                title={'Add Participants'}
                className="add-participants-drawer"
                visible={participantVisible}
                extra={<CloseIcon onClick={onCloseParticipant}/>}
            >
                <Row className="participant-form mb16">
                    <Row className="mb16">
                        <Radio.Group defaultValue="Individual">
                            <Radio.Button title="Individual" value="Individual"><IndividualIcon /> Individual</Radio.Button>
                            <Radio.Button title="Organization" value="Organization"><OrganizationIcon /> Organization</Radio.Button>
                        </Radio.Group>
                    </Row>
                    <Row className="mb16">
                        <Col span={24} className="mb4">
                            <label>Select Participant role</label>
                        </Col>
                        <Col span={24}>
                            <Select placeholder="Select Participant role"
                                options={[
                                    { value: 'Consumer', label: 'Consumer' },
                                    { value: 'Business', label: 'Business' },
                                    { value: 'Representative', label: 'Representative' },
                                    { value: 'Legal Representative', label: 'Legal Representative' },
                                    { value: 'Witness', label: 'Witness' }
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row className="mb16">
                        <Col span={24} className="mb4">
                            <label>Representative of</label>
                        </Col>
                        <Col span={24}>
                            <Select placeholder="Select User"
                                options={[
                                    { value: 'Consumer', label: 'Consumer' },
                                    { value: 'Business', label: 'Business' },
                                    { value: 'Representative', label: 'Representative' },
                                    { value: 'Legal Representative', label: 'Legal Representative' },
                                    { value: 'Witness', label: 'Witness' }
                                ]}
                            />
                        </Col>
                    </Row>

                    <Divider />

                    <Row className="mb16">
                        <Col span={24} className="mb4">
                            <label>First name</label>
                        </Col>
                        <Col span={24}>
                            <Input placeholder="Enter first name" />
                        </Col>
                    </Row>
                    <Row className="mb16">
                        <Col span={24} className="mb4">
                            <label>Last name</label>
                        </Col>
                        <Col span={24}>
                            <Input placeholder="Enter last name" />
                        </Col>
                    </Row>
                    <Row className="mb16">
                        <Col span={24} className="mb4">
                            <label>Email Address</label>
                        </Col>
                        <Col span={24}>
                            <Input placeholder="Enter email address" />
                        </Col>
                    </Row>
                    <Row className="mb16">
                        <Col span={24} className="mb4">
                            <label>Mobile number </label><span>(optional)</span>
                        </Col>
                        <Col span={24}>
                            <PhoneInput
                                className="react-tel-input w100"
                                name="phone"
                                country={"us"} 
                                enableSearch
                                value={''}
                                style={{ width: "-webkit-fill-available" }}
                                isValid={(inputNumber, country, countries) => {
                                    return countries.some((country) => {
                                        return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                                    });
                                }}
                            />
                        </Col>
                    </Row>

                    <Divider />

                    <Row className="mb16">
                        <Col span={24} className="mb4">
                            <label>Organization Address </label><span>(optional)</span>
                        </Col>
                        <Col span={24} className="mb4">
                            <Input placeholder="Enter address" />
                        </Col>
                        <Col span={24}>
                            <Input placeholder="Enter City/ State/ Country/ Zip code" />
                        </Col>
                    </Row>
                </Row>

                <Row className="add-participant-footer" gutter={32} style={{ margin: 0 }} justify="space-between">
                    <Col span={12}>
                        <Button
                            type="default"
                            size="large"
                            className="w100"
                            onClick={ onCloseParticipant }
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            size="large"
                            className="w100"
                        >
                            Add
                        </Button>
                    </Col>
                </Row>
            </Drawer>

            <Drawer
                //placement="bottom"
                closable={false}
                title={'Rank Mediator based on your preference'}
                className="rank-mediator-drawer"
                visible={rankMediatorVisible}
                extra={<CloseIcon onClick={onCloseRankMediator}/>}
            >
                <Row className="rank-info">
                    <p>Use drag & drop OR arrow to rank Mediator</p>
                </Row>
                <Row className="rank-preference-container">
                    <Card className="rank-preference mb8 ">
                        <Row className="rank-preference-detail">
                            <Col span={2} className="sno">
                                {1}
                            </Col>
                            <Col span={22} className="icon-container">
                                <Row className="icon">
                                    <Col span={16}></Col>
                                    <Col span={4}><ArrowTopIcon /></Col>
                                    <Col span={4}><ArrowDownIcon /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="rank-created-detail">
                            <Col span={24} className="created-details">
                                <Row>
                                    <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                    <Col span={16}>
                                        <Row>
                                            <Col className="name">Jane Raymond</Col>
                                        </Row>
                                        <Row>
                                            <Col className="role">Mediator, Lawyer</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pt10">
                                    <Col>
                                        <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="rank-preference mb8 ">
                        <Row className="rank-preference-detail">
                            <Col span={2} className="sno">
                                {2}
                            </Col>
                            <Col span={22} className="icon-container">
                                <Row className="icon">
                                    <Col span={16}></Col>
                                    <Col span={4}><ArrowTopIcon /></Col>
                                    <Col span={4}><ArrowDownIcon /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="rank-created-detail">
                            <Col span={24} className="created-details">
                                <Row>
                                    <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                    <Col span={16}>
                                        <Row>
                                            <Col className="name">Jane Raymond</Col>
                                        </Row>
                                        <Row>
                                            <Col className="role">Mediator, Lawyer</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pt10">
                                    <Col>
                                        <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="rank-preference mb8 ">
                        <Row className="rank-preference-detail">
                            <Col span={2} className="sno">
                                {3}
                            </Col>
                            <Col span={22} className="icon-container">
                                <Row className="icon">
                                    <Col span={16}></Col>
                                    <Col span={4}><ArrowTopIcon /></Col>
                                    <Col span={4}><ArrowDownIcon /></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="rank-created-detail">
                            <Col span={24} className="created-details">
                                <Row>
                                    <Col span={4} className="avatar"><Avatar>JR</Avatar></Col>
                                    <Col span={16}>
                                        <Row>
                                            <Col className="name">Jane Raymond</Col>
                                        </Row>
                                        <Row>
                                            <Col className="role">Mediator, Lawyer</Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pt10">
                                    <Col>
                                        <span className="bio">Results-driven corporate lawyer with 18+ years of experience ensuring the legality of commercial transactions. Adept at drafting <a className="bio-readmore" style={{color:"#136796"}}>{ '...More' }</a></span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row className="rank-mediator-footer" gutter={32} style={{ margin: 0 }} justify="space-between">
                    <Col span={12}>
                        <Button
                            type="default"
                            size="large"
                            className="w100"
                            onClick={ onCloseRankMediator }
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            size="large"
                            className="w100"
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Drawer>

            <Drawer
                title={'Payment'}
                closable={false}
                className="payment-drawer"
                contentWrapperStyle={{ bottom: 0}}
                extra={<CloseIcon onClick={onClosePayment}/>}
                visible={paymentVisible}
            >
                <Row className="payment-detail">
                    <Row className="mb24">
                        <Col>
                            <span className="registration-fee">Registration Fee</span> <InfoIcon />
                        </Col>
                    </Row>
                    <Row className="charges-detail">
                        <Row className="mb24">
                            <Col span={14}>
                                <span className="charge-label">Mediation Charge</span>
                            </Col>
                            <Col span={10} className="charges">
                                $500
                            </Col>
                        </Row>
                        <Row className="mb24">
                            <Col span={14}>
                                <span className="charge-label">Filling Charges</span>
                            </Col>
                            <Col span={10} className="charges">
                                $10
                            </Col>
                        </Row>

                        <Divider />

                        <Row className="mb24">
                            <Col span={14}>
                                <span className="charge-label">Total</span>
                            </Col>
                            <Col span={10} className="charges">
                                $510
                            </Col>
                        </Row>
                    </Row>
                    
                    <Row className="mb24">
                        <Col>
                            <span className="choose-option">Choose your convenient option</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Radio.Group onChange={onPaymentChange}>
                                <Radio value="Stripe">Stripe</Radio>
                                <Radio value="Burton">Burton</Radio>
                                <Radio value="PayPal">PayPal</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={20}>
                            <p className="accept-terms">I acknowledge that I have read and understood the <a className="term-condition">terms and conditions</a>.  To the best of my knowledge, all of the information included here is correct.</p>
                        </Col>
                        <Col span={4} className="terms-check">
                            <Checkbox onChange={onChange}></Checkbox>
                        </Col>
                    </Row>
                </Row>

                <Row className="payment-footer" gutter={32} style={{ margin: 0 }} justify="space-between">
                    <Col span={12}>
                        <Button
                            type="default"
                            size="large"
                            className="w100"
                            onClick={ onClosePayment }
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            type="primary"
                            size="large"
                            className="w100"
                            onClick={ showPaymentStatusDrawer }
                        >
                            Make Payment
                        </Button>
                    </Col>
                </Row>
            </Drawer>

            <Drawer
                title={'Case Registration Payments'}
                closable={false}
                className="payment-status-drawer"
                contentWrapperStyle={{ bottom: 0}}
                extra={<CloseIcon onClick={onClosePaymentStatus}/>}
                visible={paymentStatusVisible}
            >
                <Row className="payment-status-detail">
                    <Row>
                        <Col span={24}>
                            <p className="payment-status">Payment Success</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <p className="charges">$500</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <a className="download-receipt">Download Receipt</a>
                        </Col>
                    </Row>
                </Row>

                <Row className="payment-status-footer" gutter={32} style={{ margin: 0 }} justify="space-between">
                    <Col span={24}>
                        <Button
                            type="primary"
                            size="large"
                            className="w100"
                        >
                            Done
                        </Button>
                    </Col>
                </Row>
            </Drawer>
        </div>)
}
export default CreateCaseView;