import React, { useState } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Input, Button, Checkbox , Drawer, Select, message, Spin, Card, Tabs, Badge, Avatar, BackTop, Skeleton } from 'antd';
import Icon from '@ant-design/icons';
import { websocketService, socket } from "../../../logics/services/websocket.service";
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import "../../../logics/prototypes";
import {withRouter} from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyMessageSvg from "../../../assets/svg-icons/no-messages.svg";
import store from "../../../logics/store/case.store";

const EmptyMessageIcon = props => <Icon component={EmptyMessageSvg} style={{ fontSize: 100, verticalAlign: 'middle'}} {...props} />;


const loaderList = [1,2,3];
const scrollHeight = 647;
const CryptoJSAesJson = {
    stringify(cipherParams) {
        const j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
        if (cipherParams.iv) { j.iv = cipherParams.iv.toString(); }
        if (cipherParams.salt) { j.s = cipherParams.salt.toString(); }
        return JSON.stringify(j);
    },
    parse(jsonStr) {
        const j = JSON.parse(jsonStr);
        const cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) { cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv); }
        if (j.s) { cipherParams.salt = CryptoJS.enc.Hex.parse(j.s); }
        return cipherParams;
    }
};

class MessageNotification extends React.Component {
    constructor(props) {
        super(props);
        this.visible=this.props.visible;
        // this.notificationType="all";
        this.notificationPage = 0;
        this.count = 25;
        this.offset = 0;
        this.chatList = [];
        this.state = {
            messageNotificationVisible: this.visible,
            initLoading: true,
            exceedNotifyRecord: false,
            // notificationCount: 0,
            chatCount: 0,
            // inviteCount: 0,
            // totalCount: 0,
            // notifyType: this.notificationType,
            hasMore: false,
            // notifyList: [],
            chatList: []
        };
        this.clearChatNotifications = this.clearChatNotifications.bind(this);
    }

    componentDidMount = async () => {
        let response;
        const caseId = this.caseId;
        this.user = userService.getUser();
        // this.getNotificationData();
        this.getNotificationCount();
        this.getChatNotifications();

        socket.onmessage = resData => {
            let res = (resData && resData.data) ? JSON.parse(resData.data): [];
            if (res && res.isDisplay && res.data && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(this.user.id) != -1) {
                const data = res.data;
                this.getNotificationCount();
            } 
            if (res && (res.method == 'message' || res.method == 'chat') && res.data && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(this.user.id) != -1) {
                this.getNotificationCount();
                const data = res.data;
            }
            if (res && res.method == 'chat' && res.data && res.data.type == 'join_conference' && res.data.users && Array.isArray(res.data.users) && res.data.users.indexOf(this.user.id) != -1) {
                const data = res.data;
            }
        }
    };

    getNotificationCount() {
        apiService.getNotificationCount({
            type:'global'
        })
        .then(res => {
            if (res.status == 'success') {
                this.setState({notificationCount: res.notificationCount, chatCount: res.chatCount, inviteCount: res.inviteCount, totalCount: res.totalCount});
            }
        });
    }

    getChatNotifications() {
        apiService.getChatNotifications({offset:0,limit:this.count,type:'global'})
        .then(res => {
            if (res.status == 'success') {
                res.data.forEach(e => {
                    let decryptMessage = CryptoJS.AES.decrypt(e.msg_info.msg, apiService.encryptKey(), { format: CryptoJSAesJson, mode: CryptoJS.mode.CBC }); //this.apiService.decrypt(data.message.msg_info.msg);
                    let decryptedData = JSON.parse(decryptMessage.toString(CryptoJS.enc.Utf8));
                    if(decryptedData && decryptedData.message) {
                        e.msg_info.msg = decryptedData.message;
                    } else {
                        e.msg_info.msg = decryptedData;
                    }
                });
                this.setState({chatList: this.chatList.concat(res.data), initLoading: false});
                this.notificationPage = this.notificationPage+1;
                var nextOffset = ((this.notificationPage)*this.count); 
                this.offset = nextOffset;
                if (res.data.length <= nextOffset) {  
                    this.setState({exceedNotifyRecord: true, hasMore: false});
                } else {
                    this.setState({exceedNotifyRecord: false});
                }  
            }
        });
    }

    clearChatNotifications() {
        var notifyId = [];
        this.state.chatList.forEach((paramObj,index) => {
            notifyId.push(paramObj.msg_info._id);
        });
        if (notifyId.length > 0) {
            apiService.clearChatNotifications({notifyId:notifyId})
                .then(res => {
                    if (res.status == 'success') {
                        this.getNotificationCount();
                        this.getChatNotifications();
                        store.dispatch({ type: 'chatCountRefresh', value: true});
                    }
                });
        }
    }

    onClose = () => {
        this.setState({messageNotificationVisible: false});
        this.props.closeMessageNotificationVisible(false);
    };

    navigateToPage(data) {
        let link;
        this.onClose();
        switch (data.type) {
            case 'chat':
                link = {
                    link_id: data.casedetails.room_id
                };
                this.props.history.push('/app/case/'+(data.casedetails.case_case_id)+'/message/'+btoa(JSON.stringify(link)));
                break;
            default:
                this.props.history.push('/app/case/'+(data.casedetails.case_case_id)+'/overview');
                break;
        }
    }

    render() {
        const { messageNotificationVisible, initLoading, chatList, exceedNotifyRecord, chatCount, hasMore } = this.state;
        return (
            <div>
                <Drawer
                    title={<Row className="notification-drawer-header"><Col span={16} className="notification-header">Message</Col> <Col span={8} className="notification-clear-all" onClick={this.clearChatNotifications}>Clear all</Col></Row>}
                    className="notification-drawer"
                    id="notification-drawer"
                    placement="top"
                    forceRender={true}
                    height={"100%"}
                    onClose={this.onClose}
                    visible={messageNotificationVisible}
                    >
                         {initLoading?loaderList.map((item, index) => (<Card key={index}>
                                                            <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                        </Card>)):
                        <div id="scrollableMsgDiv"
                            style={{
                                height: 'calc(100vh - 125px)',
                                overflow: 'auto',
                                display: 'flex',
                            }}
                        >
                            <BackTop style={{bottom: 80 }} target={() => document.getElementById("scrollableMsgDiv")} />
                            <InfiniteScroll
                                dataLength={chatList.length}
                                next={this.getChatNotifications}
                                hasMore={hasMore}
                                loader={loaderList.map((item, index) => (<Card key={index}>
                                    <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                </Card>))}
                                scrollableTarget="scrollableMsgDiv"
                            >
                                <Row>
                                    { chatList.length > 0 
                                    ? chatList.map((chatData, index) => (
                                        <Card className="message-notification-updates" key={index} onClick={() => this.navigateToPage(chatData)}>
                                            <Row>
                                                <Col span={3}><Avatar src={chatData.user_info.user_image}>{chatData.user_info.p_name}</Avatar></Col>
                                                <Col span={21}>
                                                    <Row className="notification-content">
                                                        <Col>
                                                            {(chatData.msg_info.t && chatData.msg_info.t == 'au') ? <p>{ chatData.user_info?chatData.user_info.name:'Creator' } ({ chatData.user_info?.role_name }) added { chatData.msg_info.msg } to the Group: {chatData.casedetails.room_name}</p> : '' }
                                                            {(chatData.msg_info.t && chatData.msg_info.t == 'ru') ? <p>{ chatData.user_info?chatData.user_info.name:'Creator' } ({ chatData.user_info?.role_name }) removed { chatData.msg_info.msg } from {chatData.casedetails.room_name} group</p> : '' }
                                                            {(chatData.msg_info.t && chatData.msg_info.t == 'ul') ? <p>{ chatData.user_info?chatData.user_info.name:chatData.msg_info.msg } ({ chatData.user_info?.role_name }) left the Group: {chatData.casedetails.room_name}</p> : '' }
                                                            {(chatData.msg_info.t && chatData.msg_info.t == 'r') ? <p>{ chatData.user_info?chatData.user_info.name:'Creator' } ({ chatData.user_info?.role_name }) renamed the Group: {chatData.msg_info.msg}</p> : '' }
                                                            {(chatData.msg_info.t && chatData.msg_info.t == 'jc') ? <p>{ chatData.user_info?chatData.user_info.name:'Creator' } ({ chatData.user_info?.role_name }) {chatData.msg_info.msg} at ({chatData.msg_info.ts.utcLocal().timeFormat()}). Please click on the from the Messages menu to join the conference. </p> : '' }
                                                            {(chatData.msg_info.t && chatData.msg_info.t == 'lc') ? <p>{ chatData.user_info?chatData.user_info.name:'Creator' } ({ chatData.user_info?.role_name }) {chatData.msg_info.msg} at ({chatData.msg_info.ts.utcLocal().timeFormat()})</p> : '' }
                                                            {(!chatData.msg_info.t) ? <><p>{ chatData.user_info.name } ({ chatData.user_info.role_name }) {chatData.casedetails.is_group==1 ? chatData.casedetails.room_name : ''}</p> <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{ chatData.msg_info.msg }</p></> : ''}

                                                        </Col>
                                                    </Row>
                                                    <Row className="notification-case-name-time">
                                                        <Col span={11} className="notification-time">
                                                            <p>{chatData.msg_info.created_at.utcLocal().dateTimeFormat()}</p>
                                                        </Col>
                                                        <Col span={13} className="notification-case-name">
                                                            <p className="notify-case-name">{ chatData.casedetails.case_name }</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))
                                    :
                                        <div className="global-notification-nocontent">
                                            <p className="global-notification-icon">
                                                <EmptyMessageIcon/>
                                            </p>
                                            <p className="global-no-yet">
                                                No new Message related updates yet!
                                            </p>
                                        </div>
                                    }
                                </Row>
                                { exceedNotifyRecord && chatList.length > 0
                                    ?
                                        <Row className="msg-notification-end-content">
                                            <Col>
                                                <p>You’ve reached the end of the list! 🎉</p>
                                            </Col>
                                        </Row>
                                    :
                                        <></>
                                }
                            </InfiniteScroll>
                        </div>
                    }
                </Drawer>
            </div>
        );
    }
}

export default withRouter(MessageNotification);
