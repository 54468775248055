import axios from 'axios';
import { APIURL } from '../../constants';
import * as $ from 'jquery';
import userService from './user.service';
import { message } from 'antd';

const axiosInstance = axios.create({
                            baseURL: APIURL,
                        });
  
axiosInstance.interceptors.request.use(
    (config) => {
      const token = userService.getToken();
      const auth = token ? `Bearer ${token}` : '';
      config.headers.common['Authorization'] = auth;
      return config;
    },
    (error) => Promise.reject(error),
);

let sessionExpire = false;

const catchError = (e) => {
    if (e.response && e.response.status === 401 && !sessionExpire) {
        sessionExpire = true;
        setTimeout(() => {
            sessionExpire = false;
            console.log(sessionExpire)
        }, 5000);
        message.error('Your session timed-out and you were logged out for security reasons.');
        userService.removeUser();
        window.location.href = '/auth/login';
    }
}

const formHeader = {
    "Content-Type": "application/x-www-form-urlencoded;"
}

class ApiService {
    apiUrl = APIURL;

    encryptKey() {
        return '6WUKxDEBAtjBwlKE';
    }
    
    logIn(data) {
        return axiosInstance({
            method: 'POST',
            url: 'auth/login',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        });
    }

    forgotEmail(data) {
        return axiosInstance({
            method: 'POST',
            url: 'auth/forgotEmail',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        });
    }

    forgotPassword(data) {
        return axiosInstance({
            method: 'POST',
            url: 'auth/forgot',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        });
    }

    logout() {
        return axiosInstance({
            method: 'get',
            url: 'auth/logout'
        }).then(({data}) => {
            return data;
        });
    }
    
    sentOTP(data) {
        return axiosInstance({
            method: 'post',
            url: 'auth/sentOTP',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }
    
    resendLink(data) {
        return axiosInstance({
            method: 'post',
            url: 'auth/resendLink',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    verifyOTP(data) {
        return axiosInstance({
            method: 'post',
            url: 'auth/verifyEmailByOtp',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    signup(data) {
        return axiosInstance({
            method: 'post',
            url: 'auth/signup',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getSiteSettings() {
        return axiosInstance({
            method: 'get',
            url: 'common/getSiteSettings'
        }).then(({data}) => {
            return data;
        });
    }

    getAllCasesList(data) {
        return axiosInstance({
            method: 'get',
            url: 'cases/getAllCasesList',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getSingleCase(data) {
        return axiosInstance({
            method: 'post',
            url: 'cases/getSingleCase',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getCaseActivity(data){
        return axiosInstance({
            method: 'POST',
            url: 'cases/getCasesActivity',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }
    getCaseInfo(data){
        return axiosInstance({
            method: 'POST',
            url: 'cases/getCasesInfo',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }
    getTagList(data){
        return axiosInstance({
            method: 'get',
            url: 'common/getTagsList',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }
    getCaseUsersNotificationsList(data) {
        return axiosInstance({
            method: 'get',
            url: 'common/getCaseUsersNotificationsList',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getAppointCalender(data) {
        return axiosInstance({
            method: 'get',
            url: 'common/appointments',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getChatRoom(data) {
        return axiosInstance({
            method: 'post',
            url: 'chat/getChatRoom',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    createRoom(data) {
        return axiosInstance({
            method: 'post',
            url: 'chat/imCreate',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getGroupChatData(data) {
        return axiosInstance({
            method: 'post',
            url: 'chat/channelMesages',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getChatData(data) {
        return axiosInstance({
            method: 'post',
            url: 'chat/imMessage',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    sendMessage(data) {
        return axiosInstance({
            method: 'post',
            url: 'chat/sendMessage',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getWithdrawReasons(data) {
        return axiosInstance({
            method: 'get',
            url: 'cases/getWithdrawReasons',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    withdrawCase(data) {
        return axiosInstance({
            method: 'post',
            url: 'cases/withdrawCase',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getUsersNotifications(data) {
        return axiosInstance({
            method: 'post',
            url: 'common/getUsersNotificationsList',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getNotificationCount(data) {
        return axiosInstance({
            method: 'post',
            url: 'common/getNotificationCount',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getChatNotifications(data) {
        return axiosInstance({
            method: 'post',
            url: 'common/getChatNotifications',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    clearChatNotifications(data) {
        return axiosInstance({
            method: 'post',
            url: 'common/clearChatNotifications',
            data: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    clearNotifications(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'common/clearNotifications',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getResolutions() {
        return axiosInstance({
            method: 'get',
            url: 'casefiling/getResolutions'
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getMediatorList(data) {
        return axiosInstance({
            method: 'get',
            url: 'cases/getMediatorList',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getCommonReasons(data) {
        return axiosInstance({
            method: 'get',
            url: 'common/commonReasons',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getFormData(data) {
        const formData = new FormData();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const element = data[key];
                formData.append(key, element);
            }
        }
        return formData;
    }

    caseEscalation(formdata) {
        return axiosInstance({
            method: 'POST',
            url: 'cases/caseEscalation',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    caseEscalationReject(formdata) {
        return axiosInstance({
            method: 'POST',
            url: 'cases/caseEscalationReject',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    caseEscAccept(formdata) {
        return axiosInstance({
            method: 'POST',
            url: 'cases/caseEscalationAccept',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getTaskList(data) {
        return axiosInstance({
            method: 'get',
            url: 'task/getTaskList',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getTaskParties(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'task/getTaskParties',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getSingleTask(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'task/getSingleTask',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getTaskActivity(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'task/getTasksActivity',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    addComment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'common/addComment',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    editComment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'common/editComment',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getComment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'common/getComment',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    updateTaskStatus(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'task/updateTaskStatus',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getPaymentDetails() {
        return axiosInstance({
            method: 'get',
            url: 'common/getTransactions'
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getCountryList() {
        return axiosInstance({
            method: 'get',
            url: 'common/getCountries'
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getStateList(data) {
        return axiosInstance({
            method: 'get',
            url: 'common/getSates',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getCaseParties(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'cases/getCaseParties',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getTimeZone() {
        return axiosInstance({
            method: 'get',
            url: 'common/getTimeZone'
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    completeSetup(data) {
        return axiosInstance({
            method: 'post',
            url: 'common/completeSetup',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    updateprofile(data) {
        return axiosInstance({
            method: 'post',
            url: 'common/updateProfile',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    uploadProfilePhoto(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'common/uploadProfilePhoto',
            data: formdata,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    removeProfilePhoto(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'common/removeProfilePhoto',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    updateCalender(data) {
        return axiosInstance({
            method: 'post',
            url: 'common/updateAccountAndCalendarSetting',
            data: $.param(data),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getCalenderSetting() {
        return axiosInstance({
            method: 'get',
            url: 'common/getCalendarSetting'
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    updateTaskSign(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'task/UpdateTaskSign',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getCasesTaskFiles(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'task/getCasesTaskFiles',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    uploadTaskFiles(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'task/uploadTaskFiles',
            mode: 'no-cors',
            data: formdata,
            withCredentials: false,
            // processData: false,
            // contentType: false,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    deleteFile(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'file/deleteFile',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getFileData(data) {
        return axiosInstance({
            method: 'get',
            url: 'file/view',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getSettlement(data) {
        return axiosInstance({
            method: 'get',
            url: 'settlement/getSettlement',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getPaymentPlugin(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'payment/getPaymentPlugin',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    completeBurton(formdata) {
        formdata['token'] = userService.getToken();
        return axiosInstance({
            method: 'post',
            url: 'payment/completeBurton',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    previewBurtonReceipt(formdata) {
        formdata['token'] = userService.getToken();
        return axiosInstance({
            method: 'post',
            url: 'payment/previewBurtonReceipt',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    deleteProposFile(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/deleteFile',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getParties(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/getParties',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }
    
    viewProposal(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/viewProposal',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    createProposal(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/createProposal',
            data: formdata,
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    updateProposal(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/updateProposal',
            data: formdata,
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getRejectReasons(data) {
        return axiosInstance({
            method: 'get',
            url: 'proposal/getRejectReasons',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getProposalComment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'settlement/getSettlementComment',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    addProposalComment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'settlement/addSettlementComment',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    editProposalComment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'settlement/editSettlementComment',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    outcomesActivity(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/proposalActivity',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    rejectProposal(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/rejectProposal',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    acceptProposal(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/acceptProposal',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    approveReject(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/approveReject',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    approveProposal(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/approveProposal',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    withdrawProposal(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/withdrawProposal',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    previewProposal(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/previewProposal',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }
    
    addFavourite(formdata) {
        return axiosInstance({
            method: 'POST',
            url: 'cases/addFavourite',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    removeFavourite(formdata) {
        return axiosInstance({
            method: 'POST',
            url: 'cases/removeFavourite',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }
    
    getCaseDecisionParties(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/getCaseDecisionParties',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    rejectDecision(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/rejectDecision',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    approveDecision(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'proposal/approveDecision',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getSingleSettlement(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'settlement/getSingleSettlement',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getEventsList(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'calendar/appointments',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }
    
    createAppointment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'calendar/saveAppointment',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    updateAppointment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'calendar/updateAppointment',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    responseToAppointment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'calendar/changeStatus',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    deleteAppointment(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'calendar/deleteAppointment',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    inviteeInfo(data) {
        return axiosInstance({
            method: 'get',
            url: 'cases/inviteeInfo',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    inviteCaseInfo(data) {
        return axiosInstance({
            method: 'get',
            url: 'auth/inviteCaseInfo',
            params: data
        }).then(({data}) => {
            return data;
        });
    }

    acceptCase(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'casefiling/acceptCase',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    forgotToken(data) {
        return axiosInstance({
            method: 'get',
            url: 'auth/forgotToken/'+data
        }).then(({data}) => {
            return data;
        });
    }

    setPasswordToken(data) {
        return axiosInstance({
            method: 'get',
            url: 'auth/setToken/'+data
        }).then(({data}) => {
            return data;
        });
    }

    inviteeToken(data) {
        return axiosInstance({
            method: 'get',
            url: 'auth/inviteeToken/'+data
        }).then(({data}) => {
            return data;
        });
    }

    inviteUserRejectCase(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'auth/inviteUserReject',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        });
    }

    rejectCase(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'casefiling/rejectCase',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    AuthGetCommonReason(data) {
        return axiosInstance({
            method: 'get',
            url: 'auth/commonReasons',
            params: data
        }).then(({data}) => {
            return data;
        });
    }

    resetPassword(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'auth/reset',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        });
    }

    setPassword(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'auth/setPassword',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        });
    }

    setInviteePassword(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'auth/setInviteePassword',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        });
    }

    getAllCaseActivity(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'cases/getAllCasesActivity',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });        
    }

    getRolesName(data) {
        return axiosInstance({
            method: 'get',
            url: 'common/getRolesName',
            params: data
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getCurrentUserRoles() {
        return axiosInstance({
            method: 'get',
            url: 'common/getCurrentUserRoles'
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    switchRole(formdata) {
        return axiosInstance({
            method: 'post',
            url: 'common/switchRole',
            data: $.param(formdata),
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }

    getDraftCases() {
        return axiosInstance({
            method: 'get',
            url: 'casefiling/getDraftCases',
            headers: formHeader
        }).then(({data}) => {
            return data;
        }).catch(e => {
            catchError(e)
        });
    }
}
export default new ApiService();