import React, { useState, useEffect } from "react";
import userService from "../../../logics/services/user.service";
import apiService from "../../../logics/services/api.service";
import { Row, Col, Tabs, Input, Button, Checkbox, Avatar, Drawer, Space, Select, message, Upload, Progress, Spin, Card, Image, Skeleton, Modal } from 'antd';
import Icon from '@ant-design/icons';
import { FileImageOutlined, FileExcelOutlined, FileWordOutlined, FilePptOutlined, CameraOutlined, FileTextOutlined, AudioOutlined } from '@ant-design/icons';
import { websocketService, socket } from "../../../logics/services/websocket.service";
import "../../../logics/prototypes";
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import store from '../../../logics/store/case.store';
import { APIURL } from '../../../constants';
import { encrypt } from '../../../logics/helpers';
import http from "../../../logics/services/http-common";
import PDFView from "./PdfView";
import EditSvg from '../../../assets/svg-icons/edit.svg';
import DeleteSvg from '../../../assets/svg-icons/delete.svg';
import DownloadSvg from  '../../../assets/svg-icons/download_icon.svg';
import ArbitrationSvg from  '../../../assets/svg-icons/arbitration.svg';
import StarSvg from  '../../../assets/svg-icons/star.svg';
import FileUploadSvg from '../../../assets/svg-icons/upload_task_icon.svg';
import SignTaskSvg  from "../../../assets/svg-icons/sign.svg";
import PaymentTaskSvg  from "../../../assets/svg-icons/payment.svg";
import ActivitySvg from '../../../assets/svg-icons/activity.svg';
import CommentSvg from '../../../assets/svg-icons/comment.svg';
import ResponsesBySvg from '../../../assets/svg-icons/responses_by.svg';
import InformationSvg from '../../../assets/svg-icons/information.svg';
import MoreHorizSvg from '../../../assets/svg-icons/more_horiz.svg';
import SendMessageSvg from  '../../../assets/svg-icons/send-message.svg';
import PdfSvg from '../../../assets/svg-icons/pdf.svg';
import EyeSvg from '../../../assets/svg-icons/eye.svg';
import close from "../../../assets/svg-icons/close.svg";
import WaitingSvg from "../../../assets/svg-icons/waiting.svg";


import PdfPng from "../../../assets/no-preview/pdf-view.png";
import ExcelPng from "../../../assets/no-preview/excel.png";
import WordPng from "../../../assets/no-preview/word.png";
import PptPng from "../../../assets/no-preview/ppt.png";
import VideoPng from "../../../assets/no-preview/video.png";
import TextPng from "../../../assets/no-preview/text.png";
import ImagePng from "../../../assets/no-preview/image.png";
import AudioPng from "../../../assets/no-preview/audio-view.png";
import VideoViewPng from "../../../assets/no-preview/video-view.png";
import PaymentCardPng from "../../../assets/no-preview/payment-card.png";
import { StarFillIcon } from "../../../logics/images";
import CaseStatusIcon from "./CaseStatusIcon";

const ArbitrationIcon = props => <Icon component={ArbitrationSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const StarIcon = props => <Icon component={StarSvg} style={{ fontSize: 24, verticalAlign: 'middle'}} {...props} />;
const EditIcon = props => <Icon component={EditSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const DeleteIcon = props => <Icon component={DeleteSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: '8px'}} {...props} />;
const DownloadIcon = props => <Icon component={DownloadSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: '8px'}} {...props} />;
const FileUploadIcon = props => <Icon component={FileUploadSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const SignTaskIcon = props => <Icon component={SignTaskSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const PaymentTaskIcon = props => <Icon component={PaymentTaskSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const ActivityIcon = props => <Icon component={ActivitySvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const CommentIcon = props => <Icon component={CommentSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const ResponsesByIcon = props => <Icon component={ResponsesBySvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const InformationIcon = props => <Icon component={InformationSvg} style={{ fontSize: 24, verticalAlign: 'middle', margin: '0'}} {...props} />;
const MoreHorizIcon = props => <Icon component={MoreHorizSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: 'auto'}} {...props} />;
const SendMessageIcon = props => <Icon component={SendMessageSvg} style={{ fontSize: 20, verticalAlign: 'middle'}} {...props} />;
const PdfIcon = props => <Icon component={PdfSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginRight: '8px'}} {...props} />;
const EyeIcon = props => <Icon component={EyeSvg} style={{ fontSize: 24, verticalAlign: 'middle', marginLeft: '16px'}} {...props} />;
const CloseIcon = props => <Icon component={close} style={{ fontSize: 24, verticalAlign: "middle", marginLeft: 'auto' }} {...props} />;
const WaitingIcon = props => <Icon component={WaitingSvg} style={{ fontSize: 236, verticalAlign: "middle" }} {...props} />;

const { TabPane } = Tabs;
const { TextArea } = Input;

const activityLoaderList = [1,2];
const patterns = {
    isImageFilePattern: /\.(jpe?g|gif|bmp|png|svg)$/i,
    isImageNSFilePattern: /\.(tiff)$/i,
    isVideoFilePattern: /\.(mp4|webm|3gp|ogg)$/i,
    isVideoNSFilePattern: /\.(flv|wmv|avi)$/i,
    isAudioFilePattern: /\.(mp3)$/i,
    isPdfFilePattern: /\.(pdf)$/i,
    isDocFilePattern: /\.(doc|docx)$/i,
    isExcelFilePattern: /\.(xls|xlsx|csv)$/i,
    isTxtFilePattern: /\.(txt)$/i,
    isPptFilePattern: /\.(ppt|pptx)$/i,
    isExtractableFilePattern: /\.(gz|tar|rar|g?zip)$/i,
    isViewable: /\.(jpe?g|gif|bmp|png|svg|mp3|mp4|webm|3gp|ogg|pdf|txt)$/i,
    isUploadable: /\.(jpe?g|gif|bmp|png|svg|tiff?|mp3|mp4|3gp|flv|wmv|mkv|avi|webm|ogg|pdf|doc|docx|csv|xls|xlsx|ppt|pptx|txt)$/i
};

class TaskView extends React.Component {
    
    constructor(props) {
        
        super(props);
        this.siteSetting=store.getState().settings
        this.caseId=this.props.caseId;
        this.visible=this.props.visible;
        this.taskId=this.props.taskId;
        this.user=this.props.user;
        this.userId=this.user.id;

        this.canViewUser = 0;
        this.remainingMCCount = 0;
        this.remainingCVCount = 0;
        this.uploadList = [];
        this.uploadCount = [];
        this.editList = [];
        this.state = {
            caseInfo: [],
            loading: false,
            taskViewVisible: this.visible,
            confirm: false,
            taskId: this.taskId,
            userId: this.userId,
            editVisible: false,
            parentDir: '',
            parentId: 0,
            pdfData: '',
            taskUploadedFilesShow: false,
            taskActivityLoading: false,
            taskComment: '',
            showPdf: false,
            signPassword: '',
            isAggreed: false,
            uploadFileName: '',
            showUploadPdf: false,
            uploadFileData: '',
            showFileDrawer: false,
            showUploadTxt: false,
            showUploadAudio: false,
            showUploadVideo: false,
            showUploadNoPreview: false,
            showPaymentPreview: false,
            showPaymentDrawer: false,
            paymentViewFileName: '',
            paymentViewFileData: '',
            uploadFileId: 0,
            viewResponseByUser: 0,
            receiptUId: 0,
            taskDetail: [],
            mustComplete: [],
            canView: [],
            taskCommentList: [],
            taskActivityList: [],
            filesList: [],
            fileIDS: [],
            uploadList: [],
            uploadCount: [],
            silderFileIds: [],
            isUserView: false,
            userViewParam: [],
            userViewType: '',
            editComment: '',
            editCommentId: '',
            isDescriptionShow: false,
            maxFilesEr: false
        };
    }

    componentDidMount = async () => {
        let response;
        const caseId = this.caseId;
        this.user = userService.getUser();
        this.setState({caseInfo: store.getState().case});
        // apiService.getCaseInfo({
        //     case_id: caseId,
        // }).then((res) => {
        //     if (res.status === "success") {
        //         this.setState({caseInfo: res.data});
        //     }
        // });
        // let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        // element.style.display = 'none';
        this.getTaskDetail();
        this.webSocketReceive();
    };

    webSocketReceive () {
        socket.onmessage = resData => {
            let res = (resData && resData.data) ? JSON.parse(resData.data): [];
            if (res && res.method == 'comments' && res.data && (res.data.type == 'add_task' || res.data.type == 'edit_task') && res.data.case_id == this.caseId && res.data.users.indexOf(this.state.userId) != -1) {      
                this.getComment();                    
            } else if (res && res.method == 'task' && res.data && res.data.type == 'task_complete' && res.data.case_id == this.caseId && res.data.tk_id == this.state.taskId && res.data.users.indexOf(this.state.userId) != -1) { 
                apiService.getSingleTask({
                    tk_id: this.state.taskId
                }).then(res => {
                    this.setState({taskDetail: res.data, mustComplete: [], canView: []}); 
                    this.canViewUser = 0; 
                    let anyoneTaskCompleted = 0;
                    this.state.taskDetail.assigned_to.forEach(ass => {
                        if(ass.permissions == 'Must complete') {
                            this.setState({mustComplete: this.state.mustComplete.concat(ass)});
                            if (this.state.userId == ass.u_id && ass.status == 'Completed') {
                                this.setState({taskUploadedFilesShow: true});
                            }
                            if (ass.status == 'Completed') { 
                                anyoneTaskCompleted = 1;
                            }
                        } else if(ass.permissions == 'Can view all responses') {
                            this.setState({canView: this.state.canView.concat(ass)});
                            if (this.state.userId == ass.u_id) {
                                this.canViewUser = 1;            
                            }      
                        }   
                    });
                    var mustCompleteLength = this.state.mustComplete.length;
                    var canViewLength = this.state.canView.length;
                    if (mustCompleteLength > 3) {
                        this.remainingMCCount = mustCompleteLength-3;
                    }
                    if (canViewLength > 3) {
                        this.remainingCVCount = canViewLength-3;
                    }
                });
            } else if(res && res.method == 'task' && (res.data.type == 'task_delete' && res.data.tk_id == this.state.taskId)) {
                if(res.data.task_type != 'payment') {
                    this.onClose();
                } else {
                    this.onClose();                                   
                }                                                                                                       
            }
        }
    }

    onClose = () => {
        this.setState({taskViewVisible: false});
        this.props.closeTaskViewVisible(false);
        this.props.history.push('/app/case/'+this.caseId+'/tasks');
        let element = document.getElementsByClassName('bottom-fixed-menu')[0];
        element.style.display = 'flex';
    };

    _renderCounter = () => () => {
        const [current, setState] = React.useState('mycases');
        const [page, setPage] = React.useState(1);
        const [offset, setOffset] = React.useState(0);
        const handleClick = e => {
            setState(e.key);
            if (e.key === 'allactivity') {
                history.push('/app/allcases-activity');
            }
        };
    }

    getTaskDetail() {
        this.setState({loading: true});
        apiService.getSingleTask({
            tk_id: this.state.taskId
        }).then(res => {
            this.setState({taskDetail: res.data, parentDir: res.data.file_dir, parentId: res.data.f_f_id, pdfData: res.data.url, taskUploadedFilesShow: false});            
            this.canViewUser = 0; 
            let anyoneTaskCompleted = 0;
            this.state.taskDetail.assigned_to.forEach(ass => {
                if(ass.permissions == 'Must complete') {
                    this.setState({mustComplete: this.state.mustComplete.concat(ass)});
                    if (this.state.userId == ass.u_id && ass.status == 'Completed') {
                        this.setState({taskUploadedFilesShow: true});
                    }
                    if (ass.status == 'Completed') { 
                        anyoneTaskCompleted = 1;
                    }
                } else if(ass.permissions == 'Can view all responses') {
                    this.setState({canView: this.state.canView.concat(ass)});
                    if (this.state.userId == ass.u_id) {
                        this.canViewUser = 1;            
                    }      
                }   
            });
            if (this.state.taskDetail.task_status == 'Completed') {
                this.setState({taskUploadedFilesShow: true});
            }
            if (this.canViewUser == 1 && anyoneTaskCompleted == 1) {
                this.setState({taskUploadedFilesShow: true});
            }
            var mustCompleteLength = this.state.mustComplete.length;
            var canViewLength = this.state.canView.length;
            if (mustCompleteLength > 3) {
                this.remainingMCCount = mustCompleteLength-3;
            }
            if (canViewLength > 3) {
                this.remainingCVCount = canViewLength-3;
            }  
            if (this.state.taskDetail.type == 'file') {   
                this.getCasesTaskFiles(); 
            }  else {
                this.setState({loading: false});
            }               
            //this.loading = false;
        });
        
    }

    getCasesTaskFiles(load = true, responseUserId = this.state.viewResponseByUser) {
        this.setState({loading: load});
        apiService.getCasesTaskFiles({
            case_id: this.caseId,
            parent: this.state.parentId,
            tk_tk_id:this.state.taskId,
           viewResponseByUser : responseUserId,
            canViewUser : this.canViewUser
        }).then((res) => {
            if (res.status == 'success') {
                let filesList
                this.setState({filesList: res.data.filter(e => {return e && e.is_folder == 0;})});
                res.data.forEach(e => {
                    this.setState({fileIDS: this.state.fileIDS.concat(e.f_id)});
                });
                this.state.uploadList.forEach(e => {
                    if (e.show_upload == 'true') {
                        this.setState({fileIDS: this.state.fileIDS.concat(e.result.f_id)});
                    }
                });
                this.pathList = res.paths;
                if(this.pathList.length > 2) {
                    let icon =[];
                    let pathList = [];	
                    icon.push({['icon']: true});					
                    pathList = this.pathList.slice(-2);
                    this.pathLists = [...icon, ...pathList];					
                } else  {						
                    this.pathLists = this.pathList
                }					
                filesList = this.state.filesList.filter((e) => {
                    if(e && e.is_folder == 0) {
                        return e;
                    }						
                });
                this.setState({silderFileIds: filesList.map(e => e.f_id)});
            }
            this.setState({loading: false});
        });
    }

    getTaskActivity() {
        apiService.getTaskActivity({
            task_id: this.state.taskId
        }).then(res => {
            if (res.status == 'success') {
                this.setState({taskActivityList: res.data, taskActivityLoading: false});
            }
        });
    }

    getComment() {
        apiService.getComment({
            tkId: this.state.taskId,
            caseId: this.caseId,
            type : 'task'
        }).then(res => {
            if (res.status == 'success') {
                this.setState({taskCommentList: res.data, taskCommentLoading: false});
            }                
        }, () => {
        });
    }

    onTaskTabChange = key => {
        if(key == "comment") {
            this.setState({taskCommentLoading: true});
            this.getComment();
        } else if(key == "activity") {
            this.setState({taskActivityLoading: true});
            this.getTaskActivity();
        }
    };

    taskCommentChange = (e) =>  {
        this.setState({taskComment: e.target.value});
    }

    taskAddComment() {
        apiService.addComment({
            caseId: this.caseId,
            tkId: this.state.taskId,
            comment: this.state.taskComment,
            parent_id: 0,
            type : 'task'
        }).then((res) => {
            if(res.status == 'success') {
                message.success(res.message);
                this.getComment();
            } else {
                message.error(res.message);
            }
        });
        this.setState({taskComment: ''});
		let content  = 'has added the comments.';
		websocketService.send({
			method: 'comments',
			display: true,
			isDisplay:true,
			data: {
				users: this.state.taskDetail.assign_to_ids.split(",").map(Number),
				content: content,
				type: 'add_task',
				time_display: false
			}
		});
	}

    setCompleteTask() {
        this.setState({loading: true});
        apiService.updateTaskStatus({
            tk_id: this.state.taskId,
            tk_st_id: this.state.taskDetail.assignee_status.tk_st_id,
            status: 'completed'
        }).then(res => {
            this.setState({loading: false});
            if (res.status == 'success') {
                this.onClose();                   
                message.success(res.message);
            } else {
                message.error(res.message);
            }
            websocketService.send({
                method: 'task',
                isDisplay:true,
                data: {
                    users: this.state.taskDetail.assign_to_ids.split(",").map(Number),
                    content: res.instant_msg,
                    type: 'task_complete',
                    time_display: false,
                    tk_id: this.state.taskId
                }
            });
        }, () => {
            message.error('Unable to process the data');
        });

    }

    editTask = () => {
        console.log("click");
    }

    onShowPDF = (showPdf) => {
        this.setState({showPdf: !showPdf});
    }

    handleIsAggreedConfirm = (isAggreed) =>  {
        this.setState({isAggreed: !isAggreed});
    }

    signPasswordChange = (e) =>  {
        this.setState({signPassword: e.target.value});
    }

    updateTaskSign() {
        let encryptPwd = encrypt(this.state.signPassword).toString();
        this.setState({loading: true, signPassword: ''});
        apiService.updateTaskSign({
            password: encryptPwd,
            tk_st_id: this.state.taskDetail.assignee_status.tk_st_id,
            tk_tk_id: this.state.taskDetail.task_id,
            f_id: this.state.taskDetail.files_id
        })
        .then(res => {
            if (res.status == 'success') {
                this.onClose();  
                message.success(res.message);               
            } else {
                message.error(res.message);
            }
            this.setState({loading:false, signPassword: ''});
            websocketService.send({
                method: 'task',
                isDisplay:true,
                data: {
                    users: this.state.taskDetail.assign_to_ids.split(",").map(Number),
                    content: res.instant_msg,
                    type: 'task_complete',
                    time_display: false,
                    tk_id: this.state.taskDetail.task_id
                }
            });
        }, () => {
            message.error('Unable to process the data');
        });
    }

    beforeUpload = (item, filelist) => {
        if (filelist.length > 10) {
            // if (!this.state.maxFilesEr) {
            //     this.setState({ maxFilesEr: true });
            //     message.error('You can upload maximum 10 files at a time.');
            //     setTimeout(() => {
            //         this.setState({ maxFilesEr: false });
            //     }, 1000);
            // }
            return Upload.LIST_IGNORE;
        }
        let isFile= { 
            isUploadable: /\.(jpe?g|gif|bmp|png|svg|tiff?|mp3|mp4|3gp|flv|wmv|mkv|avi|webm|ogg|pdf|doc|docx|csv|xls|xlsx|ppt|pptx|txt)$/i
        };

        const isvalid = isFile.isUploadable.test(item.name);
        if (!isvalid) {
            message.error('You can only upload images, audio, video and documents');
            return Upload.LIST_IGNORE;
        }

        const isLt15M = item.size / 1024 /1024 < 300;
        if (!isLt15M) {
            message.error('You can only upload file size less then 300mb');
            return Upload.LIST_IGNORE;
        }

        this.uploadIndex = this.uploadList.length;
        item.index = this.uploadIndex;
        item.countIndex = this.uploadCount.length;
        this.uploadCountIndex = this.uploadCount.length;
        if ((this.uploadCountIndex + 1) > 10) {
            message.error('You can upload maximum 10 files at a time.');
            return Upload.LIST_IGNORE;
        }

        let fType;				
        if(item.type == 'application/vnd.ms-excel' || item.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')	{
            fType = 'excel'
        } else if(item.type == 'application/pdf') {
            fType = 'pdf';
        } else if(item.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || item.type == 'application/msword') {
            fType = 'word';
        } else if(item.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || item.type == 'application/vnd.ms-powerpoint') {
            fType = 'ppt';
        } else if(item.type == 'image/gif') {
            fType = 'gif';
        } else if(item.type == 'image/jpeg' || item.type == 'image/png' || item.type == 'image/svg+xml' || item.type == 'image/bmp' || item.type == 'image/tiff' || item.type == 'image/x-tiff') {
            fType = 'image';
        } else if(item.type == 'audio/mpeg' || item.type == 'audio/ogg') {
            fType = 'audio';
        } else if(item.type == 'video/mp4' || item.type == 'video/3gpp' || item.type == 'video/webm' || item.type == 'video/x-flv' || item.type == 'video/x-ms-wmv' || item.type == 'video/mkv' || item.type == 'video/avi' || item.name.split('.').pop() == 'flv') {
            fType = 'video';
        } else if(item.type == 'text/plain') {
            fType = 'txt';
        }

        this.setState({uploadList: this.state.uploadList.concat({file: item, fType: fType, result:{},show_upload : 'true'})});
        this.setState({uploadCount: this.state.uploadCount.concat({file: item, fType: fType, result:{},show_upload : 'true'})});
        this.uploadList = this.uploadList.concat({file: item, fType: fType, result:{},show_upload : 'true'});
        this.uploadCount = this.uploadCount.concat({file: item, fType: fType, result:{},show_upload : 'true'});
    }

    handleChange = () => {
        this.setState({uploadCount: []});
        this.uploadCount = [];
    }

    fileUpload = (options) => {

        const { onSuccess, onError, file, onProgress } = options;

        let index = file.index;
		let countIndex = file.countIndex;

        const config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: event => {
                let percent = Math.floor((event.loaded / event.total) * 100);
                if(this.uploadList[index]) {
                    this.uploadList[index].error = false;
                    this.uploadList[index].show_upload = true;
                    this.uploadList[index].file.percent = percent;
                }
                this.setState({uploadList: this.uploadList});
                if (percent === 100) {
                    setTimeout(() => {
                        this.uploadList = this.uploadList.filter((item, itemIndex) => {
                            return item.file.uid !== file.uid 
                        })
                        this.setState({uploadList: this.uploadList});
                    }, 3000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };

        const formData = new FormData();
        formData.append('file', file);
		formData.append('file_dir', this.state.parentDir);
        formData.append('case_id', this.caseId);
        formData.append('tk_id', this.state.taskId);
        formData.append('file_id', this.state.parentId);
        formData.append('type', 'task');
        formData.append('token', userService.getUser().token);

        http.post(APIURL + "task/uploadTaskFiles", formData, config, e => {
        }).then((response) => {
            return this.getCasesTaskFiles(false);
        }).catch(() => {
            this.uploadList[index].error = true;
            this.uploadList[index].show_upload = false;
            this.setState({uploadList: this.uploadList});
        });
    }
    
    deleteFile = (file_id, index, type) => {
		apiService.deleteFile({
            f_id: file_id,
            case_id: this.caseId,
            isTask : true,
            tk_tk_id: this.state.taskId
        }).then(res => {
            if(res.status == 'success') {
                message.success(res.message);
                this.setState({ uploadCount: []});
                this.uploadCountIndex = 0;
                if (type == 'current_files') {
                    let fileIndex = this.state.uploadList.findIndex(x => x.result.f_id === file_id);
                    this.state.uploadList.splice(fileIndex, 1);
                } else {
                    let fileIndex = this.state.filesList.findIndex(x => x.f_id === file_id);
                    this.state.filesList.splice(fileIndex, 1);
                }   
                this.setState({ fileIDS: []});
                this.state.filesList.forEach(e => {
                    this.setState({fileIDS: this.state.fileIDS.concat(e.f_id)});
                });
                this.state.uploadList.forEach(e => {
                    if (e.show_upload == 'true') {
                        this.setState({fileIDS: this.state.fileIDS.concat(e.result.f_id)});
                    }
                });                                   
            } else {
                message.error(res.message);
            }	
        });
	}

    checkUploadedFiles = () => {
        let showDownload = false;
        
        this.uploadList.filter(element => {           
            if (element.show_upload == 'true') {
                showDownload = true;                
            } 
        });
        return showDownload;
    }

    uploadInprogress = () => {
        let inprogress = false;  
        this.uploadList.filter(element => {
            if (element.show_upload == 'true' && element.file.percent < 100) {
                inprogress = true;
            } 
        });
        return inprogress;
    }

    viewFile = (fileName, fileId) => {
        this.setState({loading: true});
        apiService.getFileData({
            f_id: fileId
        }).then((res) => {
            if (res.status == 'success') {
                this.selectedFile = res.data;	
                this.selectedFile.isImage = patterns.isImageFilePattern.test(this.selectedFile.file.file_name);
                this.selectedFile.isVideo = patterns.isVideoFilePattern.test(this.selectedFile.file.file_name);
                this.selectedFile.isAudio = patterns.isAudioFilePattern.test(this.selectedFile.file.file_name);
                this.selectedFile.isPdf = patterns.isPdfFilePattern.test(this.selectedFile.file.file_name);
                this.selectedFile.isDoc = patterns.isDocFilePattern.test(this.selectedFile.file.file_name);
                this.selectedFile.isTxt = patterns.isTxtFilePattern.test(this.selectedFile.file.file_name);
                this.selectedFile.isExcel = patterns.isExcelFilePattern.test(this.selectedFile.file.file_name);
                this.selectedFile.isPpt = patterns.isPptFilePattern.test(this.selectedFile.file.file_name);
                this.selectedFile.isNSVideo = patterns.isVideoNSFilePattern.test(this.selectedFile.file.file_name);
                this.selectedFile.isNSImage = patterns.isImageNSFilePattern.test(this.selectedFile.file_name);
                this.selectedFile.isViewable = patterns.isViewable.test(this.selectedFile.file.file_name);
                if (this.selectedFile.isPdf) {
                    this.setState({uploadFileId: fileId, uploadFileName: fileName, uploadFileData: res.data.url, showFileDrawer: true, showUploadPdf: true, loading: false});
                } else if (this.selectedFile.isTxt) {
                    this.setState({uploadFileId: fileId, uploadFileName: fileName, uploadFileData: atob(res.data.url).replace(new RegExp('\r?\n','g'), '<br />'), showFileDrawer: true, showUploadTxt: true, loading: false});
                } else if (this.selectedFile.isDoc) { 
                    this.setState({uploadFileId: fileId, uploadFileName: fileName, uploadFileData: res.data.url, showUploadNoPreview: true, showFileDrawer: true, loading: false});
                } else if (this.selectedFile.isAudio) {
                    this.setState({uploadFileId: fileId, uploadFileName: fileName, uploadFileData: res.data.url, showFileDrawer: true, showUploadAudio: true, loading: false});
                } else if (this.selectedFile.isVideo) {
                    this.setState({uploadFileId: fileId, uploadFileName: fileName, uploadFileData: res.data.url, showFileDrawer: true, showUploadVideo: true, loading: false})
                } else {
                    this.setState({uploadFileId: fileId, uploadFileName: fileName, uploadFileData: res.data.url, showUploadNoPreview: true, showFileDrawer: true, loading: false});
                }
            }
        });
    }

    onShowUploadFileDrawer = (showFileDrawer) => {
        this.setState({showFileDrawer: !showFileDrawer, showUploadPdf: false, showUploadTxt: false, showUploadAudio: false, showUploadVideo: false, showUploadNoPreview: false});
    }

    viewResponseBy = (user_id) => {
        let responseUserId;
        if(this.state.viewResponseByUser == user_id) {
            this.setState({viewResponseByUser: 0});
            responseUserId = 0;
        } else {
            this.setState({viewResponseByUser: user_id});
            responseUserId = user_id;
        }
        this.getCasesTaskFiles(true, responseUserId); 
    }

    payNow() {     
        this.setState({loading: true});
        apiService.getPaymentPlugin({                
            amount: this.state.taskDetail.assignee_status.amount
        }).then(res => {
            if(res.status == 'success') {             
                this.pluginSessionId = res.data.plugin_session_id;
                let paymentUrl = res.data.plugin_url.split('?');                                   
                let chatScript = document.createElement("script");
                chatScript.type = "text/javascript";
                chatScript.async = true;
                chatScript.src = paymentUrl[0];
                document.getElementsByTagName('head')[0].appendChild(chatScript);       
                setTimeout(() => {
                    this.showPayment();
                }, 5000); 
            } else if(res.status == 'error') {
                this.setState({loading: false});
                message.error(res.message);
            }
        });                
    }

    showPayment() {
        if(window.i3Plugins == undefined) { 
            this.showPayment();
            return;
        }
        let _this = this;
        window.i3Plugins.onPluginCreated = function(type, plugin) {                           
            if (type == 'payment') {                                                                                            
                plugin.onEmbed = function(msg) {                                                                                                    
                    return "burton-pay";                       
                }
                plugin.onSale = function(msg) {
                    apiService.completeBurton({
                            tk_tk_id: _this.state.taskDetail.task_id,
                            case_id: _this.state.taskDetail.case_id,
                            currency_code: _this.siteSetting?_this.siteSetting.crid:'USD',
                            response: msg
                        }).then(res => {
                            if(res.status == 'success') {
                                // _this.close();  
                                _this.getTaskDetail();
                                message.success(res.message);
                                websocketService.send({
                                    method: 'task',
                                    isDisplay: true,
                                    data: {
                                        users: _this.state.taskDetail.assign_to_ids.split(",").map(Number),
                                        content: res.instant_msg,
                                        type: 'task_complete',
                                        time_display: false,
                                        tk_id: _this.state.taskDetail.task_id
                                    }
                                });
                            }
                        });                                             
                    this.hide(); // Call the plugin's hide function to hide it
                }
                plugin.onCancel = function(msg) { 
                    _this.setState({loading: false});
                    this.hide(); // Call the plugin's hide function to hide it
                }   
                plugin.onReady = function(msg) {
                    _this.setState({loading: false});                
                    var values = {                                
                        contact: {
                            email: _this.user.email,
                            first_name: _this.user.first_name,
                            last_name: _this.user.last_name,
                            // address1: '300 Oak Street',
                            // address2: '',
                            // city: 'Detroit',
                            // country: 'USA',
                            // state: 'WA',
                            // postal_code: '90210'
                        },                                
                    }                                                                  
                    plugin.setValues(values);                    
                } 
                plugin.onSubmitError = function(msg) {
                    let showErr = false;
                    if(msg.fields.length != 0 && msg.fields[0] == 'expiration_date') {
                        var error = {
                            messages: [
                                'Expiration Year is invalid',
                            ],
                            fields: [
                                'expiration_date',
                            ]
                        }
                        showErr = true;
                    } else if(msg.fields.length != 0 && msg.messages.length == 0 && msg.fields[0] == 'routing_number') {
                        // let message = msg.messages.length == 0 ? 'Routing Number is required' : msg.messages[0];  
                        if(msg.messages.length == 0) {            
                            var error = {
                                messages: [
                                    'Routing Number is required',
                                ],
                                fields: [
                                    'routing_number',
                                ]
                            }
                        }    
                        showErr = true;
                    } else if(msg.fields.length != 0 && msg.fields[0] == 'card_number') {
                        var error = {
                            messages: [
                                'Card Number is required'
                            ],
                            fields: [
                                'card_number'
                            ]
                        }
                        showErr = true;
                    } 

                    if(showErr)   
                        plugin.setValidationErrors(error);
                }
                plugin.onRequestError = function(msg) {
                    console.log(msg);
                }                                                                                             
            }
        }
        
        window.i3Plugins.launch(this.pluginSessionId);
    }

    close() {
        this.setState({showPaymentPreview: !this.state.showPaymentPreview, loading: false});
    }

    viewReceipt(userId) {
        this.setState({loading: true, receiptUId: userId});
        apiService.previewBurtonReceipt({
            case_id: this.caseId,
            tk_id: this.state.taskDetail.task_id,
            requested_by: userId
        }).then(res => {
            if(res.status == 'success') {
                this.setState({showPaymentPreview: true, loading: false, showPaymentDrawer:true, paymentViewFileData: res.data.url, paymentViewFileName: res.data.file_name});
            }
        })
    }

    onShowPaymentViewFileDrawer = (showPaymentDrawer) => {
        this.setState({showPaymentDrawer: !showPaymentDrawer});
    }

    showUserView = (data, type) => {
        this.setState({isUserView: true, userViewParam: data, userViewType: type});
    };

    closeUserView = () => {
        this.setState({isUserView: false});
    };

    forgotPassword() {
        this.setState({loading: true});
        apiService.logout()
        .then((res) => {
            if (res.status === 'success') {
                userService.removeUser();
                this.props.history.push("/auth/forgotpassword");
            }
        });
    }

    showEditComment(comment) {
        this.setState({editComment: comment.comment, editCommentId: comment.cmt_id});
		let r = !this.editList[comment.cmt_id];
		this.editList.forEach((e, k) => {
			this.editList[k] = false;
		});
		this.editList[comment.cmt_id] = r;		
	}

    closeEditComment(comment) {
        this.setState({editComment: '', editCommentId: ''});
		let r = !this.editList[comment.cmt_id];
        this.editList[comment.cmt_id] = r;
	}
    
    taskEditCommentChange = (e) =>  {
        this.setState({editComment: e.target.value});
    }

    taskUpdateComment(id) {
        this.setState({loading: true});
        apiService.editComment({
            caseId: this.caseId,
            tk_cmt_id: this.state.editCommentId,
            comment: this.state.editComment,
            type : 'task'
        }).then((res) => {
            if(res.status == 'success') {
                message.success(res.message);
                this.getComment();
            } else {
                message.error(res.message);
            }
        });
        this.setState({editComment: '', editCommentId: '', loading: false});
		this.editList[this.state.editCommentId] = false;
		let content = 'has edited the comments.';
        websocketService.send({
            method: 'comments',
            display: true,
            isDisplay:true,
            data: {
                users: this.state.taskDetail.assign_to_ids.split(",").map(Number),
                content: content,
                type: 'edit_task',
                time_display: false
            }
        });		
	}

    htmlDecode(content) {
        const htmlNode = document.createElement('div');
        htmlNode.innerHTML = content;
        htmlNode.querySelectorAll('*').forEach(function(node) {
            node.removeAttribute('style');
        });
        return htmlNode.innerHTML;
    }

    toggleDescription(isDescriptionShow) {
        this.setState({isDescriptionShow: !isDescriptionShow});
    }

    render() {
        const { userId, taskViewVisible, taskDetail, loading, caseInfo, mustComplete, canView, taskActivityList, taskActivityLoading, taskComment, taskCommentList, taskCommentLoading, editVisible, showPdf, pdfData, isAggreed, signPassword, taskUploadedFilesShow, filesList, uploadList, uploadFileName, showUploadPdf, uploadFileData, showFileDrawer, showUploadTxt, showUploadAudio, showUploadVideo, showUploadNoPreview, uploadFileId, viewResponseByUser, showPaymentDrawer,  paymentViewFileName, paymentViewFileData, showPaymentPreview, receiptUId, isUserView, userViewParam, userViewType, editComment, isDescriptionShow } = this.state;

        return (
            <div>
                <Drawer
                    title={taskDetail && taskDetail.type == 'file' ? "Task: File Upload": taskDetail && taskDetail.type == 'task' ? "Task: Sign Document": taskDetail && taskDetail.type == 'general' ? "Task: General": taskDetail && taskDetail.type == 'payment' ? "Task: Make Payment": "" }
                    className="custom-drawer-popup task-view-drawer"
                    placement="top"
                    height={"100%"}
                    onClose={this.onClose}
                    visible={taskViewVisible}
                    // extra={
                    //     <Space>
                    //         {editVisible && (<EditIcon className={taskDetail.status == 1 && taskDetail.assigned_by.u_id == userId.id ? "icon-disable": ""} onClick={() => this.editTask()} />)}
                    //         <DeleteIcon className={taskDetail.status == 1 && taskDetail.assigned_by.u_id == userId.id ? "icon-disable": ""} onClick={() => this.deleteTask()} />
                    //         <DownloadIcon className={taskDetail.type == 'file' ? "icon-disable": ""} onClick={() => this.downloadTask()} />
                    //     </Space>
                    //   }
                >
                        
                        {/* <Row className="sub-header case-name-header">
                            <Col span={20} className="case-name-bar">
                                <ArbitrationIcon className="fill-svg-w" />
                                <span className="pl16 case-name"><b>{caseInfo ? caseInfo.case_name:''}</b></span>
                                <span className="pl8">
                                    <StarIcon />
                                </span>
                            </Col>
                        </Row> */}
                        <Row className="sub-header case-name-header">
                            <Col span={20} className="case-name-bar">
                                <CaseStatusIcon caseId={this.caseId} case_status={caseInfo?.case_status} case_type={caseInfo?.case_type} size={24}/>
                                <span className="pl16 case-name"><b>{caseInfo?.case_name}</b></span>
                                <span className="pl8">
                                    { !caseInfo?.isFav ? <StarIcon />: <StarFillIcon />}
                                </span>
                            </Col>
                        </Row>

                        <Spin spinning={loading} delay={500}>
                            {taskDetail && taskDetail.assigned_by
                                ?
                                    <>
                                        <Row className="task-details p16">
                                            <Col span={24}>
                                                <p className="task-title mb16">{taskDetail.title}</p>
                                            </Col>
                                            <Col span={24}>
                                                {   taskDetail.description && taskDetail.description.length >= 250
                                                    ?
                                                        <>
                                                            {   !isDescriptionShow
                                                                ?
                                                                    <p className="task-description" dangerouslySetInnerHTML={{__html: this.htmlDecode(taskDetail.description.substring(0, 250)) }}></p>
                                                                :
                                                                    <p className="task-description" dangerouslySetInnerHTML={{__html: this.htmlDecode(taskDetail.description) }}></p>
                                                            }
                                                            <a onClick={() => this.toggleDescription(isDescriptionShow)} className="description-readmore" style={{color:"#136796"}}>{ !isDescriptionShow?'...More':<span>&nbsp; Hide</span> }</a>
                                                        </>
                                                    :
                                                    <p className="task-description" dangerouslySetInnerHTML={{__html: this.htmlDecode(taskDetail.description) }}></p>
                                                }
                                            </Col>
                                            
                                            <Col span={12} className="created-profile">
                                                <p className="created-by">Created by</p>
                                                <div className="created-details">
                                                    <Avatar className="mr8" src={taskDetail.assigned_by.user_image}>{taskDetail.assigned_by.p_name}</Avatar>
                                                    <span>
                                                        <p className="name">{taskDetail.assigned_by.name}</p>
                                                        <p className="role">{taskDetail.assigned_by.role_name}</p>
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col span={12} className="due-details">
                                                <span className="due-on">Due on</span>
                                                <span className="due-date-time">{taskDetail.due_on.utcLocal().dateTimeFormat()}</span>
                                            </Col>
                                        </Row>

                                        <Row className="tabs-container">
                                            <Col span={24}>
                                            <Tabs defaultActiveKey={(taskDetail.type == 'file' || taskDetail.type == 'task' || taskDetail.type == 'payment') ? 1: "responeBy"} onChange={this.onTaskTabChange}>
                                                {   taskDetail.type == 'file'
                                                    ?
                                                        <TabPane 
                                                            tab={
                                                                <span>
                                                                    <FileUploadIcon />
                                                                </span>
                                                            } 
                                                            key="1"
                                                        >
                                                            {   taskDetail.showSignProcess && !taskUploadedFilesShow &&
                                                                (
                                                                    <div className="all-view-container">
                                                                        <div className="file-upload-details mb8">
                                                                            <Card className="upload-details">
                                                                                <span className="warning-text">Please note whatever files you upload will immediately be visible to users with access to this Task.</span><br/>
                                                                                <span>Maximum upload file size : 300 MB</span><br/>
                                                                                <b>Allowed file extensions:</b><br/>
                                                                                <b>Images </b><br/>
                                                                                <span>(JPG, GIF, BMP, PNG, SVG, TIFF), </span><br/>
                                                                                <b>Media </b><br/>
                                                                                <span>(MP3, MP4, 3GP, FLV, WMV, AVI, WEBM, OGG) </span><br/>
                                                                                <b>Documents</b><br/>
                                                                                <span>(PDF, DOC, DOCX, CSV, XLS, XLSX, PPT,PPTX, TXT)</span>
                                                                            </Card>
                                                                            <Upload showUploadList={false} beforeUpload={(e, filelist) => this.beforeUpload(e, filelist)} customRequest={this.fileUpload} onClick={this.handleChange} multiple={true}>
                                                                                <Button type="primary" className="browse-file">Browse Files</Button>
                                                                            </Upload>
                                                                        </div>
                                                                        <div>
                                                                            {   uploadList && uploadList.length > 0
                                                                                ? uploadList.map((file, index) => (
                                                                                    <div className="file-list-container mb8" key={index}>
                                                                                        <div className="file-list-content">
                                                                                            <div className="file-list-details">
                                                                                                <div className="file-list">
                                                                                                    { (file.fType == 'png' || file.fType == 'jpg' || file.fType == 'jpeg' || file.fType == 'gif' || file.fType == 'bmp' || file.fType == 'svg') &&
                                                                                                        (
                                                                                                            <FileImageOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.fType == 'xls' || file.fType == 'xlsx' || file.fType == 'csv') &&
                                                                                                        (
                                                                                                            <FileExcelOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.fType == 'doc' || file.fType == 'docx') &&
                                                                                                        (
                                                                                                            <FileWordOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.fType == 'ppt' || file.fType == 'pptx') &&
                                                                                                        (
                                                                                                            <FilePptOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.fType == 'flv' || file.fType == 'wmv' || file.fType == 'avi' || file.fType == 'mp4' || file.fType == '3gp' || file.fType == 'webm') &&
                                                                                                        (
                                                                                                            <CameraOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   file.fType == 'pdf' &&
                                                                                                        (
                                                                                                            <PdfIcon style={{fontSize: 16, marginRight: "8px"}} />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.fType == 'tiff' || file.fType == 'txt') &&
                                                                                                        (
                                                                                                            <FileTextOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.fType == 'mp3' || file.fType == 'ogg') &&
                                                                                                        (
                                                                                                            <AudioOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    <span>{file.file.name}</span>
                                                                                                    {/* <span className="percentage">{file.file.percent}%</span> */}
                                                                                                </div>
                                                                                                <Progress showInfo={true} percent={file.file.percent} />
                                                                                            </div>
                                                                                            {/* <DeleteIcon className="delete-icon"/> */}
                                                                                        </div>
                                                                                        {file.error && (<span className="file-corrupted">File corrupted</span>)}
                                                                                    </div>
                                                                                ))
                                                                                :<></>
                                                                            }

                                                                            {   filesList && filesList.length > 0
                                                                                ? filesList.map((file, index) => (
                                                                                    <div className="file-list-container mb8" key={index}>
                                                                                        <div className="file-list-content">
                                                                                            <div className="file-list-details">
                                                                                                <div className="file-list" onClick={() => this.viewFile(file.name, file.f_id)}>
                                                                                                    {   (file.type == 'png' || file.type == 'jpg' || file.type == 'jpeg' || file.type == 'gif' || file.type == 'bmp' || file.type == 'svg') &&
                                                                                                        (
                                                                                                            <FileImageOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.type == 'xls' || file.type == 'xlsx' || file.type == 'csv') &&
                                                                                                        (
                                                                                                            <FileExcelOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.type == 'doc' || file.type == 'docx') &&
                                                                                                        (
                                                                                                            <FileWordOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.type == 'ppt' || file.type == 'pptx') &&
                                                                                                        (
                                                                                                            <FilePptOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.type == 'flv' || file.type == 'wmv' || file.type == 'avi' || file.type == 'mp4' || file.type == '3gp' || file.type == 'webm') &&
                                                                                                        (
                                                                                                            <CameraOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   file.type == 'pdf' &&
                                                                                                        (
                                                                                                            <PdfIcon style={{fontSize: 16, marginRight: "8px"}} />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.type == 'tiff' || file.type == 'txt') &&
                                                                                                        (
                                                                                                            <FileTextOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    {   (file.type == 'mp3' || file.type == 'ogg') &&
                                                                                                        (
                                                                                                            <AudioOutlined className="mr8" />
                                                                                                        )
                                                                                                    }
                                                                                                    <span>{file.name}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <DeleteIcon className="delete-icon" onClick={() => this.deleteFile(file.f_id,index, 'db_files')}/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                                :<></>
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            {   !taskDetail.showSignProcess && !taskUploadedFilesShow &&
                                                                (
                                                                    <div className="empty-task">
                                                                        <WaitingIcon />
                                                                        <span>Awaiting response</span>
                                                                    </div>
                                                                )
                                                            }
                                                            
                                                            {   taskDetail.type == 'file'  && taskUploadedFilesShow && filesList && filesList.length > 0
                                                                ? (
                                                                    <Row className="task-grid-view"> {filesList.map((file, index) => (
                                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="task-view" key={"file_view_"+index}>
                                                                            <Card extra={<a href={ APIURL + "file/downloadFile?f_id=" + file.f_id + "&token=" + this.user.token} download><DownloadIcon /></a>}>
                                                                                <div className="file-image">
                                                                                {   (file.type == 'png' || file.type == 'jpg' || file.type == 'jpeg' || file.type == 'gif' || file.type == 'bmp' || file.type == 'svg') &&
                                                                                    (
                                                                                        <Image className="img" src={file.url} />
                                                                                    )
                                                                                }
                                                                                {   (file.type == 'xls' || file.type == 'xlsx' || file.type == 'csv') &&
                                                                                    (
                                                                                        <Image className="img" src={ExcelPng} preview={{ visible: false }} onClick={() => this.viewFile(file.name, file.f_id)} />
                                                                                    )
                                                                                }
                                                                                {   (file.type == 'doc' || file.type == 'docx') &&
                                                                                    (
                                                                                        <Image className="img" src={WordPng} preview={{ visible: false }} onClick={() => this.viewFile(file.name, file.f_id)} />
                                                                                    )
                                                                                }
                                                                                {   (file.type == 'ppt' || file.type == 'pptx') &&
                                                                                    (
                                                                                        <Image className="img" src={PptPng} preview={{ visible: false }} onClick={() => this.viewFile(file.name, file.f_id)} />
                                                                                    )
                                                                                }
                                                                                {   (file.type == 'flv' || file.type == 'wmv' || file.type == 'avi') &&
                                                                                    (
                                                                                        <Image className="img" src={VideoPng} preview={{ visible: false }} onClick={() => this.viewFile(file.name, file.f_id)} />
                                                                                    )
                                                                                }
                                                                                {   file.type == 'pdf' &&
                                                                                    (
                                                                                        <Image className="img" src={PdfPng} preview={{ visible: false }} onClick={() => this.viewFile(file.name, file.f_id)} />
                                                                                    )
                                                                                }
                                                                                {   file.type == 'txt' &&
                                                                                    (
                                                                                        <Image className="img" src={TextPng} preview={{ visible: false }} onClick={() => this.viewFile(file.name, file.f_id)} />
                                                                                    )
                                                                                }
                                                                                {   file.type == 'tiff' &&
                                                                                    (
                                                                                        <Image className="img" src={ImagePng} preview={{ visible: false }} onClick={() => this.viewFile(file.name, file.f_id)} />
                                                                                    )
                                                                                }
                                                                                {   (file.type == 'mp3' || file.type == 'ogg') &&
                                                                                    (
                                                                                        <Image className="img" src={AudioPng} preview={{ visible: false }} onClick={() => this.viewFile(file.name, file.f_id)} />
                                                                                    )
                                                                                }
                                                                                {   (file.type == 'mp4' || file.type == '3gp' || file.type == 'webm') &&
                                                                                    (
                                                                                        <Image className="img" src={VideoViewPng} preview={{ visible: false }} onClick={() => this.viewFile(file.name, file.f_id)} />
                                                                                    )
                                                                                }
                                                                                </div>
                                                                                <p className="file-title w100">
                                                                                    <span className="file-name" style={{ width: '100% !important'}}>{ file.name }</span>
                                                                                    {/* <PdfIcon className="file-type-img"/>                     */}
                                                                                </p>
                                                                            </Card>
                                                                        </Col>                                                                    
                                                                ))}</Row>)                                                                
                                                                :<></>
                                                            }
                                                            
                                                            <Drawer
                                                                title={uploadFileName}
                                                                className="task-file-view-drawer"
                                                                placement="top"
                                                                height={"100%"}
                                                                onClose={() => this.onShowUploadFileDrawer(showFileDrawer)}
                                                                visible={showFileDrawer}
                                                                extra={<a href={ APIURL + "file/downloadFile?f_id=" + uploadFileId + "&token=" + this.user.token} download><DownloadIcon /></a>}
                                                            >
                                                                {showUploadPdf && (<PDFView pdfData={uploadFileData} isUrl={false} />)}
                                                                {showUploadTxt && (<Card>{uploadFileData}</Card>)}
                                                                {showUploadAudio && (<div className="react-audio-player-container"><ReactAudioPlayer src={uploadFileData} autoPlay controls /></div>)}
                                                                {showUploadVideo && <ReactPlayer playing url={uploadFileData} controls={true} width="100%" height="100%" /> }
                                                                {showUploadNoPreview && (<div className="no-preview-container"><Card><span>No Preview Available</span><p>You can Download copy of the file</p></Card></div>)}
                                                            </Drawer>
                                                        </TabPane>
                                                    :   taskDetail.type == 'task'
                                                    ?
                                                        <TabPane 
                                                            tab={
                                                                <span>
                                                                    <SignTaskIcon />
                                                                </span>
                                                            } 
                                                            key="1"
                                                        >
                                                            <div className="all-view-container">
                                                                <Card className="sign-doc-details">
                                                                    <Row style={{ width: '100%'}} justify="space-between" align="middle">
                                                                        <Col span={18}>
                                                                            <PdfIcon />
                                                                            <span className="doc-name">{taskDetail.files[0].name}</span>
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <a href={ APIURL + "file/downloadFile?f_id=" + taskDetail.files_id + "&token=" + this.user.token} download><DownloadIcon /></a>
                                                                            <EyeIcon onClick={() => this.onShowPDF(showPdf)} />
                                                                        </Col>
                                                                    </Row>
                                                                </Card>
                                                            </div>
                                                            
                                                            {   taskDetail.showSignProcess &&
                                                                (
                                                                    <div className="document-sign">
                                                                    {/* <CloseIcon /> */}
                                                                    <Checkbox id="sign-document-aggreed" checked={isAggreed} onChange={() => this.handleIsAggreedConfirm(isAggreed)}>I understand that digitally signing this document my intention to be bound by its contents.</Checkbox>
                                                                    <p>Please enter your account password to digitally sign this document. Your name, date and timezone will be recorded on the footer of the document as evidence of your signature.</p>
                                                                    <Input.Password id="sign-password" placeholder="Enter your password" value={signPassword} onChange={(e) => this.signPasswordChange(e)} maxLength={32} />
                                                                    <span className="forget-pswd"  onClick={() => this.forgotPassword()}>Forgot password?</span>
                                                                </div>
                                                                )
                                                            }

                                                            {   showPdf &&
                                                                (
                                                                    <Drawer
                                                                        title={taskDetail.files[0].name}
                                                                        className="custom-drawer-popup task-pdf-view-drawer"
                                                                        placement="top"
                                                                        height={"100%"}
                                                                        onClose={() => this.onShowPDF(showPdf)}
                                                                        visible={true}
                                                                    >
                                                                        <PDFView pdfData={pdfData} isUrl={false} />
                                                                    </Drawer>
                                                                )
                                                            }

                                                            {   !taskDetail.showSignProcess && !taskUploadedFilesShow &&
                                                                (
                                                                    <div className="empty-payment-task">
                                                                        <WaitingIcon />
                                                                        <span>Awaiting response</span>
                                                                    </div>
                                                                )
                                                            }
                                                        </TabPane>
                                                    :    taskDetail.type == 'payment'
                                                    ?
                                                        <TabPane 
                                                            tab={
                                                                <span>
                                                                    <PaymentTaskIcon />
                                                                </span>
                                                            } 
                                                            key="1"
                                                        >
                                                            <div className="all-view-container">
                                                                {   taskDetail && taskDetail.type == 'payment' && (this.user.role_label != 'Head Admin' && ((taskDetail.transactionList.length == 0 && taskDetail.created_by == this.user.id && taskDetail.assignee_status.permissions == 'Can view all responses') || (taskDetail.assignee_status.permissions == 'Must complete' && taskDetail.assignee_status.status == 'Pending'))) &&
                                                                    (
                                                                        <Card className="task-payment-card">
                                                                            <Row>
                                                                                <Col span={24} className="task-payment-head"><p>Amount need to pay</p></Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col span={24} className="task-payment-amount"><p>{ this.siteSetting?.currency.symbol } { taskDetail.assignee_status.amount }</p></Col>
                                                                            </Row>
                                                                            {   !taskDetail.showSignProcess && !taskUploadedFilesShow &&
                                                                                (
                                                                                    <div className="empty-payment-task">
                                                                                        <WaitingIcon />
                                                                                        <span>Awaiting response</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {   taskDetail && (taskDetail.assignee_status.status == 'Pending' && taskDetail.assignee_status.permissions == 'Must complete') &&
                                                                                (
                                                                                    <>
                                                                                        <Row>
                                                                                            <Col span={24}><Button type="primary" id="task-payment-paynow" className="cancel" onClick={() => this.payNow()}>Pay Now</Button></Col>
                                                                                        </Row>
                                                                                        <Row>
                                                                                            <Col span={24}><Image className="img" src={PaymentCardPng} preview={{ visible: false }} /></Col>
                                                                                        </Row>
                                                                                    </>
                                                                                )
                                                                            }
                                                                            
                                                                        </Card>
                                                                    )
                                                                }
                                                                {   taskDetail && taskDetail.type == 'payment' && this.user.role_label != 'Head Admin' &&
                                                                    (
                                                                        <>
                                                                            <Row className="task-grid-view">
                                                                                {   (taskDetail.assignee_status.status == 'Completed') &&
                                                                                    (
                                                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="task-view">
                                                                                            <Card extra={<a href={ APIURL + "payment/downloadBurtonReceipt?case_id=" + this.caseId + "&tk_id= " + taskDetail.assignee_status.tk_tk_id + "&requested_by=" + taskDetail.assignee_status.u_id + "&token=" + this.user.token } download><DownloadIcon /></a>}>
                                                                                                <div className="file-image">
                                                                                                    <Image className="img" src={PdfPng} preview={{ visible: false }} onClick={() => this.viewReceipt(taskDetail.assignee_status.u_id)} />
                                                                                                </div>
                                                                                                <p className="file-title">
                                                                                                    <span className="file-name">{ taskDetail.assignee_status.fileName ? taskDetail.assignee_status.fileName: "Payment Receipt.pdf" }</span>
                                                                                                </p>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    )
                                                                                }
                                                                            </Row>
                                                                            <Row className="task-grid-view">
                                                                            {   taskDetail && taskDetail.transactionList && taskDetail.created_by == this.user.id && (!taskDetail.assignee_status || taskDetail.assignee_status.status == 'Completed' || taskDetail.assignee_status.permissions == 'Can view all responses') && 
                                                                                (
                                                                                    taskDetail.transactionList.map((transaction, index) => (
                                                                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="task-view" key={"pay_doc_"+index}>
                                                                                            <Card extra={<a href={ APIURL + "payment/downloadBurtonReceipt?case_id=" + this.caseId + "&tk_id= " + transaction.tk_tk_id + "&requested_by=" + transaction.u_id + "&token=" + this.user.token } download><DownloadIcon /></a>}>
                                                                                                <div className="file-image">
                                                                                                    <Image className="img" src={PdfPng} preview={{ visible: false }} onClick={() => this.viewReceipt(transaction.u_id)} />
                                                                                                </div>
                                                                                                <p className="file-title" onClick={() => this.viewReceipt(transaction.u_id)}>
                                                                                                    <span className="file-name">{ transaction.fileName ? transaction.fileName: "Payment Receipt.pdf" }</span>
                                                                                                </p>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    ))
                                                                                )
                                                                            }
                                                                            </Row>
                                                                        </>
                                                                    )
                                                                }
                                                                    
                                                            </div>
                                                            <Drawer
                                                                title={paymentViewFileName}
                                                                className="task-file-view-drawer"
                                                                placement="top"
                                                                height={"100%"}
                                                                onClose={() => this.onShowPaymentViewFileDrawer(showPaymentDrawer)}
                                                                visible={showPaymentDrawer}
                                                                extra={!loading && taskDetail && showPaymentPreview && (<a href={ APIURL + "payment/downloadBurtonReceipt?case_id=" + this.caseId + "&tk_id= " + taskDetail.task_id + "&requested_by=" + receiptUId + "&token=" + this.user.token } download><DownloadIcon /></a>)}
                                                            >
                                                                <PDFView pdfData={paymentViewFileData} isUrl={false} />
                                                            </Drawer>
                                                        </TabPane>
                                                    :
                                                        <></>
                                                }
                                                
                                                <TabPane  
                                                tab={
                                                    <span>
                                                        <ResponsesByIcon />
                                                    </span>
                                                } key="responeBy">
                                                    <div>
                                                        <Row>
                                                            <Col span={24} className="task-view-tab-heading">
                                                                <p className="task-view-tab-title">Responses By</p>
                                                            </Col>
                                                            {   mustComplete && mustComplete.length > 0
                                                                ? mustComplete.map((res, index) => (
                                                                    <div className={viewResponseByUser == res.u_id && res.status == 'Completed' ? "user-details selected-bg": "user-details"} key={index} onClick={() => res.status == 'Completed' && this.viewResponseBy(res.u_id)}>
                                                                        <Avatar className="mr16" src={res.user_profile.user_image}>{res.user_profile.p_name}</Avatar>
                                                                        <span>
                                                                            <p className="name">{res.user_profile.name}</p>
                                                                            <p className="role">{ res.role_name }</p>
                                                                            {res.status != 'Completed' && (<p className="status text-orange">{res.status}</p>)}
                                                                            {res.status == 'Completed' && (<p className="status">Completed on: {res.updated_at.utcLocal().dateTimeFormat()}</p>)}
                                                                        </span>
                                                                    </div>
                                                                ))
                                                                :
                                                                    <></>
                                                            }
                                                            {/* {   taskDetail.type == 'file' && (
                                                                <div className="response-download">
                                                                    <p className="view-res">View Response</p>
                                                                    <p>Download</p>
                                                                </div>
                                                            )} */}
                                                        </Row>
                                                    </div>
                                                </TabPane>
                                                <TabPane  
                                                tab={
                                                    <span>
                                                        <InformationIcon />
                                                    </span>
                                                } key="3">
                                                    <div>
                                                        <Row>
                                                            <Col span={24} className="task-view-tab-heading">
                                                                <p className="task-view-tab-title">Task Information</p>
                                                            </Col>
                                                            <Row className="can-view">
                                                                <Col span={6}>
                                                                    <p>Can View</p>
                                                                </Col>
                                                                <Col span={18}>
                                                                    <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                        {   canView && canView.length > 0
                                                                            ? canView.map((view_party, index) => (
                                                                                <Avatar onClick={() => this.showUserView(canView, 'can_view')} src={view_party.user_profile.user_image} key={index}>{view_party.user_profile.p_name}</Avatar>
                                                                            ))
                                                                            :
                                                                                <></>
                                                                        }
                                                                    </Avatar.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row className="can-view">
                                                                <Col span={6}>
                                                                    <p>Assigned To</p>
                                                                </Col>
                                                                <Col span={18}>
                                                                    <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                                        {   mustComplete && mustComplete.length > 0
                                                                            ? mustComplete.map((view_party, index) => (
                                                                                <Avatar onClick={() => this.showUserView(mustComplete, 'assigned_to')} src={view_party.user_profile.user_image} key={index}>{view_party.user_profile.p_name}</Avatar>
                                                                            ))
                                                                            :
                                                                                <></>
                                                                        }
                                                                    </Avatar.Group>
                                                                </Col>
                                                            </Row>
                                                            <div className="sign-status">
                                                                <p><span>Task Type:</span> {taskDetail.task_type}</p>
                                                                <p><span>Overall Status:</span> {taskDetail.task_status}</p>
                                                                <p><span>Created on:</span> {taskDetail.created.utcLocal().dateTimeFormat()}</p>
                                                                {/* <p><span>Modified on:</span> Sam Jones (Claimant)</p> */}
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </TabPane>
                                                <TabPane  
                                                tab={
                                                    <span>
                                                        <CommentIcon />
                                                    </span>
                                                } key="comment">
                                                    <Col span={24} className="task-view-tab-heading">
                                                        <p className="task-view-tab-title">Comments</p>
                                                    </Col>
                                                    <div className="comment-details">
                                                        <Row>
                                                            <Col span={21}>
                                                                <TextArea name="task-comment" id="task-comment" autoSize placeholder="Add your comments" onChange={this.taskCommentChange} maxLength={1000} value={taskComment} />
                                                            </Col>
                                                            <Col span={3} className="task-add-comment">
                                                                <SendMessageIcon onClick={()=> this.taskAddComment() } />
                                                            </Col>
                                                            <Col>
                                                                <p className="info-txt">Comments you post are visible to everyone with access to this Task</p>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    { taskCommentLoading
                                                        ?
                                                            activityLoaderList.map((item, index) => (
                                                                <Card key={index}>
                                                                    <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                                </Card>
                                                            ))
                                                        :
                                                            <>
                                                            {   taskCommentList && taskCommentList.length > 0
                                                                ? taskCommentList.map((comment, index) => (
                                                                    <div className="comment-container" key={index}>
                                                                        <div className="user-comment-details">
                                                                            <Avatar className="mr16" src={comment.user_image}>{comment.p_name}</Avatar>
                                                                            <span>
                                                                                <p className="name">{comment.name}</p>
                                                                                <p className="role">{comment.user_type}</p>
                                                                            </span>
                                                                            {(!this.editList[comment.cmt_id] && comment.cmt_by == this.user.id) && <EditIcon onClick={() => this.showEditComment(comment)} />}
                                                                            {(this.editList[comment.cmt_id] && comment.cmt_by == this.user.id) && <CloseIcon onClick={() => this.closeEditComment(comment)} />}
                                                                        </div>
                                                                        {(!this.editList[comment.cmt_id]) && <p className="comment">{comment.comment}</p>}
                                                                        {   (this.editList[comment.cmt_id]) &&
                                                                            (
                                                                                <Row className="mb4">
                                                                                    <Col span={21}>
                                                                                        <TextArea name="task-edit-comment" id="task-edit-comment" rows={3} onChange={this.taskEditCommentChange} maxLength={1000} value={editComment} />
                                                                                    </Col>
                                                                                    <Col span={3} className="task-edit-comment">
                                                                                        <SendMessageIcon className={editComment.length == 0 && "disabled-opacity"} onClick={()=> this.taskUpdateComment(comment.f_id) } />
                                                                                    </Col>
                                                                                </Row>
                                                                            )
                                                                        }
                                                                        <p className="comment-date-time">{comment.created_at.utcLocal().dateTimeFormat()}</p>
                                                                    </div>
                                                                ))
                                                                :
                                                                    <></>
                                                            }
                                                            </>
                                                    }

                                                </TabPane>
                                                <TabPane  
                                                tab={
                                                    <span>
                                                        <ActivityIcon />
                                                    </span>
                                                } key="activity">
                                                    { taskActivityLoading
                                                        ?
                                                            activityLoaderList.map((item, index) => (
                                                                <Card key={index}>
                                                                    <Skeleton active title={false} paragraph={{rows:2}} className="p8" />
                                                                </Card>
                                                            ))
                                                        :
                                                            <>
                                                            {   taskActivityList && taskActivityList.length > 0
                                                                ? taskActivityList.map((act, index) => (
                                                                    <div className="activity-details" key={index}>
                                                                        <Avatar size={24} className="mr16" src={act.user_profile.user_image}>{act.user_profile.p_name}</Avatar>
                                                                        <span style={{ width: '100%' }}>
                                                                            <p className="content">{ act.user_profile.name } ({ act.user_role }) { act.description }</p>
                                                                            <p className="content">{act.created_at.utcLocal().dateTimeFormat()}</p>
                                                                        </span>
                                                                    </div>
                                                                ))
                                                                :
                                                                    <></>
                                                            }
                                                            </>
                                                    }
                                                </TabPane>
                                            </Tabs>
                                            <Row className="all-view-footer">
                                                <Col span={12}>
                                                    <Button type="primary" id="task-cancel-close" className="cancel" onClick={() => this.onClose()}>{taskDetail.showSignProcess ? 'Cancel' : 'Close'}</Button>
                                                </Col>

                                                { taskDetail.type == 'file' && taskDetail.showSignProcess &&
                                                    (<Col span={12}>
                                                        <Button type="primary" id="task-markas-complete" className="complete" disabled={!taskDetail.assignee_status || !(filesList.length > 0 || this.checkUploadedFiles()) || this.uploadInprogress()} onClick={() => this.setCompleteTask(taskDetail.assignee_status)}>Mark as Complete</Button>
                                                    </Col>)
                                                }
                                                { taskDetail.type == 'task' && taskDetail.showSignProcess &&
                                                    (<Col span={12}>
                                                        <Button type="primary" id="task-sign-document" className="complete" disabled={signPassword == '' || !isAggreed} onClick={() => this.updateTaskSign()}>Sign Document</Button>
                                                    </Col>)
                                                }
                                                { taskDetail.type == 'general' && taskDetail.showSignProcess &&
                                                    (<Col span={12}>
                                                        <Button type="primary" id="task-markas-complete" className="complete" disabled={!taskDetail.assignee_status} onClick={() => this.setCompleteTask(taskDetail.assignee_status)}>Mark as Complete</Button>
                                                    </Col>)
                                                }
                                            </Row>
                                            </Col>
                                        </Row>
                                    </>
                                :
                                    <></>
                            }                            
                        </Spin>
                </Drawer>

                <Modal 
                    title={userViewType == 'assigned_to' ? <span>Assigned To</span>: (userViewType == 'can_view' ? <span>Can View</span> : <></>)}
                    visible={isUserView}  
                    onCancel={this.closeUserView}
                    className="custom-modal-popup user-view-details"
                    footer={null}>
                        {   userViewParam && userViewParam.length > 0
                                ? userViewParam.map((view_party, index) => (
                                <div className="popover-member-detail" key={index}>
                                    <Avatar src={view_party.user_profile.user_image}>{view_party.user_profile.p_name}</Avatar>
                                    <div className="name-role">
                                        <p className="name">{view_party.user_profile.name}</p>
                                        <p className="role">{view_party.role_name}</p>
                                    </div>
                                </div>
                            ))
                            :
                                <></>
                        }
                    
                </Modal>
            </div>
        );
    }
}
export default TaskView;