import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Card, Drawer, Skeleton } from 'antd';
import { useParams, useHistory } from "react-router-dom";
import userService from "../../logics/services/user.service";
import apiService from "../../logics/services/api.service";
import { encrypt } from '../../logics/helpers';
import { websocketService, socket } from "../../logics/services/websocket.service";
import { useDispatch, useSelector } from "react-redux";
import { EyeIcon, EyeInvisibleIcon } from '../../logics/images'
import rdologo from '../../assets/rdo-logo.png';
import * as moment from "moment";
import { decodeToken } from "react-jwt";

function SetupAccount() {
    const settings = useSelector(state => state.settings);
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [termsConditions, setTermsConditions] = useState(false);
    const [isVisiblePrivacy, setPrivacyVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [form] = Form.useForm();
    let { token } = useParams();

    useEffect(() => {
        apiService.inviteeToken(token)
            .then(res => {
                if (res.status === 'success') {
                    form.setFieldsValue({
                        email: res.data.email,
                        fname: res.data.first_name,
                        lname: res.data.last_name,
                        createpassword: '',
                        confirmpassword: ''
                    })
                } else if (res.status === 'error') {
                    message.error(res.message);
                    setTimeout(() => {
                        history.push('/auth/login');
                    }, 500);
                }
            });
    },[]);

    const checkPassValid = (type, value) => {
        if (value) {
            switch (type) {
                case 0:
                    return value.match(".*[a-z].*");
                    break;
                case 1:
                    return value.match(".*[A-Z].*");
                    break;
                case 2:
                    return value.match(".*[0-9].*");
                    break;
                case 3:
                    return value.match(".*[$@$!*#?&].*");
                    break;
                default:
                    break;
            }
        } else {
            return false;
        }
    }

    const changePassword = (event) => {
        setPassword(event.target.value);
    }

    const onFinish = (data) => {        
        let errs = fvalid(data);
        if (errs.length > 0) {
            message.error(errs);
            return;
        }
        setLoading(true);
        let password = encrypt(data.createpassword).toString();
        let confirmPassword = encrypt(data.confirmpassword).toString();
        apiService.setInviteePassword({
            first_name: data.fname,
            last_name: data.lname,
            email: data.email,
            password: password,
            password_confirmation: confirmPassword,
            token: token,
            timezone: moment?.tz.guess()
        })
        .then(res => {
            if (res.status === 'success') {
                message.success(res.message);
                userService.setUserToken(res.data.token);
                dispatch({type: 'user', value: decodeToken(res.data.token)});
                if (res.data.casecount == 1 && res.data.lastestCaseId) {
                    history.push('/app/case/'+(res.data.lastestCaseId)+'/overview');
                } else {
                    history.push('/app/allcases');
                }
                if(res.inviteData) {
                    websocketService.send({
                        method: 'case',
                        isDisplay: true,
                        data: {
                            users: res.inviteData.users,
                            content: res.inviteData.content,
                            type: 'accept-case',
                            case_id: res.inviteData.case_id,
                            case_name: res.inviteData.case_name,                                
                        }
                    });
                }
            } else if (res.status === 'error') {
                setLoading(false);
                form.setFieldsValue({
                    createpassword: null,
                    confirmpassword: null
                })
                message.error(res.message);
            }
        }, () => {
            setLoading(false);
            form.setFieldsValue({
                createpassword: null,
                confirmpassword: null
            })
        });
    };

    const showTermsCondition = () => (
        setTermsConditions(true)
    )

    const showPrivacy = () => {
        setPrivacyVisible(true);
    }

    const onClose = () => {
        setTermsConditions(false)
    }

    const closePrivacy = () => {
        setPrivacyVisible(false)
    }

    const fvalid = (fieldsValue) => {
        let err = [];
        let nameReg = new RegExp(/^[a-zA-Z0-9#/, \-\']+$/);
        if (!fieldsValue['fname'] || !nameReg.test(fieldsValue['fname'])) {
            err.push('Please enter valid first name');
        } else if (!fieldsValue['lname'] || !nameReg.test(fieldsValue['lname'])) {
            err.push('Please enter valid last name');
        } else if (!fieldsValue['createpassword']) {
            err.push('Please enter create password');
        } else if (!fieldsValue['confirmpassword']) {
            err.push('Please enter confirm password');
        } else if(!checkPassValid(0,fieldsValue['createpassword']) || !checkPassValid(1,fieldsValue['createpassword']) || !checkPassValid(2,fieldsValue['createpassword']) || !checkPassValid(3,fieldsValue['createpassword']) ) {
            err.push('Password must contains 1 Lower case, 1 Upper case, 1 Number, 1 Special character from @$!*#?&');
        } else if (fieldsValue['createpassword'] != fieldsValue['confirmpassword']) {
            err.push('Passwords are not matching');
        } else if (fieldsValue['createpassword'].length < 8) {
            err.push('Passwords must contain 8 characters minimum');
        }
        return err;
    }
   
    return (
        <Row className="max-480 p16 auth-container">
            <Col className="textCenter mb24 mt40 mb40" span={24}>
                { settings && <img className="portal-logo" src={settings?.logo} alt="logo" /> }
                { !settings && <Skeleton style={{width: 100, margin: '0 auto'}} active paragraph={false} /> }
            </Col>
            <Col span={24}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <h3 className="mb8">Setup  your account</h3>
                    <Form.Item 
                        label="Email" 
                        name='email' 
                        className="w100 mb16"
                    >
                        <Input placeholder="Enter email" disabled/>
                    </Form.Item>
                    <Form.Item 
                        label="First Name" 
                        name='fname' 
                        className="w100 mb16"
                        onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9#/, \-\']/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    >
                        <Input name="fname" placeholder="Enter first name" maxLength={32} />
                    </Form.Item>
                    <Form.Item 
                        label="Last Name" 
                        name='lname' 
                        className="w100 mb16"
                        onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9#/, \-\']/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                    >
                        <Input name="lname" placeholder="Enter last name" maxLength={32} />
                    </Form.Item>
                    <Form.Item 
                        label="Create Password" 
                        name='createpassword' 
                        className="w100 mb16"
                    >
                        <Input.Password placeholder="Enter password" name="createpassword" onChange={(event) => changePassword(event)} maxLength={32}
                            iconRender={visible => (visible ? <EyeIcon /> : <EyeInvisibleIcon />)} 
                        />
                    </Form.Item>
                    { password &&
                    (<ul className="pass-check-list">
                        <li className={checkPassValid(0, password)?'green':'red'}> 1 Lower case.</li>
                        <li className={checkPassValid(1, password)?'green':'red'}> 1 Upper case.</li>
                        <li className={checkPassValid(2, password)?'green':'red'}> 1 Number.</li>
                        <li className={checkPassValid(3, password)?'green':'red'}> 1 Special character from @$!*#?&.</li>
                        <li className={password && password.length >= 8?'green':'red'}> 8 Characters minimum.</li>  
                    </ul>) 
                    }
                    <Form.Item 
                        label="Confirm Password" 
                        name='confirmpassword' 
                        className="w100 mb16"
                    >
                        <Input.Password placeholder="Enter password" name="confirmpassword" maxLength={32}
                            iconRender={visible => (visible ? <EyeIcon /> : <EyeInvisibleIcon />)} 
                        />
                    </Form.Item>
                    <Button className="w100 br8 mb16" loading={loading} disabled={loading} type="primary" htmlType="submit">Submit</Button>
                </Form>
            </Col>
            <Col span={24} className="terms-policy mb24">
                <p>By signing in you confirm that you’ve read and accepted our <span className="terms-conditions" onClick={showTermsCondition}>Terms and Conditions</span> and <span className="terms-conditions" onClick={showPrivacy}>Privacy Policy</span>.</p>
            </Col>
            <Col span={24} className="powered-by">
                <p>Powered by <img src={rdologo} width="33" alt="" /></p>
            </Col>
            <Drawer
                    title="Terms and Conditions"
                    className="custom-drawer-popup"
                    placement="bottom"
                    height={"100%"}
                    visible={termsConditions}
                    onClose={onClose}
                >
                    <Card className="terms-condition-content"><div dangerouslySetInnerHTML={{ __html: settings?.terms }}></div></Card>
                </Drawer>
                <Drawer
                    title="Privacy Policy"
                    className="custom-drawer-popup"
                    placement="bottom"
                    height={"100%"}
                    visible={isVisiblePrivacy}
                    onClose={closePrivacy}
                >
                    <Card className="terms-condition-content"><div dangerouslySetInnerHTML={{ __html: settings?.privacy }}></div></Card>
                </Drawer>
        </Row>
    );
}
export default SetupAccount;