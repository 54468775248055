import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route, 
    Redirect
} from "react-router-dom";
import Auth from './pages/auth/Auth';
import MainApp from './pages/app/MainApp';
import { useSelector, useDispatch } from "react-redux";
import apiService from "./logics/services/api.service";
import { Notifications } from 'react-push-notification';

function App() {
    const dispatch = useDispatch();
    try {
        window.addEventListener('storage', (event) => {
            if (event.key === 'accessToken') {
                if (!event.newValue && location.pathname != '/auth/login') {
                    window.location.href = `/auth/login`;
                } else if (event.newValue && event.newValue != event.oldValue && location.pathname == '/auth/login') {
                    window.location.href = `/app/allcases`;
                }
            }
        });
    } catch (e) {

    }
    useEffect(() => {
        apiService.getSiteSettings().then((res) => {
            if (res.status === 'success') {
                dispatch({ type: 'settings', value: res.data});
            }
        });
        apiService.getRolesName()
            .then((res) => {
            if (res.status === 'success') {
                dispatch({ type: 'roles', value: res.data});
            }
        });
    }, []);
    
    return (
        <Router>
            <Notifications />
            <Switch>
                <Route path="/auth">
                    <Auth />
                </Route>
                <Route path="/app">
                    <MainApp />
                </Route>
                <Redirect from="*" to="/auth/intro" />
            </Switch>
        </Router>
    );
}

export default App;
