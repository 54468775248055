import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Drawer, Card, Skeleton } from 'antd';
import { Link, useHistory, useLocation } from "react-router-dom";
import rdologo from '../../assets/rdo-logo.png';
import { encrypt } from '../../logics/helpers';
import userService from "../../logics/services/user.service";
import apiService from "../../logics/services/api.service";
import { useSelector, useDispatch } from "react-redux";
import { decodeToken } from "react-jwt";
import { EyeIcon, EyeInvisibleIcon } from '../../logics/images'
import store from "../../logics/store/case.store";
import * as moment from 'moment';

function Login() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const user = useSelector(state => state.user);
    const history = useHistory();
    let location = useLocation();
    const [loading, setLoading] = useState(false);
    const [termsConditions, setTermsConditions] = useState(false);
    const [isVisiblePrivacy, setPrivacyVisible] = useState(false);
    const [form] = Form.useForm();
    // const user = store.getState().user;//userService.getUser();
    useEffect(() => {
        if (userService.isLoggedIn()) {
            let u = userService.getUser();
            if (location.state?.from.pathname) {
                history.push(location.state?.from.pathname);
            } else if (u.setup_status === 0) {
                let url = `/app/welcome`;
                history.push(url);
            } else if (u.casecount === 1 && u.lastestCaseId) {
                let url = `/app/case/${user.lastestCaseId}/overview`;
                history.push(url);
            } else {
                let url = `/app/allcases`;
                history.push(url);
            }
        }
    }, [])
    const onSubmit = (fieldsValue) => {
        setLoading(true);
        apiService.logIn({
            email: fieldsValue['email'],
            password: encrypt(fieldsValue['password']).toString(),
            timezone: moment?.tz.guess()
        }).then((res) => {
            setLoading(false);
            if (res.status === 'success') {
                userService.setUserToken(res.data.token);
                const user = decodeToken(res.data.token);
                dispatch({type: 'user', value: user});
                if (location.state?.from.pathname) {
                    history.push(location.state?.from.pathname);
                } else if (user.setup_status === 0) {
                    let url = `/app/welcome`;
                    history.push(url);
                } else if (res.data.casecount === 1 && res.data.lastestCaseId) {
                    let url = `/app/case/${res.data.lastestCaseId}/overview`;
                    history.push(url);
                } else {
                    let url = `/app/allcases`;
                    history.push(url);
                }
            } else {
                message.error(res.message)
            }
        });
    }

    const showTermsCondition = () => (
        setTermsConditions(true)
    )

    const showPrivacy = () => {
        setPrivacyVisible(true);
    }

    const onClose = () => {
        setTermsConditions(false)
    }

    const closePrivacy = () => {
        setPrivacyVisible(false)
    }

    const signup = () => {
        history.push('/auth/signup')
    }

    return (
        <Row className="max-480 p16 auth-container">
            <Col className="textCenter mb24 mt40" span={24}>
                { settings && <img className="portal-logo" src={settings?.logo} alt="logo" /> }
                { !settings && <Skeleton style={{width: 100, margin: '0 auto'}} active paragraph={false} /> }
            </Col>
            <Col className="textCenter mb40" span={24}>
                <p className="f24">Welcome to the {settings?.site_name } Case Resolution Portal</p>
            </Col>
            <Col span={24}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <h3 className="mb24">Log in to your account</h3>
                    <Form.Item>
                        <Link to={`/auth/forgotemail`} className="labelRight">
                            Forgot Email?
                        </Link>
                        <Form.Item label="Email" name='email' className="w100 mb0">
                            <Input placeholder="Username" name="email" maxLength={100} />
                        </Form.Item> 
                    </Form.Item>
                    <Form.Item>
                        <Link to={`/auth/forgotpassword`} className="labelRight">
                            Forgot Password?
                        </Link>
                        <Form.Item label="Password" name='password' className="w100 mb0">
                            <Input.Password placeholder="Password" name="current-password" maxLength={32}
                                iconRender={visible => (visible ? <EyeIcon /> : <EyeInvisibleIcon />)}
                            />
                        </Form.Item> 
                    </Form.Item>
                    <Form.Item>
                        <Button className="w100 br8" loading={loading} type="primary" htmlType="submit">Login</Button>
                    </Form.Item>
                    <Form.Item className="login-terms">
                        By signing in you confirm that you’ve read and accepted our <span className="terms-conditions" onClick={showTermsCondition}>Terms and Conditions</span> and <span className="terms-conditions" onClick={showPrivacy}>Privacy Policy</span>.
                    </Form.Item>
                    {   settings && settings.signup_enabled == 1 && (<div>
                        <h3 className="mb16">Don’t have account ?</h3>
                        <Button className="w100 mb40 custom-btn" type="default" onClick={signup}>Sign up</Button>
                    </div>)}
                </Form>
                <Drawer
                    title="Terms and Conditions"
                    className="custom-drawer-popup"
                    placement="bottom"
                    height={"100%"}
                    visible={termsConditions}
                    onClose={onClose}
                >
                    <Card className="terms-condition-content"><div dangerouslySetInnerHTML={{ __html: settings?.terms }}></div></Card>
                </Drawer>
                <Drawer
                    title="Privacy Policy"
                    className="custom-drawer-popup"
                    placement="bottom"
                    height={"100%"}
                    visible={isVisiblePrivacy}
                    onClose={closePrivacy}
                >
                    <Card className="terms-condition-content"><div dangerouslySetInnerHTML={{ __html: settings?.privacy }}></div></Card>
                </Drawer>
            </Col>
            <Col span={24} className="powered-by">
                <p>Powered by <img src={rdologo} width="33" alt="" /></p>
            </Col>
        </Row>
    );
}
export default Login;