import React, { useState, useEffect } from "react";
import { Alert, Avatar, Button, Col, Form, Input, message, Row, Upload } from "antd";
import { CheckGreenIcon } from "../../logics/images";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import startsWith from 'lodash.startswith';
import apiService from "../../logics/services/api.service";
import userService from "../../logics/services/user.service";
import { decodeToken } from "react-jwt";
import { useHistory } from "react-router-dom";

function Welcome() {
    const history = useHistory();
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const user = useSelector(state => state.user);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();

    const beforeUpload = (file) => {
        return new Promise(resolve => {
            let isImage = { pattern: /[\/.](jpe?g|gif|bmp|png|svg|tiff?)$/i };
            const isvalid = isImage.pattern.test(file.type);
            if (!isvalid) {
                resolve(false);
            }
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isLt10M) {
                resolve(false);
            }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', event => {
                const _loadedImageUrl = event.target?.result;
                const image = document.createElement('img');
                image.src = _loadedImageUrl;      
                image.addEventListener('load', () => {
                    const { width, height } = image        
                    if (width > 512 || height > 512) {
                        message.error('Height and Width must not exceed 512px');
                        resolve(false);
                    }      
                    resolve(true);
                });
            });
        });
    }

    const profileUpload = (file) => {
        const formData = new FormData();
        formData.append('avatar', file.file);
        apiService.uploadProfilePhoto(formData)
            .then((res) => {
                if (res.status == 'success') {
                    userService.setUserToken(res.token);
                    dispatch({type: 'user', value: decodeToken(res.token)});
                } 
            });
    }

    const validate = () => {
        if (form.getFieldValue('phone') || form.getFieldValue('address1') || form.getFieldValue('address2')) {
            return false;
        }
        return true;
    }

    const onSubmit = (fieldsValue) => {
        // console.log({
        //             first_name: user.first_name,
        //             last_name: user.last_name,
        //             org_name: user.org_name,
        //             phone: fieldsValue.phone?fieldsValue.phone:'',
        //             address1: fieldsValue.address1?fieldsValue.address1:'',
        //             address2: fieldsValue.address2?fieldsValue.address2:''
        //         })
        setIsLoading(true);
        apiService.completeSetup({
                first_name: user.first_name,
                last_name: user.last_name,
                org_name: user.org_name,
                phone: fieldsValue.phone?fieldsValue.phone:'',
                address1: fieldsValue.address1?fieldsValue.address1:'',
                address2: fieldsValue.address2?fieldsValue.address2:''
            }).then((res) => {
                setIsLoading(false);
                if (res.status == 'success') {
                    userService.setUserToken(res.token);
                    dispatch({type: 'user', value: decodeToken(res.token)});
                    history.push(`/app/allcases`);
                }
            });
    }

    return (
        <div className="welcome-container">
            <Row>
                <Col span={24}>
                    <Alert type="success" showIcon icon={<CheckGreenIcon />} className="mb24" message="Account created successfully"></Alert>
                </Col>
                <Col className="sec-container" span={24}>
                    <div className="sec-title">
                        <h3>Welcome to { settings?.site_name }</h3>
                        <p className="mb32">Complete your profile details to register your first case</p>
                        <Avatar className="mb16" size={"large"} src={user?.image} >{ user?.p_name }</Avatar>
                        <p className="mb12">Upload your profile picture  - size 512 x 512 (optional)</p>
                        <Upload className="mb24" name="logo" showUploadList={false} beforeUpload={(file) => beforeUpload(file)} customRequest={profileUpload}>
                            <Button className="change" type="primary">
                                Change profile picture
                            </Button>
                        </Upload>
                    </div>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onSubmit}
                        className="my-account"
                    >
                        <Form.Item
                            label={<div>{ user?.org_name?'Representative Mobile number':'Mobile number' } <span style={{fontWeight: 400}}>(Optional)</span></div>}
                            className="w100 mb12"
                            name="phone"
                        >
                            <PhoneInput
                                className="react-tel-input w100"
                                name="phone"
                                country={"us"} 
                                enableSearch
                                value={user?.phone}
                                style={{ width: "-webkit-fill-available" }}
                                isValid={(inputNumber, country, countries) => {
                                    return countries.some((country) => {
                                      return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                                    });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={<div>{ user?.org_name?'Organization Postal Address':'Postal Address' } <span style={{fontWeight: 400}}>(Optional)</span></div>}
                            className="w100 mb16"
                            name="address1"
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z0-9#/, \-\']/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <Input
                                placeholder="Flat, Company, Apartment"
                                name="address1"
                                showCount
                                maxLength={120}
                            />
                        </Form.Item>
                        <Form.Item
                            className="w100 mb48"
                            name="address2"
                            onKeyPress={(event) => {
                                if (!/[a-zA-Z0-9#/, \-\']/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <Input
                                placeholder="Enter Street, Area, Village"
                                name="address2"
                                maxLength={120}
                            />
                        </Form.Item>
                        <div className="p-footer">
                            <Row gutter={16} style={{ margin: 0 }} justify="space-between">
                                <Col span={12}>
                                    <Button
                                        type="default"
                                        size="large"
                                        className="custom-btn w100"
                                        loading={isLoading}
                                        disabled={isLoading}
                                        onClick={() => onSubmit(form.getFieldsValue())}
                                    >
                                        I do it later
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Form.Item shouldUpdate className="mb0">
                                        {() => (
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="w100"
                                            loading={isLoading}
                                            disabled={isLoading || validate()}
                                            onClick={() => form.submit()}
                                        >
                                            Complete
                                        </Button>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}
export default Welcome;