import { Button, Card, Col, Row, Spin, Steps } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon, DeleteIcon, SortDownIcon } from "../../logics/images";
import apiService from "../../logics/services/api.service";
const { Step } = Steps;

function CreateCase() {
    const history = useHistory();
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [draftList, setDraftList] = useState([]);

    useEffect(() => {
        setLoading(true);
        apiService.getDraftCases()
            .then(res => {
                setLoading(false);
                if (res.status == 'success') {
                    setDraftList(res.data);
                    if (res.roles) {
                        setRolesList(res.roles);
                    }
                }
            });
    }, []);

    const navigateCreate = () => {
        history.push('/app/case-create');
    }

    return (<div className={draftList.length > 1?"case-create-container":"case-create-container draft-list-empty"}>
        <Row>
            <Col span={24}>
                <Card className="register-card">
                    <h4>Hi { user?.name }!</h4>
                    <p>Let’s get started by registering your case and inviting the other party.</p>
                    <Row align="bottom" justify="space-around" gutter={24}>
                        {/* <nz-spin [nzSpinning]="loading"></nz-spin> */}
                        <Spin spinning={loading}></Spin>
                        { rolesList.length > 0 && <Col span={24} className="select-role">
                                <p>What will be your role in this case?</p>
                                {/* <nz-select [(ngModel)]="selectedRole" name="selectedRole" nzSize="large" nzPlaceHolder="Select Role">
                                    <nz-option *ngFor="let role of rolesList" [nzLabel]="role.role_name" [nzValue]="role.role_label"></nz-option>
                                </nz-select> */}
                            </Col>
                        }
                        { !loading && <Col span={24}>
                            <Button type="primary" onClick={ navigateCreate }>Create a Case</Button>
                        </Col> }
                    </Row>
                </Card>
            </Col>
            { draftList.length != 0 && <Col span={24} className="draft-lists">
                <div className="draft-header">
                    <Row align="middle" justify="space-between">
                        <Col>
                            <h5>My Drafts</h5>
                            <p>Previous Case Registrations awaiting completion</p>
                        </Col>
                        <Col className="sort-icon">
                            <SortDownIcon />
                        </Col>
                    </Row>
                </div>
                <Row className="draft-container" align="middle">
                    {draftList.map((list, index) => (  <Col span={24}>
                        <Card key={'draft_'+index}  actions={[
                                <Row align="middle" justify="space-between">
                                    <Col>
                                        <Steps className="pl16 pt16 pb16" current={list.case_step} responsive={false} direction="horizontal" id="create-case-steps">
                                            <Step icon={<span>1</span>} />
                                            <Step icon={<span>2</span>} />
                                            <Step icon={<span>3</span>} />
                                            <Step icon={<span>4</span>} />
                                            <Step icon={<span>5</span>} />
                                        </Steps>
                                    </Col>
                                    <Col className="right-action">
                                        <ArrowRightIcon />
                                    </Col>
                                </Row>
                            ]}>
                            <i><DeleteIcon /></i>
                            <p>My Role: { list.role_name }</p>
                            <p>Category: { list.category?list.category:'No data provided' }</p>
                            <p>Sub-Category: { list.sub_category?list.sub_category:'Not applicable' }</p>
                            <p>Description: { list.case_description?list.case_description:'No data provided' }</p>
                            <p className="mb-0">Created on: { list.created_at.utcLocal().dateTimeFormat() }</p>
                        </Card>
                    </Col>
                    ))}
                </Row>
            </Col>}
        </Row>
    </div>)
}
export default CreateCase;