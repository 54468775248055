import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Drawer, Card, Skeleton, Steps, Spin } from 'antd';
import { Link, useHistory, useLocation } from "react-router-dom";
import rdologo from '../../assets/rdo-logo.png';
import { encrypt } from '../../logics/helpers';
import userService from "../../logics/services/user.service";
import apiService from "../../logics/services/api.service";
import { useSelector, useDispatch } from "react-redux";
import { decodeToken } from "react-jwt";
import { CheckCircleIcon, EyeIcon, EyeInvisibleIcon, InfoIcon, SignupIndvIcon, SignupOrgIcon, SignupRepIcon } from '../../logics/images'
import store from "../../logics/store/case.store";
import * as moment from "moment";
const { Step } = Steps;
let inter;

function Signup() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings);
    const history = useHistory();
    const [current, setCurrent] = useState(0)
    const [isShowInfo, setIsShowInfo] = useState(false);
    const [infoContent, setInfoContent] = useState('');
    const [infoTitle, setInfoTitle] = useState('');
    const [selectedType, setSelectedType] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [isOTPSent, setIsOTPSent] = useState(false);
    const [loadingOTPBtn, setLoadingOTPBtn] = useState(false);
    const [oldEmail, setOldEmail] = useState(null);
    const [timer, setTimer] = useState(60);
    const [password, setPassword] = useState('');
    const [loadingSignup, setLoadingSignup] = useState(false);
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    useEffect(() => {
        forceUpdate({});
    }, []);

    if (settings && !settings.signup_enabled) {
        history.push('/auth/login');
    } else if (settings && settings.signup_enabled) {
        setTimeout(() => {
            document.getElementById("signup-steps").classList.remove("ant-steps-vertical");            
        });
    }

    const selectType = (type) => {
        setSelectedType(type);
    }

    const showDrawer = (type) => {
        setIsShowInfo(true);
        if (type === 0) {
            setInfoTitle('Individual');
            setInfoContent('This is for individuals who are in a dispute and not part of an organization such as a company or government.');
        } else if (type === 1) {
            setInfoTitle('Organization');
            setInfoContent('This is for users who are in a dispute and who are part of an organization (such as a company or government).');
        } else if (type === 2) {
            setInfoTitle('Legal Representative');
            setInfoContent('This is for legal or other representatives who are representing an Individual or Organization in a dispute.');
        }
    }

    const closeInfo = () => {
        setIsShowInfo(false);
    }

    const nextSection = () => {
        setCurrent(1);
        if (selectedType != 1 && form && form.getFieldValue('OrgName')) {
            form.resetFields(['OrgName']);
        }
    }

    const backSection = () => {
        setCurrent(0);
    }

    const sendOTP = () => {
        // console.log(form.getFieldsValue())
        setLoadingOTPBtn(true);
        apiService.sentOTP({
                email: form.getFieldValue('email'),
                first_name: form.getFieldValue('firstName'),
                last_name: form.getFieldValue('lastName'),
                org_name: selectedType == 1?form.getFieldValue('orgName'):null,
                party_type: selectedType,
                link_party: null,
                old_email: oldEmail
            }).then((res) => {
                setLoadingOTPBtn(false);
                if (res.status == 'success') {
                    message.success(res.message);
                    setIsOTPSent(true);
                    otpTimer();
                } else {
                    message.error(res.message);
                }
            });
    }

    const resendLink = () => {
        apiService.resendLink({
                email: form.getFieldValue('email'),
                first_name: form.getFieldValue('firstName'),
                last_name: form.getFieldValue('lastName'),
                org_name: form.getFieldValue('orgName'),
                party_type: selectedType,
                link_party: null
            }).then((res) => {
                if (res.status == 'success') {
                    message.success(res.message);
                    otpTimer()
                } else {
                    message.error(res.message);
                }
            });
    }

    const otpTimer = () => {
        setTimer(60);
        let t = 60;
        inter = setInterval(() => {
            t--;
            setTimer(t);
            if (t == 0 && inter) {
                clearInterval(inter);
            }
        }, 1000);
    }

    const verifyOTP = () => {
        setLoadingOTPBtn(true);
        apiService.verifyOTP({
            email: form.getFieldValue('email'),
            otp: form.getFieldValue('otp')
        }).then((res) => {
            setLoadingOTPBtn(false);
            if (res.status == 'success') {
                message.success(res.message);
                setIsVerified(true);
            } else {
                message.error(res.message);
            }
        });
    }

    const changeEmail = () => {
        setOldEmail(form.getFieldValue('email'));
        setIsOTPSent(false);
        setIsVerified(false);
        if (inter) {
            clearInterval(inter);
        }
    }

    const checkPassValid = (type, value) => {
        if (value) {
            switch (type) {
                case 0:
                    return value.match(".*[a-z].*");
                    break;
                case 1:
                    return value.match(".*[A-Z].*");
                    break;
                case 2:
                    return value.match(".*[0-9].*");
                    break;
                case 3:
                    return value.match(".*[$@$!*#?&].*");
                    break;
                default:
                    break;
            }
        } else {
            return false;
        }
    }

    const changePassword = (event) => {
        setPassword(event.target.value);
    }

    const otpformvalid = () => {
        let nameReg = new RegExp(/^[a-zA-Z0-9#/, \-\']+$/);
        let emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z ]{2,10}$/);
        if (!form.getFieldValue('firstName') || !nameReg.test(form.getFieldValue('firstName'))) {
            return true;
        } else if (!form.getFieldValue('lastName') || !nameReg.test(form.getFieldValue('lastName'))) {
            return true;
        } else if (!form.getFieldValue('email') || !emailReg.test(form.getFieldValue('email'))) {
            return true;
        } else if (selectedType == 1 && (!form.getFieldValue('orgName') || !nameReg.test(form.getFieldValue('orgName')))) {
            return true;
        }
        return false;
    }

    const signupSubmit = () => {
        if (!checkPassValid(0, form.getFieldValue('createpassword')) || !checkPassValid(1,form.getFieldValue('createpassword')) || !checkPassValid(2,form.getFieldValue('createpassword')) || !checkPassValid(3,form.getFieldValue('createpassword'))) {
            message.error('Password must contains 1 Lower case, 1 Upper case, 1 Number, 1 Special character from @$!*#?&');
            return;
        } else if(form.getFieldValue('createpassword') != form.getFieldValue('confirmpassword')) {
            message.error('Passwords are not matching');
            return;
        } else if(form.getFieldValue('createpassword').length <= 7) {
            message.error('Passwords must contain 8 characters minimum');
            return;
        }
        setLoadingSignup(true);
        let password = encrypt(form.getFieldValue('createpassword')).toString();
        let confirmPassword = encrypt(form.getFieldValue('confirmpassword')).toString();
        apiService.signup({
                email: form.getFieldValue('email'),
                first_name: form.getFieldValue('firstName'),
                last_name: form.getFieldValue('lastName'),
                org_name: form.getFieldValue('orgName'),
                password: password,
                password_confirmation: confirmPassword,
                timezone: moment?.tz.guess()
            }).then((res) => {
                setLoadingSignup(false);
                if (res.status == 'success') {
                    message.success(res.message);
                    userService.setUserToken(res.data.token);
                    const user = decodeToken(res.data.token);
                    dispatch({type: 'user', value: user});
                    if (user.setup_status === 0) {
                        history.push(`/app/welcome`);
                    } else if (res.data.casecount == 1 && res.data.lastestCaseId) {
                        history.push('/app/case/'+(res.data.lastestCaseId)+'/overview');
                    } else {
                        history.push('/app/allcases');
                    }
                } else {
                    message.error(res.message);
                }
            });
    }

    return (
        <Row className="max-480 p16 sigup-container">
            <Col className="textCenter mb24 mt40" span={24}>
                { settings && <img className="portal-logo" src={settings?.logo} alt="logo" /> }
                { !settings && <Skeleton style={{width: 100, margin: '0 auto'}} active paragraph={false} /> }
            </Col>
            { !settings && (<div className="w100 textCenter"><Spin></Spin></div>)}
            { settings && settings.signup_enabled == 1 && (
            <div className="w100">
                <Col className="textCenter mb40" span={24}>
                    <Steps current={current} direction="horizontal" id="signup-steps" className="signup-steps ant-steps-horizontal ant-steps-label-horizontal">
                        <Step />
                        <Step />
                    </Steps>
                </Col>
                <h3 className="mb8">Sign Up</h3>
                <p className="info-sub-title mb24">Effortlessly handle your case.</p>
                { current == 0 && 
                (<Col span={24}>
                    <p className="info-title mb16">Sign up as:</p>
                    <Card className={"sign-up-card "+(selectedType == 0?'selected':'')} onClick={() => selectType(0)}>
                        <div className="icon first">
                            <SignupIndvIcon />
                        </div>
                        <p>Individual</p>
                        <InfoIcon onClick={() => showDrawer(0)} />
                        { selectedType == 0 && (<CheckCircleIcon />)}
                    </Card>
                    <Card className={"sign-up-card "+(selectedType == 1?'selected':'')} onClick={() => selectType(1)}>
                        <div className="icon second">
                            <SignupOrgIcon />
                        </div>
                        <p>Organization</p>
                        <InfoIcon onClick={() => showDrawer(1)} />
                        { selectedType == 1 && (<CheckCircleIcon />)}
                    </Card>
                    <Card className={"sign-up-card "+(selectedType == 2?'selected':'')} onClick={() => selectType(2)}>
                        <div className="icon third  ">
                            <SignupRepIcon />
                        </div>
                        <p>Legal Representative</p>
                        <InfoIcon onClick={() => showDrawer(2)} />
                        { selectedType == 2 && (<CheckCircleIcon />)}
                    </Card>
                    <div style={{margin: '0 auto', width: '90%'}}>
                        <Button type="primary" className="w100 mb24" disabled={selectedType == null} onClick={nextSection}>Next</Button>
                    </div>
                    <Drawer 
                        title={infoTitle}
                        visible={isShowInfo}
                        placement="bottom"
                        mask={false} 
                        onClose={closeInfo}
                        className="signup-info-drawer"
                    >
                        { infoContent }
                    </Drawer>
                </Col>)}
                { current == 1 && (
                <Col span={24}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        { selectedType == 1 && (<>
                        <Form.Item label="Organization Name" name='orgName' className="w100 mb16"
                        onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9#/, \-\']/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}>
                            <Input placeholder="Enter organization name" name="orgName" maxLength={64} />
                        </Form.Item>
                        <div className="org-rep-label">Representative Details</div></>)}
                        <Form.Item label="First Name" name='firstName' className="w100 mb16"
                        onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9#/, \-\']/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}>
                            <Input placeholder="Enter first name" name="firstName" maxLength={32} />
                        </Form.Item>
                        <Form.Item label="Last Name" name='lastName' className="w100 mb16"
                        onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9#/, \-\']/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}>
                            <Input placeholder="Enter last name" name="lastName" maxLength={32} />
                        </Form.Item>
                        <Form.Item label={<div className="email-label">{selectedType == 1?"Representative email address":"Email address"}<div><span className="verify"></span>{ isOTPSent && (<span className="change" onClick={() => changeEmail()}>Change</span>)}</div></div>} name='email' className="w100 email-label-item mb16"
                        onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9#@%._+-/, \-\']/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}>
                            <Input placeholder="Enter email address" disabled={isOTPSent} name="email" maxLength={100} />
                        </Form.Item>
                        { !isOTPSent && (
                        <div className="verify-email mb24">
                            <p>
                                We would like you to verify your email. Click “Send OTP” button
                            </p>
                            <div>
                                {/* <Button type="primary">Send OTP</Button> */}
                                <Form.Item shouldUpdate className="mb0">
                                    {() => (
                                        <Button
                                            type="primary"
                                            loading={loadingOTPBtn}
                                            disabled={otpformvalid()}
                                            onClick={() => sendOTP()}
                                        >
                                            Send OTP
                                        </Button>
                                    )}
                                </Form.Item>
                            </div>
                        </div>)}
                        { isOTPSent && !isVerified && (
                        <Row gutter={8} justify="space-between" align="bottom" className="mb24">
                            <Col span={16}>
                                <Form.Item label={<div>OTP {timer > 0 && (<span className="otp-time">{ timer == 60?'01:00':'00:'+(timer < 10 ?'0'+timer:timer) }</span>)}</div>} name='otp' className="w100 mb8">
                                    <Input placeholder="Enter OTP here" name="otp" maxLength={6} />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item shouldUpdate className="mb8">
                                    {() => (
                                        <Button
                                            type="primary"
                                            loading={loadingOTPBtn}
                                            disabled={!form.getFieldValue('otp')}
                                            onClick={() => verifyOTP()}
                                        >
                                            Verify
                                        </Button>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <span className="resend-otp">Didn’t receive an OTP?</span>
                            </Col>
                            <Col span={6}>
                                <a className={"resend-link "+ (timer == 0?'active':'')} style={ timer == 0?{cursor:'pointer'}:{cursor:'no-drop'}} onClick={() => timer == 0?resendLink(): undefined}>Resend</a>
                            </Col>
                        </Row>)}
                        { isOTPSent && isVerified && (<>
                            <Form.Item 
                                label="Create Password" 
                                name='createpassword' 
                                className="w100 mb16"
                            >
                                <Input.Password placeholder="Enter password" name="createpassword" onChange={(event) => changePassword(event)} maxLength={32}
                                    iconRender={visible => (visible ? <EyeIcon /> : <EyeInvisibleIcon />)} 
                                />
                            </Form.Item>
                            { password && <ul className="pass-check-list">
                                <li className={checkPassValid(0, password)?'green':'red'}> 1 Lower case.</li>
                                <li className={checkPassValid(1, password)?'green':'red'}> 1 Upper case.</li>
                                <li className={checkPassValid(2, password)?'green':'red'}> 1 Number.</li>
                                <li className={checkPassValid(3, password)?'green':'red'}> 1 Special character from @$!*#?&.</li>
                                <li className={password && password.length >= 8?'green':'red'}> 8 Characters minimum.</li>  
                            </ul> 
                            }
                            <Form.Item 
                                label="Confirm Password" 
                                name='confirmpassword' 
                                className="w100 mb16"
                            >
                                <Input.Password placeholder="Enter password" name="confirmpassword" maxLength={32}
                                    iconRender={visible => (visible ? <EyeIcon /> : <EyeInvisibleIcon />)} 
                                />
                            </Form.Item>
                        </>)}
                        <Row gutter={8} justify="space-between" className="mb24">
                            <Col>
                                <Button className="custom-btn" type="default" onClick={backSection}>Back</Button>
                            </Col>
                            <Col span={18}>
                                <Form.Item shouldUpdate>
                                    {() => (
                                        <Button
                                            type="primary"
                                            className="w100"
                                            disabled={!form.getFieldValue('email') || !isVerified || !form.getFieldValue('createpassword') || !form.getFieldValue('confirmpassword')}
                                            onClick={() => signupSubmit()}
                                        >
                                            Signup
                                        </Button>
                                    )}
                                </Form.Item>
                                {/* <Button type="primary" className="w100">Signup</Button> */}
                            </Col>
                        </Row>
                    </Form>
                </Col>)}
                <h3 className="signup-to-login">Already have an account? <Link to={`/auth/login`}>Log In</Link></h3>
            </div>)}
        </Row>
    );
}
export default Signup;